<template>
    <sui-container>
        <h3 class="sui-header">Form ADV Part 2A and 2B</h3>
        <p>
            Read and/or download
            <a v-bind:href="documentUrl" target="_blank" @click="updateSalesforceContact">
                <strong>Form ADV Part 2A and 2B</strong>
            </a>.
        </p>
    </sui-container>
</template>

<script>
import { mapState} from "vuex";
import moment from "moment";
import formUrl from "@/mixins/formUrl";
export default {
    name: "FormADV",
    inject: ['notyf'],
    mixins: [formUrl],
    beforeMount() {
        this.getFormAdvUrl();
        if (this.user.profile.is_exhibit_b_investor_profile_form_user) {
            if (!this.isInvestorProfileSubmitted) {
                this.$router.push({name: "investorProfile"});
            }
        } else {
            if (!this.hasSubmittedClientAgreement) {
                this.$router.push({name: "clientAgreement"});
            }
        }
    },
    computed: {
        ...mapState({user: "userProfile"}),
        ...mapState(["hasSubmittedClientAgreement", "isInvestorProfileSubmitted", "hasViewedOrDownloadedFormADV"])
    },
    data() {
        return {
            documentUrl: null,
            formUrls: [
                {name:"ESWA", firm: "44f11933f5604c038543ac0387a1de7b", url:"https://eversourcewa.app.box.com/s/0go9muy794v8hicgn60qv0aqd4iw9a2e"},
                {name: "BWWS", firm: "eaf18a4049c440c38d1e244fd9ae633e", url:"https://bmsswesson.app.box.com/s/tfj5qzjeeb2sgrechx4u3psuiuiev2ct"},
                {name:"ESWA", firm: "1", url:"https://eversourcewa.app.box.com/s/0go9muy794v8hicgn60qv0aqd4iw9a2e"},
            ],
        }
    },
    methods: {
        updateSalesforceContact() {
            const date = moment(Date.now()).locale("en").format("YYYY-MM-DD");
            this.$store.dispatch("updateContactInitialFormAdvDeliveryDate", {
                portalUserId: this.user.id,
                deliveryDate: date
            })
                .then(() => {
                    this.$store.dispatch("getClientAgreement", this.user.id)
                        .then(() => {
                            this.$router.push({name: "formCRS"});
                             if(this.hasViewedOrDownloadedFormADV) {
                                 this.$router.push({name: "formCRS"});
                             }
                        });
                }).catch();
        },
        getFormAdvUrl() {
            this.documentUrl = this.getFormUrl(this.user.profile._firm, this.formUrls);
        }
    }
}
</script>

<style scoped>
    a {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
