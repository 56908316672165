<template>
    <div>
        <sui-table basic="very" selectable padded>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in tableColumns" :key=index>
                        {{ column}}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="resourceList">
                <sui-table-row v-for="(resource, index) in resourceList" :key="index">
                    <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>
                        <a :href="resource.assetUrl" target="_blank">{{ resource.name}}</a>
                        <font-awesome-icon icon="external-link-alt" style="margin-left: 8px"/>
                    </sui-table-cell>
                    <sui-table-cell>{{ resource.addedDate }}</sui-table-cell>
                    <sui-table-cell>{{ resource.lastModifiedDate }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
    </div>
</template>

<script>
    export default {
        name: "MasterResourceList",
        props: {
            resourceList: {
                type: Array,
            }
        },
        data() {
            return {
                tableColumns: [
                    'Resource Name',
                    'Added Date',
                    'Last Modified Date',
                ]
            }
        }
    }
</script>

<style scoped>

</style>
