<template>
    <sui-container class="cash-request-form">
        <h3 style="font-weight: lighter">New Cash Request</h3>
        <CashRequestForm  ref="form"/>
        <div>
            <sui-button
                basic
                color="grey"
                type="button"
                content="Cancel"
                @click.native="cancel"
            />
            <sui-button
                primary
                type="button"
                content="Submit"
                @click.native="save"
            />
        </div>
        <sui-message info>
                <p>
                    <font-awesome-icon icon="info-circle" />
                    For any recurring cash disbursement changes, please submit a new Investment Strategy form to update the Cash Target.
                </p>
        </sui-message>
    </sui-container>
</template>

<script>
    import CashRequestForm from "./MasterForm";
    export default {
        name: "Submit",
        components: {
            CashRequestForm
        },
        methods: {
            cancel() {
                this.$refs.form.cancel()
            },
            save() {
                this.$refs.form.save();
            },
        }
    }
</script>

<style scoped>
    .cash-request-form {
        margin: 2em 0;
    }
</style>
