<template>
    <div class="main-container">
        <div class="manager-header">
            <sui-container>
                <div class="manager">
                    <h3 is="sui-header" style="font-weight: lighter">Firm Models</h3>
                    <div class="search-filter">
                        <sui-dropdown
                            class="firm-dropdown-menu"
                            placeholder="Firm"
                            text="Firm"
                            selection
                            :options="firmsOptions"
                            v-model="firm"
                        />
                        <sui-input
                            placeholder="Search"
                            icon="search"
                            icon-position="left"
                            v-model="searchPhrase"
                        />
                    </div>
                    <div class="actions">
                        <sui-button
                            basic
                            size="tiny"
                            color="grey"
                            content="New Firm Model"
                            @click="handleAdd"
                        />
                    </div>
                </div>
            </sui-container>
        </div>
        <sui-container class="model-list">
            <ModelList
                :models="models"
                @handleEdit="handleEdit"
                @showDeleteWarning="showDeleteWarning"
            />
            <Modal
                :open="isOpen"
                :cancel="toggleModal"
                :modalAction="modalAction"
                :models="modelOptions"
                @add="addModel"
                @edit="editModel"
            />
            <DeleteWarning ref="deleteWarningModal" @handleDelete="deleteModel" />
        </sui-container>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ModelList from "./ModelList";
import Modal from "./Form";
import DeleteWarning from "@/components/common/DeleteWarningModal";

export default {
    name: "Index",
    components: {
        DeleteWarning,
        Modal,
        ModelList
    },
    created() {
        const loader = this.$loading.show();
        Promise.all([
            this.getAllFirmModels(),
            this.getAllFirms(),
            this.getAllInvestmentModels()
        ])
            .finally(() => {
                loader.hide();
            });
    },
    data() {
        return {
            firm: null,
            searchPhrase: null,
            isOpen: false,
            modalAction: null
        }
    },
    inject: ['notyf'],
    computed: {
        ...mapState({ investmentModels: "investmentModelList", user: "userProfile"}),
        ...mapGetters(["firms"]),
        ...mapGetters({ models: "firmModels" }),
        firmsOptions() {
            if (this.firms) {
                let firms = this.firms.map(firm => {
                    return { key: firm.id, value: firm.id, text: firm.name };
                });
                firms.unshift({ key: "", value: "", text: "All" });
                return firms;
            }
            return [];
        },
        modelOptions() {
            if (this.investmentModels) {
                return this.investmentModels.map(model => {
                    return {key: model.id, value: model.id, text: model.name};
                });
            }
            return [];
        },
        firmModels() {
            if (this.models) {
                if (this.searchPhrase) {
                    return this.models.filter(model => {
                        if (model.firm_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                            model.investment_model_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                            model.email.toLowerCase().includes(this.searchPhrase.toLowerCase())
                        ) return model;
                    });
                } else {
                    return  this.models;
                }
            }
            return [];
        }
    },
    watch: {
        firm: {
            handler: "getModels",
        }
    },
    methods: {
        ...mapActions([
            "getAllFirms", "getAllInvestmentModels", "getAllFirmModels", "getFirmModels",
            "getFirmModel", "createFirmModel", "updateFirmModel", "deleteFirmModel"
        ]),
        toggleModal() {
            this.isOpen = !this.isOpen;
        },
        handleAdd() {
            this.modalAction = "add";
            this.toggleModal();
        },
        handleEdit(Id) {
            this.modalAction = "edit";
            const loader = this.$loading.show();
            this.getFirmModel(Id).
            then(() => {
                loader.hide();
                this.toggleModal();
            });
        },
        getModels() {
            const loader = this.$loading.show();
            if (this.firm) {
                this.getFirmModels(this.firm)
                    .then(() => {
                        loader.hide();
                    }).catch()
            } else {
                this.getAllFirmModels()
                    .then(() => {
                        loader.hide();
                    }).catch()
            }
        },
        addModel(model) {
            const loader = this.$loading.show();
            this.createFirmModel(model)
                .then(() => {
                    this.getAllFirmModels();
                    loader.hide();
                    this.notyf.success("Firm model saved");
                }).catch()
            this.toggleModal();
        },
        editModel(model) {
            const loader = this.$loading.show();
            this.updateFirmModel(model)
                .then(() => {
                    loader.hide();
                    this.notyf.success("Firm model updated");
                }).catch();
            this.toggleModal();
        },
        deleteModel(recordId) {
            const loader = this.$loading.show();
            this.deleteFirmModel(recordId)
                .then(() => {
                    this.getAllFirmModels();
                    loader.hide();
                    this.notyf.success("Firm model deleted");
                }).catch();
            this.$refs.deleteWarningModal.toggleIsOpen();
        },
        showDeleteWarning(model) {
            this.$refs.deleteWarningModal.toggleIsOpen(model.id, model.investment_model_name);
        },
    }
}
</script>

<style scoped>
.manager {
    display: flex;
    justify-content: space-between;
    margin-top: 1em !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 10px !important;
}
.firm-dropdown-menu {
    margin-right: 2px !important;
}
</style>
