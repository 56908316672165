<template>
    <sui-grid-column :width="8">
        <sui-grid-column>
            <sui-form-field required>
                <label>Name</label>
                <input type="text" placeholder="First Name, Middle Initial, Last Name" v-model.trim="$v.agreement.ClientName__c.$model"
                       :class="{ 'is-invalid': submitted && $v.agreement.ClientName__c.$error }"
                />
                <div v-if="submitted && !$v.agreement.ClientName__c.required" class="invalid-feedback">
                    Enter first name, middle initial, last name
                </div>
            </sui-form-field>
            <sui-form-fields equal-width>
                <sui-form-field>
                    <label>Company/Trust Name</label>
                    <input type="text" placeholder="Company Name or Trust Name" v-model="agreement.CompanyOrTrust__c" />
                </sui-form-field>
                <sui-form-field>
                    <label>Trustee/Corporate Officer Name</label>
                    <input type="text" placeholder="Trustee/Corporate Officer Name" v-model="agreement.TrusteeOrCorporateOfficer__c" />
                </sui-form-field>
            </sui-form-fields>
            <sui-form-field :width="8">
                <label>Company/Trust Tax ID</label>
                <input type="text" placeholder="Company/Trust Tax ID" v-model="agreement.CompanyOrTrustTaxID__c"/>
            </sui-form-field>
            <section style="margin-top: 4em">
                <sui-form-field required>
                    <label>Home Address</label>
                    <input type="text" placeholder="Street" v-model="$v.agreement.ClientHomeAddressStreet__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientHomeAddressStreet__c.$error }"
                    />
                    <div v-if="submitted && !$v.agreement.ClientHomeAddressStreet__c.required" class="invalid-feedback">
                        Enter street
                    </div>
                </sui-form-field>
                <sui-form-fields>
                    <sui-form-field width="six" required>
                        <input type="text" placeholder="City" v-model="$v.agreement.ClientHomeAddressCity__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.ClientHomeAddressCity__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientHomeAddressCity__c.required" class="invalid-feedback">
                            Enter city
                        </div>
                    </sui-form-field>
                    <sui-form-field width="six">
                        <sui-dropdown
                            placeholder="State"
                            selection
                            direction="downward"
                            :options="states"
                            v-model="$v.agreement.ClientHomeAddressState__c.$model"
                            :class="{ 'is-invalid': submitted && $v.agreement.ClientHomeAddressState__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientHomeAddressState__c.required" class="invalid-feedback">
                            Enter state
                        </div>
                    </sui-form-field>
                    <sui-form-field width="four" required>
                        <input type="text" placeholder="Zip" v-model="$v.agreement.ClientHomeAddressZipCode__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.ClientHomeAddressZipCode__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientHomeAddressZipCode__c.required" class="invalid-feedback">
                            Enter zip code
                        </div>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <sui-checkbox label="Home address same as mailing address" v-model="clientHomeAddressSamesAsMailingAddress" />
                </sui-form-field>
                <sui-form-field required>
                    <label>Mailing Address</label>
                    <input type="text" placeholder="Street" v-model="$v.agreement.ClientMailingAddressStreet__c.$model"
                       :class="{ 'is-invalid': submitted && $v.agreement.ClientMailingAddressStreet__c.$error}"
                    />
                    <div v-if="submitted && !$v.agreement.ClientMailingAddressStreet__c.required" class="invalid-feedback">
                        Enter mailing address
                    </div>
                </sui-form-field>
                <sui-form-fields>
                    <sui-form-field width="six">
                        <input type="text" placeholder="City" v-model="$v.agreement.ClientMailingAddressCity__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientMailingAddressCity__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientMailingAddressCity__c.required" class="invalid-feedback">
                            Enter city
                        </div>
                    </sui-form-field>
                    <sui-form-field width="six">
                        <sui-dropdown
                            placeholder="State"
                            selection
                            direction="downward"
                            :options="states"
                            v-model="$v.agreement.ClientMailingAddressState__c.$model"
                            :class="{ 'is-invalid': submitted && $v.agreement.ClientMailingAddressState__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientMailingAddressState__c.required" class="invalid-feedback">
                            Enter state
                        </div>
                    </sui-form-field>
                    <sui-form-field width="four">
                        <input type="text" placeholder="Zip" v-model="$v.agreement.ClientMailingAddressZipCode__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientMailingAddressZipCode__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientMailingAddressZipCode__c.required" class="invalid-feedback">
                            Enter zip code
                        </div>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <label>Work Address</label>
                    <input type="text" placeholder="Street" v-model="agreement.ClientWorkAddressStreet__c" />
                </sui-form-field>
                <sui-form-fields>
                    <sui-form-field width="six">
                        <input type="text" placeholder="City" v-model="agreement.ClientWorkAddressCity__c" />
                    </sui-form-field>
                    <sui-form-field width="six">
                        <sui-dropdown
                            placeholder="State"
                            selection
                            direction="downward"
                            :options="states"
                            v-model="agreement.ClientWorkAddressState__c"
                        />
                    </sui-form-field>
                    <sui-form-field width="four">
                        <input type="text" placeholder="Zip" v-model="agreement.ClientWorkAddressZipCode__c" />
                    </sui-form-field>
                </sui-form-fields>
            </section>
            <section style="margin-top: 4em">
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <Label>Cell Phone</Label>
                        <input type="text" placeholder="Cell Phone" v-model="$v.agreement.ClientCellPhone__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.ClientCellPhone__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientCellPhone__c.required" class="invalid-feedback">
                            Enter cell phone
                        </div>
                    </sui-form-field>
                    <sui-form-field required>
                        <Label>Business Phone</Label>
                        <input type="text" placeholder="Business Phone" v-model="$v.agreement.ClientBusinessPhone__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientBusinessPhone__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientBusinessPhone__c.required" class="invalid-feedback">
                            Enter business phone
                        </div>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <Label>Home Phone</Label>
                        <input type="text" placeholder="Home Phone" v-model="$v.agreement.ClientHomePhone__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientHomePhone__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientHomePhone__c.required" class="invalid-feedback">
                            Enter home phone
                        </div>
                    </sui-form-field>
                    <sui-form-field required>
                        <Label>Email Address</Label>
                        <input type="email" name="clt-email" placeholder="Email Address" v-model="$v.agreement.ClientEmailAddress__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientEmailAddress__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientEmailAddress__c.required" class="invalid-feedback">
                            Enter email address
                        </div>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field :width="8" required>
                    <label>Mail Preference</label>
                    <sui-dropdown
                        placeholder="Mail Preference"
                        selection
                        direction="downward"
                        :options="mailPreferenceOptions"
                        v-model="$v.agreement.ClientMailPreference__c.$model"
                        :class="{ 'is-invalid': submitted && $v.agreement.ClientMailPreference__c.$error}"
                    />
                    <div v-if="submitted && !$v.agreement.ClientMailPreference__c.required" class="invalid-feedback">
                        Enter mailing preference
                    </div>
                </sui-form-field>
            </section>
            <section style="margin-top: 4em">
                <sui-form-field :width="8" required>
                    <label>Employment Status</label>
                    <sui-dropdown
                        placeholder="Employment Status"
                        selection
                        direction="downward"
                        :options="employmentStatusOptions"
                        v-model="$v.agreement.ClientEmploymentStatus__c.$model"
                        :class="{ 'is-invalid': submitted && $v.agreement.ClientEmploymentStatus__c.$error}"
                    />
                    <div v-if="submitted && !$v.agreement.ClientEmploymentStatus__c.required" class="invalid-feedback">
                        Enter employment status
                    </div>
                </sui-form-field>
                <sui-form-fields equal-width>
                    <sui-form-field>
                        <Label>Employer</Label>
                        <input type="text" placeholder="Former if retired or unemployed" v-model="agreement.ClientEmployer__c" />
                    </sui-form-field>
                    <sui-form-field>
                        <Label>Occupation</Label>
                        <input type="text" placeholder="Former if retired or unemployed"  v-model="agreement.ClientOccupation__c" />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields inline>
                    <label style="margin-right: 10px!important;">
                        Are you employed by a broker-dealer, securities exchange, or FINRA?
                    </label>
                    <sui-form-field>
                        <sui-checkbox v-model="agreement.IsClientEmployedByBrokerDealer__c" />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields inline>
                    <label style="margin-right: 10px!important;">
                        Are you a senior officer, director, or large shareholder of a public company?
                    </label>
                    <sui-form-field>
                        <sui-checkbox v-model="agreement.IsClientSeniorOfficerOfPublicCompany__c" />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field :width="8" v-if="agreement.IsClientSeniorOfficerOfPublicCompany__c">
                    <label>Public Company Name</label>
                    <input type="text" placeholder="Public Company Name" v-model="agreement.ClientPublicCompany__c" />
                </sui-form-field>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <Label>Date of Birth</Label>
                        <input type="text" placeholder="MM/DD/YYYY" v-model="$v.agreement.ClientDoB__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientDoB__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientDoB__c.required" class="invalid-feedback">
                            Enter date of birth
                        </div>
                    </sui-form-field>
                    <sui-form-field required>
                        <Label>SSN</Label>
                        <input type="text" placeholder="SSN" v-model="$v.agreement.ClientSSN__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientSSN__c.$error}"
                        />
                        <div v-if="submitted && !$v.agreement.ClientSSN__c.required" class="invalid-feedback">
                            Enter social security number
                        </div>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <label>Driver's License Number</label>
                            <input type="text" placeholder="Driver's License Number" v-model="$v.agreement.ClientDriversLicense__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.ClientDriversLicense__c.$error}"
                            />
                            <div v-if="submitted && !$v.agreement.ClientDriversLicense__c.required" class="invalid-feedback">
                                Enter driver's license number
                            </div>
                        </sui-form-field>
                        <sui-form-field required>
                            <label>Driver's License State or Country of Issuance</label>
                            <input type="text" placeholder="State or Country of Issuance" v-model="$v.agreement.ClientDriversLicenseIssueState__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.ClientDriversLicenseIssueState__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.ClientDriversLicenseIssueState__c.required" class="invalid-feedback">
                                Enter drivers license issue state
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                </sui-form-field>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Driver's License Issue Date</label>
                        <input type="text" placeholder="MM/DD/YYYY"  v-model="$v.agreement.ClientDriversLicenseIssueDate__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientDriversLicenseIssueDate__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientDriversLicenseIssueDate__c.required" class="invalid-feedback">
                            Enter drivers license issue date
                        </div>
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Driver's License Expiration Date</label>
                        <input type="text" placeholder="MM/DD/YYYY" v-model="$v.agreement.ClientDriversLicenseExpirationDate__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.ClientDriversLicenseExpirationDate__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.ClientDriversLicenseExpirationDate__c.required" class="invalid-feedback">
                            Enter drivers license expiration date
                        </div>
                    </sui-form-field>
                </sui-form-fields>
            </section>
        </sui-grid-column>
    </sui-grid-column>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    name: "ClientFormInput",
    props: {
        agreement: {
            type: Object,
            required: true
        },
        states: {
            type: Array,
            required: true
        },
        mailPreferenceOptions: {
            type: Array,
            required: true
        },
        employmentStatusOptions: {
            type: Array,
            required: true
        },
        submitted: {
            type: Boolean
        }
    },
    data() {
        return  {
            clientHomeAddressSamesAsMailingAddress: false,
        }
    },
    watch: {
        clientHomeAddressSamesAsMailingAddress: function () {
            if (this.clientHomeAddressSamesAsMailingAddress) {
                this.agreement.ClientMailingAddressStreet__c = this.agreement.ClientHomeAddressStreet__c;
                this.agreement.ClientMailingAddressCity__c = this.agreement.ClientHomeAddressCity__c;
                this.agreement.ClientMailingAddressState__c = this.agreement.ClientHomeAddressState__c;
                this.agreement.ClientMailingAddressZipCode__c = this.agreement.ClientHomeAddressZipCode__c;
            }
        }
    },
    validations: {
        agreement: {
            ClientName__c: { required },
            ClientHomeAddressStreet__c: { required },
            ClientHomeAddressCity__c: { required },
            ClientHomeAddressState__c: { required },
            ClientHomeAddressZipCode__c: { required },
            ClientMailingAddressStreet__c: { required },
            ClientMailingAddressCity__c: { required },
            ClientMailingAddressState__c: { required },
            ClientMailingAddressZipCode__c: { required },
            ClientCellPhone__c: { required },
            ClientBusinessPhone__c: { required },
            ClientHomePhone__c: { required },
            ClientEmailAddress__c: { required },
            ClientMailPreference__c: { required },
            ClientEmploymentStatus__c: { required },
            ClientSSN__c: { required },
            ClientDoB__c: { required },
            ClientDriversLicense__c: { required },
            ClientDriversLicenseIssueState__c: { required },
            ClientDriversLicenseIssueDate__c: { required },
            ClientDriversLicenseExpirationDate__c: { required }
        }
    },
}
</script>

<style scoped>
    label {
        display: block !important;
        margin: 0 0 .28571429rem 0 !important;
        color: rgba(0,0,0,.87) !important;
        font-size: .92857143em !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }
    .invalid-feedback {
        margin-left: 1px;
        color: #D63301;
        font-weight: bold;
    }
</style>
