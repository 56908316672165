<template>
    <sui-container fluid class="footer">
        <sui-divider />
        <div>
            <p class="legal-notice">
                &copy; {{ currentYear }} EverSource Wealth Advisors, LLC. All rights reserved.<br>
                Investment advice offered through EverSource Wealth Advisors, a Registered Investment Advisor. <br>
                EverSource Wealth Advisors may only conduct business with residents of states in which it is properly registered.
            </p>
        </div>
    </sui-container>
</template>

<script>
    export default {
        name: "Footer",
        computed: {
            currentYear() {
                const now = new Date();
                let currentYear = now.getFullYear();
                if (currentYear < 1900) currentYear = currentYear + 1900;
                return currentYear;
            }
        }
    }
</script>

<style scoped>
    @media (min-height: 300px) {
        .footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding-bottom: 10px;
            padding-top: 10px;
            font-size: .8rem;
            color: rgba(36,28,21,0.65);
            
        }
    }
    .legal-notice {
        line-height: 1.5;
        text-align: center;
    }
</style>
