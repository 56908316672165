<template>
    <sui-modal v-model="open" size="large" :closable="false">
        <sui-modal-header style="text-align: center">Edit Investment Strategy</sui-modal-header>
        <sui-modal-content scrolling>
            <InvestmentStrategyForm :strategy="investmentStrategy" ref="form"/>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                color="grey"
                type="button"
                content="Cancel"
                @click.native="toggle"
            />
            <sui-button
                primary
                type="button"
                content="Save"
                @click.native="save"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    import InvestmentStrategyForm from "./MasterForm";
    export default {
        name: "Modal",
        components: {
            InvestmentStrategyForm
        },
        props: {
            open: { type: Boolean, default: false },
            investmentStrategy: { type: Object },
            toggle: { type: Function},
        },
        methods: {
            save() {
                this.$refs.form.update();
                this.$emit('toggle-modal');
            }
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.2) !important;
    }

</style>
