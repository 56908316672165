<template>
    <div class="main-container">
        <sui-container>
            <h2 is="sui-header" class="manager-header">Settings</h2>
            <Manager />
        </sui-container>
    </div>
</template>

<script>
    import Manager from "@/components/account-settings/Index"
    
    export default {
        name: "AccountSettings",
        components: {
            Manager
        }
    }
</script>

<style scoped>
    .main-container {
        margin: 10px 0em !important;
    }
    .manager-header {
        font-weight: lighter !important;
    }
</style>
