<template>
    <sui-card class="centered card">
        <sui-card-content>
            <div class="center aligned header" style="margin: 1.5em;">{{ title }}</div>
            <template v-if="isPasswordResetSuccessful === false">
                <sui-form class="password-reset-form">
                    <sui-form-field>
                        <label>New Password</label>
                        <sui-input
                            type="password"
                            placeholder="New password"
                            autocomplete="off"
                            v-model="newPassword"
                        />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Confirm New Password</label>
                        <sui-input
                            type="password"
                            placeholder="Confirm new password"
                            autocomplete="off"
                            v-model="newPasswordConfirmation"
                        />
                    </sui-form-field>
                    <sui-button
                        type="button"
                        fluid
                        color="teal"
                        content="Save Password"
                        @click="resetPassword"
                    >
                    </sui-button>
                </sui-form>
            </template>
            <template v-if="isPasswordResetSuccessful">
                <sui-message positive>
                    <sui-message-content>
                        Your password has been reset successfully with the new password.
                    </sui-message-content>
                </sui-message>
                <div class="center aligned">
                    <router-link to="/account/login" style="color: #2a998b;">Log in?</router-link>
                </div>
            </template>
        </sui-card-content>
    </sui-card>
</template>

<script>
    export default {
        name: "PasswordResetConfirmForm",
        data() {
            return {
                title:'Reset password',
                newPassword: "",
                newPasswordConfirmation: "",
                isPasswordResetSuccessful: false
            }
        },
        methods: {
            resetPassword() {
                const loader = this.$loading.show();
                this.$store.dispatch("resetPassword", {
                    uid: this.$route.params.uid,
                    token: this.$route.params.token,
                    new_password1: this.newPassword,
                    new_password2: this.newPasswordConfirmation
                }).then(() => {
                    loader.hide();
                    this.title = 'Password reset complete'
                    this.isPasswordResetSuccessful = !this.isPasswordResetSuccessful;
                })
            }
        }
    }
</script>

<style scoped>
    .card {
        width: 340px !important;
    }
    .password-reset-form {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
</style>
