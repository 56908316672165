<template>
    <sui-table basic="very"  compact>
        <sui-table-header>
            <sui-table-row>
                <sui-table-header-cell></sui-table-header-cell>
                <sui-table-header-cell></sui-table-header-cell>
                <sui-table-header-cell v-for="(column, idx) in columns" :key=idx>
                    {{ column }}
                </sui-table-header-cell>
                <sui-table-header-cell></sui-table-header-cell>
            </sui-table-row>
        </sui-table-header>
        <sui-table-body v-if="users">
            <sui-table-row v-for="(user, index) in users" :key="index">
                <sui-table-cell collapsing>{{ index + 1 }}</sui-table-cell>
                <sui-table-cell collapsing>
                    <sui-checkbox @change="handleSelectedUsers($event, user)"/>
                </sui-table-cell>
                <sui-table-cell collapsing>
                    <div class="user-name" @click="handleEdit(user.id)">
                        <span class="user-name-initials">
                          {{ `${user.last_name}, ${user.first_name} `}}
                        </span>
                    </div>
                </sui-table-cell>
                <sui-table-cell>{{ user.email }}</sui-table-cell>
                <sui-table-cell>{{ profileTypeName(user.profile.profile_type) }} </sui-table-cell>
                <sui-table-cell>
                    <sui-checkbox disabled v-model="user.is_active"/>
                </sui-table-cell>
                <sui-table-cell>{{ user.last_login | datetime}}</sui-table-cell>
                <sui-table-cell collapsing>
                    <sui-icon
                        style="color: #8C979A"
                        name="edit"
                        class="edit"
                        @click="handleEdit(user.id)"
                    />
                </sui-table-cell>
                <sui-table-cell collapsing>
                    <sui-dropdown simple>
                        <sui-dropdown-menu>
                            <sui-dropdown-item @click="handleEdit(user.id)">
                                <font-awesome-icon icon="pencil-alt" class="list-item-menu-icon pencil-alt" />
                                Edit
                            </sui-dropdown-item>
                            <sui-dropdown-item @click="handleDelete(user.id)">
                                <font-awesome-icon icon="trash-alt" class="list-item-menu-icon trash" />
                                Delete
                            </sui-dropdown-item>
                        </sui-dropdown-menu>
                    </sui-dropdown>
                </sui-table-cell>
            </sui-table-row>
        </sui-table-body>
    </sui-table>
</template>

<script>
import { mapActions } from "vuex";
import profileTypeName from "../../mixins/profileTypeName";

export default {
    name: "UserList",
    props: {
        users: {
            type: Array
        },
        searchPhrase: {
            type: String
        },
    },
    data() {
        return {
            columns: [
                'User',
                'Email',
                'Profile',
                'Active',
                'Last Login'
            ],
            selectedUsers:[],
        }
    },
    mixins: [profileTypeName],
    inject: ['notyf'],
    methods: {
        ...mapActions(["deleteUser"]),
        
        handleEdit(userId) {
            this.$emit("handleEdit", userId);
        },
        
        handleSelectedUsers(event, user) {
            if (event) {
                this.selectedUsers.push(user.id);
            } else {
                for (let item of this.selectedUsers) {
                     if (user.id === item) {
                         const index = this.selectedUsers.indexOf(item);
                         if (index > -1) this.selectedUsers.splice(index, 1);
                         break;
                     }
                }
            }
        },
        
        handleDelete(userId) {
            const loader = this.$loading.show();
            this.deleteUser(userId)
                .then(() => {
                    loader.hide();
                    this.notyf.success("User deleted successfully.")
                })
                .catch(() => loader.hide());
        }
    }
}
</script>

<style scoped>
     .user-name, .edit {
        cursor: pointer;
    }
     .user-name {
        color: #2185d0;
     }
     .user-name:hover {
        text-decoration: underline;
     }
     .list-item-menu {
         display: flex;
         color: #8C979A;
     }
     .list-item-menu-icon {
         margin: 0 10px;
         cursor: pointer;
     }
     .list-item-menu .eye:hover{
         color: #555F61;
     }
     .list-item-menu .pencil-alt:hover{
         color: #555F61;
     }
     .list-item-menu .trash:hover{
         color: #ff4d4d;
     }
</style>
