<template>
     <sui-modal
         :closable="false"
         v-model="isOpen"
         class="staff-member-modal"
     >
         <sui-icon
             name="close"
             @click="handleCloseModal"
         />
         <sui-modal-header align="center">
             <h3 is="sui-header" icon text-align="center">
                 <sui-icon name="user" circular />
                 <sui-header-content>{{ modalHeaderContent }}</sui-header-content>
             </h3>
         </sui-modal-header>
         <sui-modal-content>
             <MasterForm
                 ref="memberForm"
                 :is-new="isNew"
                 :staffMember="staffMember"
                 :toggle-modal="toggleModal"
                 :reset-is-new="resetIsNew"
             />
         </sui-modal-content>
         <sui-modal-actions>
             <sui-button
                 basic
                 size="small"
                 color="grey"
                 type="button"
                 content="Cancel"
                 @click="handleCancel"
             />
             <sui-button
                 primary
                 size="small"
                 type="button"
                 content="Save"
                 @click="handleSave"
             />
             </sui-modal-actions>
     </sui-modal>
</template>

<script>
    import MasterForm from "@/components/staffDirectory/MasterForm";
    export default {
        name: "StaffMemberModal",
        components: {
            MasterForm,
        },
        props: {
            isNew: {type: Boolean},
            isOpen: {type: Boolean},
            staffMember: {type: Object},
            toggleModal: {type: Function},
            resetIsNew: {type: Function},
        },
        computed: {
            modalHeaderContent() {
                return this.staffMember.FullName__c;
            }
        },
        methods: {
            handleCloseModal() {
                this.toggleModal();
                this.resetIsNew();
            },
            handleCancel() {
                this.toggleModal();
                this.resetIsNew();
            },
            handleSave() {
                console.log(this.isNew);
                this.$refs.memberForm.save();
            }
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.25) !important;
    }
</style>
