<template>
    <sui-table basic="very" celled>
        <sui-table-header>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell v-for="(column, index) in columns" :key="index">
                {{column}}
            </sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
        </sui-table-header>
        <sui-table-body v-if="politicalContributions">
            <sui-table-row v-for="(politicalContribution, index) in politicalContributions" :key="index">
                <sui-table-cell>{{ index + 1}}</sui-table-cell>
                <sui-table-cell>${{politicalContribution.Amount__c}}</sui-table-cell>
                <sui-table-cell>{{politicalContribution.Candidate_Name__c}}</sui-table-cell>
                <sui-table-cell>{{politicalContribution.Political_Race__c}}</sui-table-cell>
                <sui-table-cell>{{politicalContribution.Election_Date__c}}</sui-table-cell>
                <sui-table-cell>{{politicalContribution.Approved_By__c}}</sui-table-cell>
                <sui-table-cell>
                    {{politicalContribution.Approved_Date__c}}
                </sui-table-cell>
                <sui-table-cell collapsing>
                    <div class="table-row-menu">
                        <div class="action-icons">
                            <sui-icon class="edit-icon" name="edit" @click="editPoliticalContribution(index)"/>
                            <sui-icon
                                class="trash-icon"
                                name="trash"
                                @click="removePoliticalContribution({index: index, id: politicalContribution.Id})"
                            />
                        </div>
                    </div>
                </sui-table-cell>
            </sui-table-row>
        </sui-table-body>
        <sui-table-footer full-width>
            <sui-table-row>
                <sui-table-header-cell />
                <sui-table-header-cell colspan="7">
                    <sui-button
                        type="button"
                        basic
                        content="Add"
                        floated="left"
                        size="small"
                        @click="handlePoliticalContributionForm"
                    />
                </sui-table-header-cell>
            </sui-table-row>
        </sui-table-footer>
    </sui-table>
</template>

<script>
export default {
    name: "PoliticalContributionTable",
    props: {
        politicalContributions: {type: Array},
        toggleIsNewPoliticalContribution: {type: Function},
        reset: {type: Function},
    },
    data() {
        return {
            columns: [
                'Amount',
                'Candidate Name',
                'Political Race',
                'Election Date',
                'Approved By',
                'Approved Date',
            ],
        }
    },
    methods: {
        handlePoliticalContributionForm() {
            this.reset();
            this.toggleIsNewPoliticalContribution();
            this.$emit('handlePoliticalContributionForm');
        },
        editPoliticalContribution(index) {
            this.$emit('getPoliticalContribution', index);
        },
        removePoliticalContribution(data) {
            this.$emit('removePoliticalContribution', data);
        }
    }
}
</script>

<style scoped>
.table-row-menu {
    display: flex;
    justify-content: space-between;
}
.edit-icon, .trash-icon {
    cursor: pointer;
    color: #8C979A;
}
</style>
