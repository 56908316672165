<template>
    <div class="tooltip">
        <sui-icon name="send" @click="sendEmail" />
        <span class="tooltiptext">Send notification</span>
    </div>
</template>

<script>

import {mapActions} from "vuex";
export default {
    name: "SendEmail",
    props: {
        supervisedPersonUserId: {
            type: String
        }
    },
    inject: ['notyf'],
    methods: {
        ...mapActions(["sendQuarterlyAttestationEmail"]),
        sendEmail() {
            this.notyf.success('A quarterly attestation notification was sent to the supervised person');
            this.sendQuarterlyAttestationEmail(this.supervisedPersonUserId);
        }
    }
}
</script>

<style scoped>
    .tooltip {
        position: relative;
        display: inline-block;
        cursor: pointer;
        color: #767676;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: #A9A9A9;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        
        position: absolute;
        z-index: 1;
        top: -5px;
        right: 105%;
    }

    .tooltip .tooltiptext::after {
        content: " ";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #A9A9A9;
    }
    
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

</style>
