import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'
import createPersistedState from "vuex-persistedstate"
import VueToastr from "vue-toastr";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import jwtDecode from 'jwt-decode'
import notyf from "notyf/notyf";
import endpoints from "./utils/endpoints"

Vue.use(Vuex);
Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultProgressBar: false,
    defaultProgressBarValue: 0,
    defaultPosition: "toast-top-center",
    defaultCloseOnHover: false,
});
Vue.use(Loading, {
    color: '#0e94ff',
    loader: 'bars',
    width: 40,
    height: 40,
    opacity: 0.4,
});

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.withCredentials = true;

export default new Vuex.Store({
    state: {
        jwt: localStorage.getItem('jwt'),
        isRequesting: false,
        isRequestSuccessful: false,
        responseStatus: null,
        isAccountConfirmed: false,
        isPasswordResetEmailCompleted: false,
        isPasswordResetCompleted: false,
        token : {
            access: '',
            refresh: '',
        },
        userProfile: {},
        isAuthenticated: false,
        user: {},
        users: [],
        advisors: [],
        userListFilter: {
            listView: 'ALL_USERS',
            firm: null,
            profileTypeTk: null,
            user: {
                id: null,
                profileTypeTk: null,
            }
        },
        profileTypes: [],
        firm: {},
        firms: [],
        states: [],
        attestationFilter: {
            firm: null,
            fiscalYear: null,
            fiscalQuarter: null,
        },
        quarterlyAttestation: null,
        quarterlyAttestations: [],
        outsideBusinessActivities: [],
        personalSecurities: [],
        politicalContributions: [],
        investmentModel: {},
        investmentModelList: [],
        investmentStrategy: null,
        investmentStrategyList: null,
        cashRequest: null,
        cashRequestList: null,
        feeSchedule: {},
        feeScheduleList: [],
        supervisedPerson: {},
        supervisedPersons: [],
        quarterlyAttestationSubmissionStatus: [],
        siteVerifyResponse: null,
        roles: [],
        userRoles: [],
        supportTicket: null,
        supportTicketList: [],
        clientAgreement: null,
        hasSubmittedClientAgreement: false,
        hasViewedOrDownloadedFormADV: false,
        hasViewedOrDownloadedFormCRS: false,
        investorProfile: {},
        isInvestorProfileSubmitted: false,
        firmFeeSchedule: {},
        firmFeeSchedules: [],
        firmModel: {},
        firmModels: [],
        staff: [],
        staffMember: {}

    },
    plugins: [createPersistedState()],
    getters: {
        isRequesting: state => state.isRequesting,
        responseStatus: state => state.responseStatus,
        isRequestSuccessful: state => state.isRequestSuccessful,
        isAccountConfirmed: state => state.isAccountConfirmed,
        isPasswordResetEmailCompleted: state => state.isPasswordResetEmailCompleted,
        isPasswordResetCompleted: state => state.isPasswordResetCompleted,
        userProfile: state => state.userProfile,
        isAuthenticated: state => state.isAuthenticated,
        user: state => state.user,
        users: state => state.users,
        advisors: state => state.advisors,
        userListFilter: state => state.userListFilter,
        firm: state => state.firm,
        firms: state => state.firms,
        states: state => state.states,
        profileTypes: state => state.profileTypes,
        attestationFilter: state => state.attestationFilter,
        quarterlyAttestations: state => state.quarterlyAttestations,
        quarterlyAttestation: state => state.quarterlyAttestation,
        outsideBusinessActivities: state => state.outsideBusinessActivities,
        personalSecurities: state => state.personalSecurities,
        politicalContributions: state => state.politicalContributions,
        investmentStrategy: state => state.investmentStrategy,
        investmentStrategyList: state => state.investmentStrategyList,
        cashRequest: state => state.cashRequest,
        cashRequestList: state => state.cashRequestList,
        investmentModel: state => state.investmentModel,
        investmentModelList: state => state.investmentModelList,
        feeSchedule: state => state.feeSchedule,
        feeScheduleList: state => state.feeScheduleList,
        supervisedPerson: state => state.supervisedPerson,
        supervisedPersons: state => state.supervisedPersons,
        quarterlyAttestationSubmissionStatus: state => state.quarterlyAttestationSubmissionStatus,
        siteVerifyResponse: state => state.siteVerifyResponse,
        roles: state => state.roles,
        userRoles: state => state.userRoles,
        supportTicket: state => state.supportTicket,
        supportTicketList: state => state.supportTicketList,
        clientAgreement: state => state.clientAgreement,
        hasSubmittedClientAgreement: state => state.hasSubmittedClientAgreement,
        hasViewedOrDownloadedFormADV: state => state.hasViewedOrDownloadedFormADV,
        hasViewedOrDownloadedFormCRS: state => state.hasViewedOrDownloadedFormCRS,
        investorProfile: state => state.investorProfile,
        isInvestorProfileSubmitted: state => state.isInvestorProfileSubmitted,
        firmFeeSchedule: state => state.firmFeeSchedule,
        firmFeeSchedules: state => state.firmFeeSchedules,
        firmModel: state => state.firmModel,
        firmModels: state => state.firmModels,
        staff: state => state.staff,
        staffMember: state => state.staffMember
    },
    mutations: {
        setIsRequesting(state, payload) {
            state.isRequesting = payload;
        },
        setIsRequestSuccessful(state, payload) {
            state.isRequestSuccessful = payload;
        },
        setResponseStatus(state, payload) {
            state.responseStatus = payload;
        },
        setIsAccountConfirmed(state, payload) {
            state.isAccountConfirmed = payload;
        },
        setIsPasswordResetEmailCompleted(state, payload) {
            state.isPasswordResetEmailCompleted = payload;
        },
        setIsPasswordResetCompleted(state, payload) {
            state.isPasswordResetCompleted = payload;
        },
        updateAccessToken(state, payload) {
            state.jwt = payload;
            state.token.access = payload;
        },
        updateRefreshToken(state, payload) {
            state.token = Object.assign({}, state.token, {"access": payload.refresh});
        },
        removeToken(state) {
            localStorage.removeItem('jwt');
            state.jwt = null;
        },
        setUserProfile(state, payload) {
            state.userProfile = Object.assign({}, state.userProfile, payload);
        },
        setIsAuthenticated(state, payload) {
            state.isAuthenticated = payload;
        },
        setUser(state, payload) {
            state.user = Object.assign({}, state.user, payload);
        },
        setUsers(state, users) {
            state.users = users;
        },
        setAdvisors(state, payload) {
            state.advisors = payload;
        },
        setUserListFilter(state, payload) {
            state.userListFilter = Object.assign({}, state.userListFilter, payload);
        },
        setFirm(state, payload) {
            state.firm = Object.assign({}, state.firm, payload);
        },
        setFirms(state, payload){
            state.firms = payload;
        },
        setStates(state, payload) {
            state.states = payload;
        },
        setProfileTypes(state, payload) {
            state.profileTypes = payload;
        },
        setAttestationFilter(state, payload) {
            state.attestationFilter = Object.assign({}, state.attestationFilter, payload);
        },
        setQuarterlyAttestations(state, payload) {
            state.quarterlyAttestations = payload;
        },
        setQuarterlyAttestation(state, payload) {
            state.quarterlyAttestation = payload;
        },
        setOutsideBusinessActivities(state, payload) {
            state.outsideBusinessActivities = payload;
        },
        setPersonalSecurities(state, payload) {
            state.personalSecurities = payload;
        },
        setPoliticalContributions(state, payload) {
            state.politicalContributions = payload;
        },
        setInvestmentStrategy(state, payload) {
            state.investmentStrategy = payload;
        },
        setInvestmentStrategyList(state, payload) {
            state.investmentStrategyList = payload;
        },
        setCashRequest(state, payload) {
            state.cashRequest = payload;
        },
        setCashRequestList(state, payload) {
            state.cashRequestList = payload;
        },
        setInvestmentModel(state, payload) {
            state.investmentModel = payload;
        },
        setInvestmentModelList(state, payload) {
            state.investmentModelList = payload;
        },
        setFeeSchedule(state, payload) {
            state.feeSchedule = payload;
        },
        setFeeScheduleList(state, payload) {
            state.feeScheduleList = payload;
        },
        setSupervisedPerson(state, payload) {
            state.supervisedPerson = payload;
        },
        setSupervisedPersons(state, payload) {
            state.supervisedPersons = payload;
        },
        setQuarterlyAttestationSubmissionStatus(state, payload) {
            state.quarterlyAttestationSubmissionStatus = payload;
        },
        setSiteVerifyResponse(state, payload) {
            state.siteVerifyResponse = payload;
        },
        setRoles(state, payload) {
            state.roles = payload;
        },
        setUserRoles(state, payload) {
            state.userRoles = payload;
        },
        setUserEmail(state, payload) {
            state.userProfile.email = payload;
        },
        setSupportTicket(state, payload) {
            state.supportTicket = payload;
        },
        setSupportTicketList(state, payload) {
            state.supportTicketList = payload;
        },
        setClientAgreement(state, payload) {
            state.clientAgreement = payload;
        },
        setHasSubmittedClientAgreement(state, payload) {
            state.hasSubmittedClientAgreement = payload;
        },
        setHasViewedOrDownloadedFormADV(state, payload) {
            state.hasViewedOrDownloadedFormADV = payload;
        },
        setHasViewedOrDownloadedFormCRS(state, payload) {
            state.hasViewedOrDownloadedFormCRS = payload;
        },
        setInvestorProfile(state, payload) {
            state.investorProfile = payload;
        },
        setIsInvestorProfileSubmitted(state, payload) {
            state.isInvestorProfileSubmitted = payload;
        },
        setFirmFeeSchedule(state, payload) {
            state.firmFeeSchedule = payload;
        },
        setFirmFeeSchedules(state, payload) {
            state.firmFeeSchedules = payload;
        },
        setFirmModel(state, payload) {
            state.firmModel = payload;
        },
        setFirmModels(state, payload) {
            state.firmModels = payload;
        },
        setStaff(state, payload) {
            state.staff = payload;
        },
        setStaffMember(state, payload) {
            state.staffMember = payload;
        }
    },
    actions: {
        verifyCaptcha({commit}, token) {
            return axios.post(endpoints.reCAPTCHA.verify, token)
                .then(response => {
                    commit('setSiteVerifyResponse', response.data);
                }).catch()
        },
        refreshToken({ commit }, token){
            axios.post(endpoints.auth.token.refreshToken, token)
                .then(response => {
                    commit("updateAccessToken", response.data.token);
                })
                .catch();
        },
        inspectToken({ state, commit }) {
            const token = state.jwt;
            if(token) {
                const decodedToken = jwtDecode(token)
                const exp = decodedToken.exp;
                const orig_iat = decodedToken.orig_iat;

                if (exp < (Date.now()/1000)) {
                    commit('setIsAuthenticated', false);
                } else if ((exp - Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200) {
                    this.dispatch("refreshToken");
                }
            } else {
                commit('setIsAuthenticated', false);
            }
        },
        login({commit}, payload) {
           return  axios
                .post(endpoints.auth.login, payload)
                .then(response => {
                    commit('updateAccessToken', response.data.token);
                    commit("setUserProfile", response.data.user);
                    commit("setIsAuthenticated", true);
                })
                .catch();
        },
        logout({ commit }) {
            return axios.post(endpoints.auth.logout)
                .then( () => {
                    commit('setUserProfile', null);
                    commit('setIsAuthenticated', false);
                    commit('removeToken');
                })
                .catch();
        },
        forgotPassword({ commit }, payload) {
            axios.post(endpoints.auth.forgotPassword, payload)
                .then(() => {
                    commit("setIsRequesting", false);
                })
                .catch(() => {
                    commit("setIsRequesting", false);
                });
        },
        resetPassword(context, payload) {
            return axios.post(endpoints.auth.resetPassword, payload)
                .then()
                .catch();
        },
        confirmAccount({ commit }, payload ) {
            axios({
                method: "post",
                url: endpoints.auth.confirmAccount,
                data: payload,
            })
                .then( () => {
                    commit("setIsAccountConfirmed", true);
                    commit("setIsRequesting", false);
                })
                .catch( () => {
                    commit("setIsRequesting", false);
                })
        },
        changePassword({ state, commit }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'put',
                url: endpoints.settings.password,
                data: payload
            })
                .then(response => {
                    commit("updateAccessToken", response.data.token);
                    return response;
                })
                .catch();
        },
        changeEmail({ state, commit }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'put',
                url: endpoints.settings.email,
                data: payload
            })
                .then(response => {
                    commit("updateAccessToken", response.data.token);
                    commit("setUserProfile", response.data.user);
                    return response;
                })
                .catch();
        },
        updatePersonalInfo({ state }, user) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'put',
                url: endpoints.settings.profile + `${user.id}/`,
                data: user
            })
                .then()
                .catch();
        },
        getAllFirms({ state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.firms,
                method: "get",
            })
                .then((response) => {
                    commit("setFirms", response.data.results);
                })
        },
        createFirm({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.firms,
                data: payload,
            })
                .then()
                .catch();
        },
        getFirm({ state, commit }, firmId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.firms + firmId,
                method: "get",
            })
                .then((response) => {
                    commit("setFirm", response.data);
                }).catch();
        },
        updateFirm({state}, firm) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.firms + firm.id + '/',
                data: firm
            })
                .then()
                .catch();
        },
        deleteFirm({state}, firmId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.firms + firmId + '/',
            })
                .then()
                .catch();
        },
        getStates({ state, commit }) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            axiosInstance({
                url: endpoints.states,
                method: "get",
                params: {}
            })
                .then((response) => {
                    commit("setStates", response.data.results);
                })
        },
        getUsers({ state, commit }, filter) {
            if (filter !== undefined) {
                const base = {
                    baseURL: endpoints.baseURL,
                    headers: {
                        Authorization: `JWT ${state.token.access}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    }
                };
                const axiosInstance = axios.create(base);
                return axiosInstance({
                    url: endpoints.users + 'filter/',
                    method: "get",
                    params: filter,
                })
                    .then((response) => {
                        commit("setUsers", response.data);
                    })
                    .catch()
            }
        },
        getAllUsers({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users,
                method: "get",
            })
                .then((response) => {
                    commit("setUsers", response.data.results);
                })
                .catch()
        },
        getFirmUsers({ state, commit }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users + "firm_users/",
                method: "get",
                params: payload
            })
                .then((response) => {
                    commit("setUsers", response.data);
                })
                .catch()
        },
        getAdvisorClients({ state, commit }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users + "firm_advisor_clients/",
                method: "get",
                params: payload
            })
                .then((response) => {
                    commit("setUsers", response.data);
                })
                .catch()
        },
        getAllAdvisors({ state, commit }, profileTypeTk) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users + profileTypeTk + '/all_advisors/',
                method: "get",
                params: profileTypeTk,
            })
                .then((response) => {
                    commit("setAdvisors", response.data);
                })
                .catch()
        },
        getFirmAdvisors({ state, commit }, firmId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users + firmId + '/firm_advisors/',
                method: "get",
            })
                .then((response) => {
                    commit("setAdvisors", response.data);
                })
                .catch()
        },
        createUser({ state }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.users,
                data: payload.user,
                params: {
                    notifyUser: payload.notifyUser,
                }
            })
                .then(response => {
                    if(response.status === 409) {
                        notyf.error(response.data);
                    }
                })
        },
        getUser({ state, commit }, id) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users + id ,
                method: "get",
            })
                .then(response => commit("setUser", response.data))
                .catch();
        },
        updateUser({state, commit},  payload) {
            console.log(payload);
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'put',
                url: endpoints.users + payload.id + '/',
                data: payload
            })
                .then(response => {
                    commit("setUser", response.data)
                })
                .catch();
        },
        sendAccountActivationLink({state}, payload) {
            const userId = state.user.id;
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'post',
                url: endpoints.users + userId + '/send_account_activation_link/',
                data: payload
            })
                .then()
                .catch();
        },
        getUserProfile({state, commit},  userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'get',
                url: endpoints.users + userId + '/',
            })
                .then(response => commit("setUserProfile", response.data))
                .catch();
        },
        updateProfile({state, commit},  payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'put',
                url: endpoints.users + payload.id + '/',
                data: payload
            })
                .then(response => commit("setUserProfile", response.data))
                .catch();
        },
        deleteUser({ state, commit }, userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.users + `${userId}/`,
                method: "delete",
            })
                .then(() => {
                    const users = JSON.parse(JSON.stringify(state.users));
                    for (let i = 0; i <= users.length; i++) {
                        if (users[i].id === userId) {
                            const index = users.indexOf(users[i]);
                            if (index > -1) {
                                users.splice(index, 1);
                                commit("setUsers", users);
                            }
                        }
                    }
                })
                .catch()
        },
        getProfileTypes({ state, commit }) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.profileTypes,
                method: "get",
                params: {}
            })
                .then((response) => {
                    commit("setProfileTypes", response.data.results);
                })
        },
        getQuarterlyAttestations({state, commit}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: 'get',
                url: endpoints.quarterlyAttestations,
                params: payload,
                data: payload,
            })
                .then(response => {
                    commit("setQuarterlyAttestations", response.data);
                })
                .catch();

        },
        getQuarterlyAttestation({state, commit}, quarterlyAttestationId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.quarterlyAttestations + quarterlyAttestationId,
                method: "get",
            })
                .then(response => {
                    commit("setQuarterlyAttestation", response.data);
                })
                .catch();
        },
        getUserQuarterlyAttestations({state, commit}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.userQuarterlyAttestations + state.userProfile.id,
                method: "get",
                param: {},
                data: payload
            })
                .then(response => {
                    commit("setQuarterlyAttestations", response.data)
                })
                .catch();
        },
        getMostRecentQuarterlyAttestation ({state, commit}, userId) {
            /**
             * For users with multiple login account, verify logged-user against route parameters
             */
            if (state.userProfile.id && state.userProfile.id === userId) {
                const base = {
                    baseURL: endpoints.baseURL,
                    headers: {
                        Authorization: `JWT ${state.token.access}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    }
                };
                const axiosInstance = axios.create(base);
                return axiosInstance({
                    url: endpoints.quarterlyAttestations + userId + '/most_recent/',
                    method: 'get',
                })
                    .then(response => {
                        commit('setQuarterlyAttestation', response.data);
                    })
                    .catch();
            } else {
                commit('setUserProfile', null);
                commit('setIsAuthenticated', false);
                commit('removeToken');
                router.push({name: 'login'});
            }
        }
        ,
        saveQuarterlyAttestation({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                url: endpoints.quarterlyAttestations,
                method: "post",
                data: payload
            })
                .then()
                .catch();
        },
        updateQuarterlyAttestation({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.quarterlyAttestations + payload.quarterlyAttestation.Id + '/',
                data: payload
            })
                .then()
                .catch();
        },
        filterAttestations({state, commit}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.quarterlyAttestations + payload.firm + '/firm',
                params: payload,
            })
                .then(response => {
                    commit('setQuarterlyAttestations', response.data);
                    commit('setAttestationFilter', payload);
                }).catch();

        },
        getUserAttestations({state, commit}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.quarterlyAttestations + payload.user + '/user',
                params: payload,
            })
                .then(response => {
                    commit('setQuarterlyAttestations', response.data);
                }).catch();

        },
        deleteQuarterlyAttestation({state}, quarterlyAttestationId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.quarterlyAttestations + quarterlyAttestationId + '/',
            })
                .then()
                .catch();
        },
        deletePersonalSecurity({state}, personalSecurityId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            axiosInstance({
                method: "delete",
                url: endpoints.personalSecurities + personalSecurityId + '/',
            })
                .then()
                .catch();
        },
        deleteOutsideBusinessActivity({state}, businessActivityId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            axiosInstance({
                method: "delete",
                url: endpoints.outsideBusinessActivities + businessActivityId + '/',
            })
                .then()
                .catch();
        },
        deletePoliticalContribution({state}, politicalContributionId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            axiosInstance({
                method: "delete",
                url: endpoints.politicalContributions + politicalContributionId + '/',
            })
                .then()
                .catch();
        },
        saveInvestmentStrategy({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.investmentStrategies,
                data: payload,
            })
                .then()
                .catch();
        },
        updateInvestmentStrategy({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.investmentStrategies + payload.Id + '/',
                data: payload,
            })
                .then()
                .catch();
        },
        getInvestmentStrategy({state, commit}, investmentStrategyId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.investmentStrategies + investmentStrategyId,
            }).then(response =>{
                commit('setInvestmentStrategy', response.data);
            })
        },
        getClientInvestmentStrategyList({state, commit}, userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.investmentStrategies,
                params: {clientId: userId},
            }).then(response  => {
                    commit('setInvestmentStrategyList', response.data);
            }).catch();
        },
        saveCashRequest({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.cashRequests,
                data: payload,
            })
                .then()
                .catch();
        },
        updateCashRequest({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.cashRequests + payload.Id + '/',
                data: payload,
            })
                .then()
                .catch();
        },
        getCashRequest({state, commit}, cashRequestId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.cashRequests + cashRequestId,
            }).then(response =>{
                commit('setCashRequest', response.data);
            })
        },
        getClientCashRequestList({state, commit}, userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.cashRequests,
                params: {clientId: userId},
            }).then(response  => {
                commit('setCashRequestList', response.data);
            }).catch();
        },
        getAllInvestmentModels({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.investmentModels,
            }).then(response  => {
                commit('setInvestmentModelList', response.data.results);
            }).catch();
        },
        getFirmInvestmentModels({state, commit}, firmTk) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.investmentModels + firmTk + '/firm_investment_models/',
            }).then(response  => {
                commit('setInvestmentModelList', response.data);
            }).catch();
        },
        getAdvisorInvestmentModels({state, commit}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.investmentModels + payload.firmTk + '/advisor_investment_models/',
                params: {
                    financialAdvisorUserId: payload.financialAdvisorUserId
                },
            }).then(response  => {
                commit('setInvestmentModelList', response.data);
            }).catch();
        },
        createInvestmentModel({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.investmentModels,
                data: payload,
            })
                .then()
                .catch();
        },
        getInvestmentModel({state, commit}, modelId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.investmentModels + modelId,
            }).then(response  => {
                commit('setInvestmentModel', response.data);
            }).catch();
        },
        updateInvestmentModel({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.investmentModels + payload.id + '/',
                data: payload,
            })
                .then()
                .catch();
        },
        deleteInvestmentModel({state}, modelId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.investmentModels + modelId + '/',
            })
                .then()
                .catch();
        },
        getAllFeeSchedules({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.feeSchedules,
            }).then(response  => {
                commit('setFeeScheduleList', response.data.results);
            }).catch();
        },
        getFirmFeeSchedules({state, commit}, firmTk, userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.feeSchedules + firmTk + '/firm_fee_schedules/',
                params: {
                    userId: userId,
                },
            }).then(response  => {
                commit('setFeeScheduleList', response.data);
            }).catch();
        },
        getAdvisorFeeSchedules({state, commit}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.feeSchedules + payload.firmTk + '/advisor_fee_schedules/',
                params: {
                    financialAdvisorUserId : payload.financialAdvisorUserId
                },
            }).then(response  => {
                commit('setFeeScheduleList', response.data);
            }).catch();
        },
        createFeeSchedule({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.feeSchedules,
                data: payload,
            })
                .then()
                .catch();
        },
        getFeeSchedule({state, commit}, feeScheduleId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.feeSchedules + feeScheduleId,
            }).then(response  => {
                commit('setFeeSchedule', response.data);
            }).catch();
        },
        updateFeeSchedule({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.feeSchedules + payload.id + '/',
                data: payload,
            })
                .then()
                .catch();
        },
        deleteFeeSchedule({state}, feeScheduleId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.feeSchedules + feeScheduleId + '/',
            })
                .then()
                .catch();
        },
        getAllSupervisedPersons({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.supervisedPersons,
            }).then(response  => {
                commit('setSupervisedPersons', response.data.results);
            }).catch();
        },
        getFirmSupervisedPersons({state, commit}, firmTk) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.supervisedPersons + firmTk + '/firm/',
            }).then(response  => {
                commit('setSupervisedPersons', response.data);
            }).catch();
        },
        getSupervisedPerson({state, commit}, supervisedPersonId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.supervisedPersons + `${supervisedPersonId}/`,
            })
                .then(response  => {
                    commit("setSupervisedPerson", response.data);
                }).catch();
        },
        getSupervisedPersonByUserId({state, commit}, supervisedPersonUserId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.supervisedPersons + `${supervisedPersonUserId}/get_supervised_person_by_user_id/`,
            })
                .then(response  => {
                    commit("setSupervisedPerson", response.data);
                }).catch();
        },
        addSupervisedPerson({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.supervisedPersons,
                data: payload,
            })
                .then()
                .catch();
        },
        updateSupervisedPerson({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.supervisedPersons + `${payload.id}/`,
                data: payload,
            })
                .then()
                .catch();
        },
        removeSupervisedPerson({state}, id) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.supervisedPersons + id + '/',
            })
                .then()
                .catch();
        },
        getAllAttestationASubmissionStatus({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.quarterlyAttestationsSubmissionStatus,
            }).then(response  => {
                commit('setQuarterlyAttestationSubmissionStatus', response.data.results);
            }).catch();
        },
        getFirmAttestationSubmissionStatus({state, commit}, firmId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.quarterlyAttestationsSubmissionStatus + firmId + '/firm/',
            }).then(response  => {
                commit('setQuarterlyAttestationSubmissionStatus', response.data);
            }).catch();
        },
        getRoles({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.roles,
            }).then(response => {
                commit('setRoles', response.data.results);
            }).catch();
        },
        getUserRoles({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.userRoles,
            }).then(response => {
                commit('setUserRoles', response.data.results);
            }).catch();
        },
        createSupportTicket({state}, data) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.supportTickets,
                data: data,
            })
                .then()
                .catch();
        },
        updateSupportTicket({state}, data) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.supportTickets + data.Id + '/',
                data: data,
            })
                .then()
                .catch();
        },
        getSupportTicket({state, commit}, ticketId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.supportTickets + ticketId,
            })
                .then(response => {
                    commit("setSupportTicket", response.data);
                })
                .catch();
        },
        getSupportTicketList({state, commit}) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.supportTickets,
            })
                .then(response => {
                    commit("setSupportTicketList", response.data);
                })
                .catch();
        },
        createClientAgreement({state, commit}, data) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.clientAgreements,
                data: data,
            })
                .then(() =>{
                    commit("setHasSubmittedClientAgreement", true);
                })
                .catch();
        },
        getClientAgreement({state, commit}, userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.clientAgreements + userId,
            })
                .then(response => {
                    commit("setClientAgreement", response.data)
                })
                .catch();
        },
        updateClientAgreement({state}, data){
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: `${endpoints.clientAgreements} ${data.Id}/`,
                data: data,
            })
                .then()
                .catch();
        },
        updateContactInitialFormAdvDeliveryDate({state, commit}, data) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.contacts.updateFormAdvDeliveryDate,
                data: data,
            })
                .then(() => {
                    commit("setHasViewedOrDownloadedFormADV", true);
                })
                .catch();
        },
        updateContactInitialFormCrsDeliveryDate({state, commit}, data) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.contacts.updateFormCrsDeliveryDate,
                data: data,
            })
                .then(() => {
                    commit("setHasViewedOrDownloadedFormCRS", true);
                })
                .catch();
        },
        ssoIntoBlackDiamond({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.sso,
                params: payload
            })
                .then()
                .catch();
        },
        submitInvestorProfile({state}, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url:endpoints.investorProfile + `${state.userProfile.id}/`,
                data: payload
            })
                .then()
                .catch();
        },
        getInvestorProfile({state, commit}, email) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url:endpoints.investorProfile + `${state.userProfile.id}/`,
                params: {portalUserEmail: email}
            })
                .then(response => {
                    commit("setInvestorProfile", response.data)
                })
                .catch();
        },
        getAllFirmModels({ state, commit }) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmModels,
            }).then(response  => {
                commit('setFirmModels', response.data.results);
            }).catch();
        },
        getFirmModels({ state, commit }, firmId) {

            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmModels + "firm/",
                params: { firm: firmId }
            }).then(response  => {
                commit('setFirmModels', response.data);
            }).catch();
        },
        getAdvisorFirmModels({ state, commit }, params) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmModels + "advisor/",
                params: params
            }).then(response  => {
                commit('setFirmModels', response.data);
            }).catch();
        },
        createFirmModel({ state }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.firmModels,
                data: payload,
            })
                .then()
                .catch();
        },
        getFirmModel({state, commit }, firmModelId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmModels + `${firmModelId}/`,
            }).then(response  => {
                commit('setFirmModel', response.data);
            }).catch();
        },
        updateFirmModel({ state }, firmModel) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.firmModels + `${firmModel.id}/`,
                data: firmModel,
            })
                .then()
                .catch();
        },
        deleteFirmModel({ state }, firmModelId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.firmModels + `${firmModelId}/`,
            })
                .then()
                .catch();
        },
        getAllFirmFeeSchedules({ state, commit }) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmFeeSchedules,
            }).then(response  => {
                commit('setFirmFeeSchedules', response.data.results);
            }).catch();
        },
        getFirmsFeeSchedules({ state, commit }, firmId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmFeeSchedules +"firm/",
                params: { firm: firmId }
            }).then(response  => {
                commit("setFirmFeeSchedules", response.data);
            }).catch();
        },
        getAdvisorFirmFeeSchedules({ state, commit }, params) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmFeeSchedules + "advisor/",
                params: params
            }).then(response  => {
                commit('setFirmFeeSchedules', response.data);
            }).catch();
        },
        createFirmFeeSchedule({ state }, payload) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.firmFeeSchedules,
                data: payload,
            })
                .then()
                .catch();
        },
        getFirmFeeSchedule({state, commit }, firmFeeScheduleId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.firmFeeSchedules + `${firmFeeScheduleId}/`,
            }).then(response  => {
                commit('setFirmFeeSchedule', response.data);
            }).catch();
        },
        updateFirmFeeSchedule({ state }, firmFeeSchedule) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.firmFeeSchedules + `${firmFeeSchedule.id}/`,
                data: firmFeeSchedule,
            })
                .then()
                .catch();
        },
        deleteFirmFeeSchedule({ state }, firmFeeScheduleId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "delete",
                url: endpoints.firmFeeSchedules + `${firmFeeScheduleId}/`,
            })
                .then()
                .catch();
        },

        sendQuarterlyAttestationEmail({ state }, userId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "post",
                url: endpoints.quarterlyAttestations + "send_email/",
                data: {"supervisedPersonUserId": userId}
            })
                .then()
                .catch();
        },

        getStaff({ state, commit }){
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.employees,
            })
                .then(response => {
                    commit("setStaff", response.data);
                })
                .catch();
        },

        getStaffMember({ state, commit }, staffMemberId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "get",
                url: endpoints.employees + staffMemberId,
            })
                .then(response => {
                    commit("setStaffMember", response.data);
                })
                .catch();
        },
        updateStaffMember({ state }, data) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.employees + data.Id + "/",
                data: data
            })
                .then()
                .catch();
        },
        removeStaffMember({ state }, memberId) {
            const base = {
                baseURL: endpoints.baseURL,
                headers: {
                    Authorization: `JWT ${state.token.access}`,
                    'Content-Type': 'application/json'
                },
                xhrFields: {
                    withCredentials: true
                }
            };
            const axiosInstance = axios.create(base);
            return axiosInstance({
                method: "put",
                url: endpoints.employees + memberId + "/remove_member"
            })
                .then()
                .catch();
        }
    },
})
