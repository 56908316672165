<template>
    <sui-table basic="very" celled>
        <sui-table-header>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell v-for="(column, index) in columns" :key="index">
                {{column}}
            </sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
        </sui-table-header>
        <sui-table-body v-if="outsideBusinessActivities">
            <sui-table-row v-for="(businessActivity, index) in outsideBusinessActivities" :key="index">
                <sui-table-cell>{{ index + 1}}</sui-table-cell>
                <sui-table-cell>{{businessActivity.Name}}</sui-table-cell>
                <sui-table-cell>{{businessActivity.Approved_By__c}}</sui-table-cell>
                <sui-table-cell>{{businessActivity.Approved_Date__c}}</sui-table-cell>
                <sui-table-cell collapsing>
                    <div class="table-row-menu">
                        <div class="action-icons">
                            <sui-icon class="edit-icon" name="edit" @click="editOutsideBusinessActivity(index)"/>
                            <sui-icon
                                class="trash-icon"
                                name="trash"
                                @click="removeOutsideBusinessActivity({index: index, id: businessActivity.Id})"/>
                        </div>
                    </div>
                </sui-table-cell>
            </sui-table-row>
        </sui-table-body>
        <sui-table-footer full-width>
            <sui-table-row>
                <sui-table-header-cell />
                <sui-table-header-cell colspan="4">
                    <sui-button
                        type="button"
                        basic
                        content="Add"
                        floated="left"
                        size="small"
                        @click="handleBusinessActivityForm"
                    />
                </sui-table-header-cell>
            </sui-table-row>
        </sui-table-footer>
    </sui-table>
</template>

<script>
export default {
    name: "OutsideBusinessActivityTable",
    props: {
        outsideBusinessActivities: {type: Array},
        toggleIsNewOutsideBusinessActivity: {type: Function},
        reset: {type: Function},
    },
    data() {
        return {
            columns: [
                'Outside Business Activity',
                'Approved By',
                'Approved Date'
                
            ],
        }
    },
    methods: {
        handleBusinessActivityForm() {
            this.reset();
            this.toggleIsNewOutsideBusinessActivity();
            this.$emit('handleOutsideBusinessActivityForm');
        },
        editOutsideBusinessActivity(index) {
            this.$emit('getOutsideBusinessActivity', index);
        },
        removeOutsideBusinessActivity(data) {
            this.$emit('removeOutsideBusinessActivity', data);
        }
    }
}
</script>

<style scoped>
.table-row-menu {
    display: flex;
    justify-content: space-between;
}
.edit-icon, .trash-icon {
    cursor: pointer;
    color: #8C979A;
}
</style>
