<template>
    <sui-grid>
        <sui-grid-row>
            <sui-grid-column :width="8">
                <h3 class="is-sui-header client-header">
                    Client
                    <span style="font-size: small !important;">(Minor, if custodial account)</span>
                </h3>
                <hr class="client-header-underline">
            </sui-grid-column>
            <sui-grid-column :width="8">
                <h3 class="is-sui-header joint-client-header">
                    Joint Client
                    <span style="font-size: small !important;">(Custodian, if custodial account)</span>
                </h3>
                <hr class="joint-client-header-underline">
            </sui-grid-column>
        </sui-grid-row>
        <sui-grid-row>
            <ClientInput
                :agreement="agreement"
                :states="states"
                :mailPreferenceOptions="mailPreferenceOptions"
                :employmentStatusOptions="employmentStatusOptions"
                :submitted="submitted"
            />
            <sui-grid-column :width="8">
                <section>
                    <sui-form-field required>
                        <label>Name</label>
                        <input type="text" placeholder="First Name, Middle Initial, Last Name"
                               v-model.trim="$v.agreement.JtCltName__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.JtCltName__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.JtCltName__c.required" class="invalid-feedback">
                            Enter first name, middle initial, last name
                        </div>
                    </sui-form-field>
                </section>
                <section style="margin-top: 14.9em">
                    <sui-form-field>
                        <sui-checkbox
                            label="Address same as client address"
                            @change="setAddressSameAsClientAddress($event)"
                            v-model="isAddressSameClientAddress" />
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Home Address</label>
                        <input type="text" placeholder="Street" v-model="$v.agreement.JtCltHomeAddressStreet__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.JtCltHomeAddressStreet__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.JtCltHomeAddressStreet__c.required" class="invalid-feedback">
                            Enter street
                        </div>
                    </sui-form-field>
                    <sui-form-fields>
                        <sui-form-field width="six" required>
                            <input type="text" placeholder="City" v-model="$v.agreement.JtCltHomeAddressCity__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltHomeAddressCity__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltHomeAddressCity__c.required" class="invalid-feedback">
                                Enter city
                            </div>
                        </sui-form-field>
                        <sui-form-field width="six" required>
                            <sui-dropdown
                                placeholder="State"
                                selection
                                direction="downward"
                                :options="states"
                                v-model="$v.agreement.JtCltHomeAddressState__c.$model"
                                :class="{ 'is-invalid': submitted && $v.agreement.JtCltHomeAddressState__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltHomeAddressState__c.required" class="invalid-feedback">
                                Enter state
                            </div>
                        </sui-form-field>
                        <sui-form-field width="four" required>
                            <input type="text" placeholder="Zip" v-model="$v.agreement.JtCltHomeAddressZipCode__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltHomeAddressZipCode__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltHomeAddressState__c.required" class="invalid-feedback">
                                Enter zip code
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field>
                        <sui-checkbox label="Home address same as mailing address" v-model="isJtClientHomeAddressSamesAsMailingAddress" />
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Mailing Address</label>
                        <input type="text" placeholder="Street" v-model="$v.agreement.JtCltMailingAddressStreet__c.$model"
                           :class="{ 'is-invalid': submitted && $v.agreement.JtCltMailingAddressStreet__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.JtCltMailingAddressStreet__c.required" class="invalid-feedback">
                            Enter street
                        </div>
                    </sui-form-field>
                    <sui-form-fields>
                        <sui-form-field width="six" required>
                            <input type="text" placeholder="City" v-model="$v.agreement.JtCltMailingAddressCity__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltMailingAddressCity__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltMailingAddressCity__c.required" class="invalid-feedback">
                                Enter city
                            </div>
                        </sui-form-field>
                        <sui-form-field width="six" required>
                            <sui-dropdown
                                placeholder="State"
                                selection
                                direction="downward"
                                :options="states"
                                v-model="$v.agreement.JtCltMailingAddressState__c.$model"
                                :class="{ 'is-invalid': submitted && $v.agreement.JtCltMailingAddressState__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltMailingAddressState__c.required" class="invalid-feedback">
                                Enter state
                            </div>
                        </sui-form-field>
                        <sui-form-field width="four" required>
                            <input type="text" placeholder="Zip" v-model="$v.agreement.JtCltMailingAddressZipCode__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltMailingAddressZipCode__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltMailingAddressZipCode__c.required" class="invalid-feedback">
                                Enter zip code
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field>
                        <label>Work Address</label>
                        <input type="text" placeholder="Street" v-model="agreement.JtCltWorkAddressStreet__c" />
                    </sui-form-field>
                    <sui-form-fields>
                        <sui-form-field width="six">
                            <input type="text" placeholder="City" v-model="agreement.JtCltWorkAddressCity__c" />
                        </sui-form-field>
                        <sui-form-field width="six">
                            <sui-dropdown
                                placeholder="States"
                                selection
                                direction="downward"
                                :options="states"
                                v-model="agreement.JtCltWorkAddressState__c"
                            />
                        </sui-form-field>
                        <sui-form-field width="four">
                            <input type="text" placeholder="Zip" v-model="agreement.JtCltWorkAddressZipCode__c" />
                        </sui-form-field>
                    </sui-form-fields>
                </section>
                <section style="margin-top: 4em">
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <Label>Cell Phone</Label>
                            <input type="text" placeholder="Cell Phone" v-model="$v.agreement.JtCltCellPhone__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltCellPhone__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltCellPhone__c.required" class="invalid-feedback">
                                Enter cell phone
                            </div>
                        </sui-form-field>
                        <sui-form-field required>
                            <Label>Business Phone</Label>
                            <input type="text" placeholder="Business Phone" v-model="$v.agreement.JtCltBusinessPhone__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltBusinessPhone__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltBusinessPhone__c.required" class="invalid-feedback">
                                Enter business phone
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <Label>Home Phone</Label>
                            <input type="text" placeholder="Home Phone" v-model="$v.agreement.JtCltHomePhone__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltHomePhone__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltHomePhone__c.required" class="invalid-feedback">
                                Enter home phone
                            </div>
                        </sui-form-field>
                        <sui-form-field required>
                            <Label>Email Address</Label>
                            <input type="email" placeholder="Email Address" v-model="$v.agreement.JtCltEmailAddress__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltEmailAddress__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltEmailAddress__c.required" class="invalid-feedback">
                                Enter email address
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field :width="8" required>
                        <label>Mail Preference</label>
                        <sui-dropdown
                            placeholder="Mail Preference"
                            selection
                            direction="downward"
                            :options="mailPreferenceOptions"
                            v-model="$v.agreement.JtCltMailPreference__c.$model"
                            :class="{ 'is-invalid': submitted && $v.agreement.JtCltMailPreference__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.JtCltMailPreference__c.required" class="invalid-feedback">
                            Enter employment status
                        </div>
                    </sui-form-field>
                </section>
                <section style="margin-top: 4em">
                    <sui-form-field :width="8" required>
                        <label>Employment Status</label>
                        <sui-dropdown
                            placeholder="Employment Status"
                            selection
                            direction="downward"
                            :options="employmentStatusOptions"
                            v-model="$v.agreement.JtCltEmploymentStatus__c.$model"
                            :class="{ 'is-invalid': submitted && $v.agreement.JtCltEmploymentStatus__c.$error }"
                        />
                        <div v-if="submitted && !$v.agreement.JtCltEmploymentStatus__c.required" class="invalid-feedback">
                            Enter employment status
                        </div>
                    </sui-form-field>
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <Label>Employer</Label>
                            <input type="text" placeholder="Former if retired or unemployed" v-model="agreement.JtCltEmployer__c" />
                        </sui-form-field>
                        <sui-form-field>
                            <Label>Occupation</Label>
                            <input type="text" placeholder="Former if retired or unemployed" v-model="agreement.JtCltOccupation__c" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields inline>
                        <label style="margin-right: 10px!important;">
                            Are you employed by a broker-dealer, securities exchange, or FINRA?
                        </label>
                        <sui-form-field>
                            <sui-checkbox v-model="agreement.IsJtCltEmployedByBrokerDealer__c" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields inline>
                        <label style="margin-right: 10px!important;">
                            Are you a senior officer, director, or large shareholder of a public company?
                        </label>
                        <sui-form-field>
                            <sui-checkbox v-model="agreement.IsJtCltSeniorOfficerOfPublicCompany__c" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field :width="8" v-if="agreement.IsJtCltSeniorOfficerOfPublicCompany__c">
                        <label>Public Company Name</label>
                        <input type="text" placeholder="Company Name" v-model="agreement.JtCltPublicCompany__c" />
                    </sui-form-field>
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <Label>Date of Birth</Label>
                            <input type="text" placeholder="MM/DD/YYYY" v-model="$v.agreement.JtCltDoB__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltDoB__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltDoB__c.required" class="invalid-feedback">
                                Enter date of birth
                            </div>
                        </sui-form-field>
                        <sui-form-field required>
                            <Label>SSN</Label>
                            <input type="text" placeholder="SSN" v-model="$v.agreement.JtCltSSN__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltSSN__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltSSN__c.required" class="invalid-feedback">
                                Enter social security number
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field>
                        <sui-form-fields equal-width>
                            <sui-form-field required>
                                <label>Driver's License Number</label>
                                <input type="text" placeholder="Driver's License Number" v-model="$v.agreement.JtCltDriversLicense__c.$model"
                                   :class="{ 'is-invalid': submitted && $v.agreement.JtCltDriversLicense__c.$error }"
                                />
                                <div v-if="submitted && !$v.agreement.JtCltDriversLicense__c.required" class="invalid-feedback">
                                    Enter drivers license number
                                </div>
                            </sui-form-field>
                            <sui-form-field required>
                                <label>Driver's License State or Country of Issuance</label>
                                <input type="text" placeholder="State or Country of Issuance" v-model="$v.agreement.JtCltDriversLicenseIssueState__c.$model"
                                   :class="{ 'is-invalid': submitted && $v.agreement.JtCltDriversLicenseIssueState__c.$error }"
                                />
                                <div v-if="submitted && !$v.agreement.JtCltDriversLicenseIssueState__c.required" class="invalid-feedback">
                                    Enter drivers license issue state
                                </div>
                            </sui-form-field>
                        </sui-form-fields>
                    </sui-form-field>
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <label>Driver's License Issue Date</label>
                            <input type="text" placeholder="MM/DD/YYYY" v-model="$v.agreement.JtCltDriversLicenseIssueDate__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltDriversLicenseIssueDate__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltDriversLicenseIssueDate__c.required" class="invalid-feedback">
                                Enter drivers license issue date
                            </div>
                        </sui-form-field>
                        <sui-form-field required>
                            <label>Driver's License Expiration Date</label>
                            <input type="text" placeholder="MM/DD/YYYY" v-model="$v.agreement.JtCltDriversLicenseExpirationDate__c.$model"
                               :class="{ 'is-invalid': submitted && $v.agreement.JtCltDriversLicenseExpirationDate__c.$error }"
                            />
                            <div v-if="submitted && !$v.agreement.JtCltDriversLicenseExpirationDate__c.required" class="invalid-feedback">
                                Enter drivers license expiration date
                            </div>
                        </sui-form-field>
                    </sui-form-fields>
                </section>
            </sui-grid-column>
        </sui-grid-row>
    </sui-grid>
</template>

<script>
    import ClientInput from "@/components/clientForms/client-agreement/ClientFormInput";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "JointClientInput",
        components: {
            ClientInput
        },
        props: {
            agreement: {
                type: Object,
                required: true
            },
            states: {
                type: Array,
                required: true
            },
            mailPreferenceOptions: {
                type: Array,
                required: true
            },
            employmentStatusOptions: {
                type: Array,
                required: true
            },
            submitted: {
                type: Boolean
            }
        },
        data() {
            return {
                isJtClientHomeAddressSamesAsMailingAddress: false,
                isAddressSameClientAddress: false
            }
        },
        watch: {
            isJtClientHomeAddressSamesAsMailingAddress: function () {
                if (this.isJtClientHomeAddressSamesAsMailingAddress) {
                    this.agreement.JtCltMailingAddressStreet__c = this.agreement.JtCltHomeAddressStreet__c;
                    this.agreement.JtCltMailingAddressCity__c = this.agreement.JtCltHomeAddressCity__c;
                    this.agreement.JtCltMailingAddressState__c = this.agreement.JtCltHomeAddressState__c;
                    this.agreement.JtCltMailingAddressZipCode__c = this.agreement.JtCltHomeAddressZipCode__c;
                }
            },
        },
        methods: {
            setAddressSameAsClientAddress(event) {
                if (event) {
                    this.agreement.JtCltHomeAddressStreet__c = this.agreement.ClientHomeAddressStreet__c;
                    this.agreement.JtCltHomeAddressCity__c = this.agreement.ClientHomeAddressCity__c;
                    this.agreement.JtCltHomeAddressState__c = this.agreement.ClientHomeAddressState__c;
                    this.agreement.JtCltHomeAddressZipCode__c = this.agreement.ClientHomeAddressZipCode__c;
                    this.agreement.JtCltMailingAddressStreet__c = this.agreement.ClientMailingAddressStreet__c;
                    this.agreement.JtCltMailingAddressCity__c = this.agreement.ClientMailingAddressCity__c;
                    this.agreement.JtCltMailingAddressState__c = this.agreement.ClientMailingAddressState__c;
                    this.agreement.JtCltMailingAddressZipCode__c = this.agreement.ClientMailingAddressZipCode__c;
                }
            }
        },
        validations: {
            agreement: {
                ClientName__c: { required },
                ClientHomeAddressStreet__c: { required },
                ClientHomeAddressCity__c: { required },
                ClientHomeAddressState__c: { required },
                ClientHomeAddressZipCode__c: { required },
                ClientMailingAddressStreet__c: { required },
                ClientMailingAddressCity__c: { required },
                ClientMailingAddressState__c: { required },
                ClientMailingAddressZipCode__c: { required },
                ClientCellPhone__c: { required },
                ClientBusinessPhone__c: { required },
                ClientHomePhone__c: { required },
                ClientEmailAddress__c: { required },
                ClientMailPreference__c: {required},
                ClientEmploymentStatus__c: {required},
                ClientSSN__c: { required },
                ClientDoB__c: {required},
                ClientDriversLicense__c: {required},
                ClientDriversLicenseIssueState__c: {required},
                ClientDriversLicenseIssueDate__c: {required},
                ClientDriversLicenseExpirationDate__c: {required},
                JtCltName__c: { required },
                JtCltHomeAddressStreet__c: { required },
                JtCltHomeAddressCity__c: { required },
                JtCltHomeAddressState__c: { required },
                JtCltHomeAddressZipCode__c: { required },
                JtCltEmailAddress__c: { required },
                JtCltMailingAddressStreet__c: { required },
                JtCltMailingAddressCity__c: { required },
                JtCltMailingAddressState__c: { required },
                JtCltMailingAddressZipCode__c: { required },
                JtCltCellPhone__c: { required },
                JtCltBusinessPhone__c: { required },
                JtCltHomePhone__c: { required },
                JtCltMailPreference__c: { required },
                JtCltEmploymentStatus__c: { required },
                JtCltDoB__c: { required },
                JtCltSSN__c: { required },
                JtCltDriversLicense__c: { required },
                JtCltDriversLicenseIssueDate__c: { required },
                JtCltDriversLicenseIssueState__c: { required },
                JtCltDriversLicenseExpirationDate__c: { required }
            }
        },
    }
</script>

<style scoped>
    label {
        display: block !important;
        margin: 0 0 .28571429rem 0 !important;
        color: rgba(0,0,0,.87) !important;
        font-size: .92857143em !important;
        font-weight: 700 !important;
        text-transform: none !important;
    }
    .invalid-feedback {
        margin-left: 1px;
        color: #D63301;
        font-weight: bold;
    }
</style>
