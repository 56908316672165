import currency from "./filters/currency";
import twoDigits from "./filters/twoDigits";
import toDate from "./filters/date";
import toDatetime from "./filters/datetime";

export default {
    install(Vue) {
        Vue.filter('twoDigits', twoDigits);
        Vue.filter('date', toDate);
        Vue.filter('datetime', toDatetime);
        Vue.filter('currency', currency);
    }
}
