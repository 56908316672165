<template>
    <sui-form size="small" @submit.prevent="handleSubmit">
        <h3 is="sui-header" >Exhibit B: Investor(s) Profile </h3>
        <sui-grid v-if="user.profile.profile_type === 2">
            <sui-grid-row>
                <sui-grid-column>
                    <sui-form-field :width="4">
                        <label>Client</label>
                        <sui-dropdown
                            selection
                            floating
                            direction="downward"
                            placeholder="Client"
                            :options="clients"
                            v-model="email"
                        />
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column :width="8">
                    <sui-form-fields grouped>
                        <label>1. Plan to begin taking money from investments in:</label>
                        <div class="ip-relation-time-horizon">
                            <sui-form-field>
                                <sui-checkbox label="Under 1 year" radio value="Under 1 year" v-model="form.IP_Relationship_Time_Horizon__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="1-2 years" radio value="1-2 years" v-model="form.IP_Relationship_Time_Horizon__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="3-5 years" radio value="3-5 years" v-model="form.IP_Relationship_Time_Horizon__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="6-10 years" radio value="6-10 years" v-model="form.IP_Relationship_Time_Horizon__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="11-20 years" radio value="11-20 years" v-model="form.IP_Relationship_Time_Horizon__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Over 20 years" radio value="Over 20 years" v-model="form.IP_Relationship_Time_Horizon__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Relationship_Time_Horizon__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Relationship_Time_Horizon__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>2. After beginning to take money from investments, plan to take withdrawals over a period of:</label>
                        <div class="ip-relationship-withdrawal-period">
                            <sui-form-field>
                                <sui-checkbox label="Under 5 years" radio value="Under 5 years" v-model="form.IP_Relationship_Withdrawal_Period__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="5-10 years" radio value="5-10 years" v-model="form.IP_Relationship_Withdrawal_Period__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="10-20 years" radio value="10-20 years" v-model="form.IP_Relationship_Withdrawal_Period__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Over 20 years" radio value="Over 20 years" v-model="form.IP_Relationship_Withdrawal_Period__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Relationship_Withdrawal_Period__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Relationship_Withdrawal_Period__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>3. Current plan is to withdraw this percentage annually from the portfolio:</label>
                        <div class="ip-percentage-withdrawal">
                            <sui-form-field>
                                <sui-checkbox label="0%" radio value="0%" v-model="form.IP_Percentage_Withdrawal__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="1-4%" radio value="1-4%" v-model="form.IP_Percentage_Withdrawal__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="5-10%" radio value="5-10%" v-model="form.IP_Percentage_Withdrawal__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Over 10%" radio value="Over 10%" v-model="form.IP_Percentage_Withdrawal__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Percentage_Withdrawal__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Percentage_Withdrawal__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>4. Risk tolerance, defined as willingness to accept a potential loss for a greater long-term gain, is:</label>
                        <div class="ip-risk-tolerance">
                            <sui-form-field>
                                <sui-checkbox
                                    label="Low (Loss of 5% for even greater long-term gain)"
                                    radio value="Low (Loss of 5% for even greater long-term gain)"
                                    v-model="form.IP_Risk_Tolerance__c"
                                />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Moderate (Loss of 15% for even greater long-term gain)"
                                    radio value="Moderate (Loss of 15% for even greater long-term gain)"
                                    v-model="form.IP_Risk_Tolerance__c"
                                />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="High (Loss of 30% for even greater long-term gain)"
                                    radio value="High (Loss of 30% for even greater long-term gain)"
                                    v-model="form.IP_Risk_Tolerance__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Risk_Tolerance__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Risk_Tolerance__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>5. Current sources of income (such as salary, Social Security, pension) are expected to be:</label>
                        <div class="ip-source-of-income">
                            <sui-form-field>
                                <sui-checkbox label="Very unstable" radio value="Very unstable" v-model="form.IP_Sources_of_Income__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Unstable" radio value="Unstable" v-model="form.IP_Sources_of_Income__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Somewhat stable" radio value="Somewhat stable" v-model="form.IP_Sources_of_Income__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Stable" radio value="Stable" v-model="form.IP_Sources_of_Income__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Very stable" radio value="Very stable" v-model="form.IP_Sources_of_Income__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Sources_of_Income__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Sources_of_Income__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                </sui-grid-column>
                <sui-grid-column :width="8">
                    <sui-form-fields grouped>
                        <label>6. This investment portfolio accounts for what percent of personal net worth:</label>
                        <div class="ip-percent-of-wealth">
                            <sui-form-field>
                                <sui-checkbox label="0%-25%" radio value="0-25%" v-model="form.IP_Percent_of_Wealth__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="25%-50%" radio value="25-50%" v-model="form.IP_Percent_of_Wealth__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="50%-75%" radio value="50-75%" v-model="form.IP_Percent_of_Wealth__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="75%-100%" radio value="75-100%" v-model="form.IP_Percent_of_Wealth__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Percent_of_Wealth__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Percent_of_Wealth__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>7. Level of investment experience in stock and bond funds would be described as:</label>
                        <div class="ip-investment-experience">
                            <sui-form-field>
                                <sui-checkbox label="Inexperienced" radio value="Inexperienced" v-model="form.IP_Investment_Experience__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Somewhat experienced" radio value="Somewhat experienced" v-model="form.IP_Investment_Experience__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Experienced" radio value="Experienced" v-model="form.IP_Investment_Experience__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Very experienced" radio value="Very experienced" v-model="form.IP_Investment_Experience__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Investment_Experience__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Investment_Experience__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>8. Desire to invest primarily in socially responsible investment funds:</label>
                        <div class="ip-socially-responsible-investing">
                            <sui-form-field>
                                <sui-checkbox label="Yes" radio value="Yes" v-model="form.IP_Socially_Responsible_Investing__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="No" radio value="No" v-model="form.IP_Socially_Responsible_Investing__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Socially_Responsible_Investing__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Socially_Responsible_Investing__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                    <sui-form-fields grouped>
                        <label>9. The new investment objective and target mix of stocks versus bond investments is:</label>
                        <div class="ip-asset-allocation">
                            <sui-form-field>
                                <sui-checkbox
                                    label="Conservative (0% Stocks / 100% Bonds)"
                                    radio value="Conservative (0% Stocks / 100% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Conservative (10% Stocks / 90% Bonds)"
                                    radio value="Conservative (10% Stocks / 90% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Conservative (20% Stocks / 80% Bonds)"
                                    radio value="Conservative (20% Stocks / 80% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Conservative (30% Stocks / 70% Bonds)"
                                    radio value="Conservative (30% Stocks / 70% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Balanced (40% Stocks / 60% Bonds)"
                                    radio value="Balanced (40% Stocks / 60% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Balanced (50% Stocks / 50% Bonds)"
                                    radio value="Balanced (50% Stocks / 50% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Balanced (60% Stocks / 40% Bonds)"
                                    radio value="Balanced (60% Stocks / 40% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Growth (70% Stocks / 30% Bonds)"
                                    radio value="Growth (70% Stocks / 30% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Growth (80% Stocks / 20% Bonds)"
                                    radio value="Growth (80% Stocks / 20% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Growth (90% Stocks / 10% Bonds)"
                                    radio value="Growth (90% Stocks / 10% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox
                                    label="Growth (100% Stocks / 0% Bonds)"
                                    radio value="Growth (100% Stocks / 0% Bonds)"
                                    v-model="form.IP_Asset_Allocation__c"
                                    :class="{ 'is-invalid': submitted && $v.form.IP_Asset_Allocation__c.$error }"
                                />
                            </sui-form-field>
                            <div v-if="submitted && !$v.form.IP_Asset_Allocation__c.required" class="invalid-feedback">
                                Please select an option
                            </div>
                        </div>
                    </sui-form-fields>
                </sui-grid-column>
            </sui-grid-row>
            <sui-grid-row>
                <sui-grid-column>
                    <InvestorProfileNote :profile-type="user.profile.profile_type" />
                </sui-grid-column>
            </sui-grid-row>
            <sui-grid-row>
                <sui-grid-column>
                    <div>
                        <sui-button
                            type="button"
                            size="small"
                            content="Undo"
                            icon="undo"
                            @click="handleUndo"
                        />
                        <sui-button
                            primary
                            size="small"
                            content="Save"
                        />
                    </div>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import InvestorProfileNote from "@/components/clientForms/exhibit-b-investor-profile/InvestorProfileNote";

export default {
    name: "InvestorProfileForm",
    components: {InvestorProfileNote},
    created() {
        const loader = this.$loading.show();
        if (this.user.profile.profile_type === 2) {
            this.getAdvisorClients({ firmId: this.user.profile._firm,  advisorId: this.user.id })
                .then(() => loader.hide())
                .catch(() => loader.hide());
        } else {
            this.getInvestorProfile(this.user.email)
                .then(() => loader.hide());
        }
    },
    beforeMount() {
        if (this.user.profile.profile_type !== 2) {
            if (this.user.profile.is_exhibit_b_investor_profile_form_user && !this.hasSubmittedClientAgreement) {
                this.$router.push({name: "clientAgreement"});
            }
        }
        
        this.initializeForm();
        
        if (this.form.Investor_Profile_Status__c) {
            this.$store.commit("setIsInvestorProfileSubmitted", true);
        }
    },
    data() {
        return {
            form: {},
            submitted: false,
            email: null
        }
    },
    computed: {
        ...mapState(["hasSubmittedClientAgreement", "investorProfile", "isInvestorProfileSubmitted", "users"]),
        ...mapState({user: "userProfile"}),
        ...mapGetters(["investorProfile"]),
        clients() {
            if (this.users) {
                return this.users.map(user => {
                    return {
                        key: user.id,
                        value: user.email,
                        text: `${user.first_name} ${user.last_name}`
                    }
                });
            }
            return [];
        }
    },
    watch: {
        investorProfile: {
            deep: true,
            handler: "initializeForm"
        },
        email: function () {
            const loader = this.$loading.show();
            if (this.email) {
                this.getInvestorProfile(this.email)
                    .then(() => {
                        loader.hide();
                        if (!this.investorProfile) {
                            this.notyf.error('Investor profile not found.');
                        } else {
                            this.notyf.success('Investor profile found.');
                        }
                    })
                    .catch(() => loader.hide());
            } else {
                this.setInvestorProfile({});
            }
        }
    },
    inject: ['notyf'],
    methods: {
        ...mapActions(["getAdvisorClients", "submitInvestorProfile", "getInvestorProfile"]),
        ...mapMutations(["setInvestorProfile", "setIsInvestorProfileSubmitted"]),
        initializeForm() {
            if(this.investorProfile) {
                this.form = this.investorProfile;
            } else {
                this.form = {
                    IP_Relationship_Time_Horizon__c: null,
                    IP_Relationship_Withdrawal_Period__c: null,
                    IP_Percentage_Withdrawal__c: null,
                    IP_Risk_Tolerance__c: null,
                    IP_Sources_of_Income__c: null,
                    IP_Percent_of_Wealth__c: null,
                    IP_Investment_Experience__c: null,
                    IP_Socially_Responsible_Investing__c: null,
                    IP_Asset_Allocation__c: null
                }
            }
        },
        handleSubmit() {
            this.submitted = true;
            
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.notyf.error("Required field(s) not provided.");
                return;
            }
    
            if (this.form.Id) {
                if (this.form.Investor_Profile_Status__c !== "Submitted") {
                    this.form.Investor_Profile_Status__c = "Submitted";
                } else {
                    this.form.Investor_Profile_Status__c = "Updated";
                }
            } else {
                this.form.Investor_Profile_Status__c = "Submitted";
            }
            if (this.user.profile.profile_type === 2) {
                this.form.portalUserEmail = this.email;
            } else {
                this.form.portalUserEmail = this.user.email;
            }
            
            const loader = this.$loading.show();
            this.submitInvestorProfile(this.form)
                .then(() => {
                    this.setInvestorProfile(this.form);
                    this.setIsInvestorProfileSubmitted(true);
                    if(!this.form.Status__c && this.isInvestorProfileSubmitted) {
                        if (this.user.profile.profile_type === 4) {
                            this.$router.push({name: "formADV"});
                        }
                    }
                    loader.hide();
                    this.notyf.success('Investor profile saved.')
                });
        },
        handleUndo() {
            this.form = Object.assign({}, this.investorProfile);
        }
    },
    validations: {
        form: {
            IP_Relationship_Time_Horizon__c: { required },
            IP_Relationship_Withdrawal_Period__c: { required },
            IP_Percentage_Withdrawal__c: { required },
            IP_Risk_Tolerance__c: { required },
            IP_Sources_of_Income__c: { required },
            IP_Percent_of_Wealth__c: { required },
            IP_Investment_Experience__c: { required },
            IP_Socially_Responsible_Investing__c: { required },
            IP_Asset_Allocation__c: { required }
        }
    }
}
</script>

<style scoped>
    .ip-relation-time-horizon,
    .ip-percent-of-wealth,
    .ip-relationship-withdrawal-period,
    .ip-percentage-withdrawal,
    .ip-risk-tolerance,
    .ip-source-of-income,
    .ip-investment-experience,
    .ip-socially-responsible-investing,
    .ip-asset-allocation {
        margin-left: 2em !important;
    }
    .invalid-feedback {
        margin-left: 1px;
        color: #D63301;
        font-weight: bold;
    }
</style>
