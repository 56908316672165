<template>
    <sui-form>
        <sui-grid>
            <sui-grid-row centered>
                <sui-grid-column  centered :width="10">
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <label>First Name</label>
                            <input type="text" name="first_name" v-model="user.first_name" />
                        </sui-form-field>
                        <sui-form-field class="required field">
                            <label>Last Name</label>
                            <input type="text" name="last_name" placeholder="Last Name" v-model="user.last_name" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields equal-width>
                        <sui-form-field required>
                            <label>Email</label>
                            <input type="email" name="email" v-model="user.email" />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Mobile</label>
                            <input type="text" name="mobile" placeholder="(xxx) xxx-xxxx" v-model="user.profile.mobile_phone" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <sui-form-fields>
                                <sui-form-field width="ten">
                                    <label>Phone</label>
                                    <input type="text" name="phone" placeholder="(xxx) xxx-xxxx" v-model="user.profile.phone" />
                                </sui-form-field>
                                <sui-form-field width="six">
                                    <label>Ext.</label>
                                    <input type="text" name="phone_extension"  placeholder="Ext." v-model="user.profile.phone_extension" />
                                </sui-form-field>
                            </sui-form-fields>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Fax</label>
                            <input type="text" name="fax" placeholder="Fax" v-model="user.profile.fax"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields>
                        <sui-form-field
                            v-if="authUser.is_admin || authUser.profile.is_super_admin"
                            required
                            width="eight"
                        >
                            <label>Profile Type</label>
                            <sui-dropdown
                                name="profile-type"
                                placeholder="Profile"
                                floating
                                selection
                                search
                                :options="profileTypes"
                                v-model="user.profile.profile_type"
                            />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields>
                        <sui-form-field
                            v-if="authUser.profile.is_super_admin"
                            required
                            width="eight"
                        >
                            <label>Firm</label>
                            <sui-dropdown
                                placeholder="Firm"
                                selection
                                search
                                floating
                                :options="firms"
                                v-model="user.profile._firm"
                            />
                        </sui-form-field>
                        <sui-form-field
                            v-if="authUser.is_admin || authUser.profile.is_super_admin"
                            width="eight"
                        >
                            <label>Financial Advisor</label>
                            <sui-dropdown
                                placeholder="Financial Advisor"
                                selection
                                search
                                floating
                                :options="advisors"
                                v-model="user.advisor"
                            />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields
                        v-if="authUser.is_admin || authUser.profile.is_super_admin"
                        equal-width
                    >
                        <sui-form-field>
                            <sui-checkbox
                                name="system-admin"
                                label="System Administrator"
                                v-model="user.is_admin"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <sui-checkbox name="cco" label="CCO" v-model="user.profile.is_cco"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields
                        v-if="authUser.profile.is_super_admin"
                        equal-width
                    >
                        <sui-form-field>
                            <sui-checkbox
                                name="super-system-admin"
                                label="Super System Administrator"
                                v-model="user.profile.is_super_admin"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <sui-checkbox name="super-cco" label="Super CCO" v-model="user.profile.is_super_cco"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-divider></sui-divider>
                    <sui-form-fields grouped>
                        <label>Forms</label>
                        <div>
                            <sui-form-field style="margin-left: 1em;">
                                <sui-checkbox
                                    label="Exhibit B - Investor Profile Form (Don’t use if private assets are held.)"
                                    v-model="user.profile.is_exhibit_b_investor_profile_form_user"
                                />
                            </sui-form-field>
                        </div>
                    </sui-form-fields>
                    <sui-divider></sui-divider>
                    <sui-form-fields grouped>
                        <label>Custodians</label>
                        <sui-form-field class="custodian">
                            <sui-checkbox label="Fidelity" v-model="user.profile.is_using_fidelity" />
                        </sui-form-field>
                        <sui-form-field class="custodian">
                            <sui-checkbox label="National Advisors Trust" v-model="user.profile.is_using_national_advisors_trust" />
                        </sui-form-field>
                        <sui-form-field class="custodian">
                            <sui-checkbox label="Schwab" v-model="user.profile.is_using_schwab" />
                        </sui-form-field>
                        <sui-form-field class="custodian">
                            <sui-checkbox label="TD Ameritrade" v-model="user.profile.is_using_td_ameritrade" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-divider></sui-divider>
                    <sui-form-field>
                        <sui-checkbox
                            style="font-style: italic !important;"
                            label="Generate new password and notify user immediately"
                            v-model="notifyUser"
                        />
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "BaseUserForm",
    props: {
        modalAction: {
            type: String
        },
        toggleModal: {
            type: Function
        },
        getUsers: {
            type: Function,
        }
    },
   
    beforeMount() {
        this.initializeForm();
    },
    data() {
        return {
            user: {},
            notifyUser: false,
        }
    },
    inject: ["notyf"],
    computed: {
        ...mapState({authUser: "userProfile", userRecordData: "user" }),
        firms() {
            return this.$store.getters.firms.map(firm => {
                return {
                    "key": firm.id,
                    "value": firm.id,
                    "text": firm.name
                };
            })
        },
        profileTypes() {
            if (this.$store.getters.profileTypes) {
                return this.$store.getters.profileTypes.map(profileType => {
                    return {
                        "key": profileType.profile_type_tk,
                        "value": parseInt(profileType.profile_type_tk),
                        "text": profileType.name
                    }
                });
            }
            return [];
        },
        advisors() {
            if (this.$store.getters.advisors) {
                return this.$store.getters.advisors.map(advisor => {
                    return {
                        "key": advisor.id,
                        "value": advisor.id,
                        "text": `${advisor.first_name} ${advisor.last_name}`
                    }
                });
            }
            return [];
        },
    },
    watch: {
        userRecordData: {
            deep: true,
            handler: "initializeForm"
        },
        modalAction: {
            handler: "initializeForm"
        }
    },
    methods: {
        ...mapActions(["getFirmAdvisors",  "getProfileTypes", "createUser", "updateUser", "sendAccountActivationLink"]),
        initializeForm() {
            if (this.modalAction === "create") {
                this.user = {
                    first_name: null,
                    last_name: null,
                    email: null,
                    is_admin: null,
                    advisor: null,
                    created_by: null,
                    profile: {
                        _firm: null,
                        profile_type: null,
                        is_cco: false,
                        is_super_cco: false,
                        is_super_admin: false,
                        title: null,
                        company: null,
                        department: null,
                        phone: null,
                        phone_extension: null,
                        mobile_phone: null,
                        fax: null,
                        address_line_1: null,
                        address_line_2: null,
                        city: null,
                        state: null,
                        zip: null,
                        country: null,
                        is_exhibit_b_investor_profile_form_user: false,
                        is_using_fidelity: false,
                        is_using_national_advisors_trust: false,
                        is_using_schwab: false,
                        is_using_td_ameritrade: false
                    }
                };
                this.notifyUser = true;
            } else {
                this.user = this.userRecordData;
                this.notifyUser = false;
            }
        },
        handleSave() {
            const loader = this.$loading.show();
            if (this.modalAction === "create") {
                if (this.authUser.profile.profile_type === 2) {
                    this.user.advisor = this.authUser.id;
                    this.user.profile.profile_type = 4;
                    this.user.profile._firm = this.authUser.profile._firm;
                } else if (this.authUser.profile.profile_type === 1 && !this.authUser.profile.is_super_admin) {
                    this.user.profile._firm = this.authUser.profile._firm;
                }
                this.createUser({user: this.user, notifyUser: this.notifyUser})
                    .then(() => {
                        this.getUsers();
                        loader.hide();
                        this.toggleModal();
                        this.notyf.success("User created successfully.");
                    });
            } else if (this.modalAction === "edit") {
                if (this.notifyUser) {
                    this.sendAccountActivationLink({
                        userId: this.user.id,
                        generateNewPassword: this.notifyUser
                    });
                }
                this.updateUser(this.user)
                    .then(() => {
                        this.getUsers();
                        loader.hide();
                        this.toggleModal();
                        this.notyf.success("User updated.");
                    });
            }
        }
    },
}
</script>

<style scoped>
    .custodian {
        margin-left: 1em !important;
    }
</style>
