<template>
    <sui-container fluid class="forgot-password-form">
        <Header/>
        <forgot-password-form/>
    </sui-container>
</template>

<script>
    import Header from "./Header";
    import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm";
    export default {
        name: "PasswordReset",
        components: {
            ForgotPasswordForm,
            Header
        }
    }
</script>

<style scoped>
    .forgot-password-form {
        min-height: 100vh;
        width: 100%;
        padding-top: 52px;
        background: #f4f6f9;
    }
</style>
