<template>
    <div is="sui-header">
        <sui-container>
<!--            <h2 class="main-header">RESOURCE CENTER</h2>-->
<!--            <sui-divider class="main-header-divider"></sui-divider>-->
            <template v-if="userProfileType === profileTypes.ADMINISTRATOR || userProfileType === profileTypes.FINANCIAL_ADVISOR ||
                        userProfileType === profileTypes.STANDARD_USER">
                <div class="ui three column grid" style="margin-top: 20px; align-content: center">
                    <div class="row">
                        <div class="column">
                            <div class="ui vertical menu" v-if="userProfileType === profileTypes.ADMINISTRATOR ||
                            userProfileType === profileTypes.FINANCIAL_ADVISOR">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.users" size="mini" spaced="right"/>
                                        <span>SETUP</span>
                                    </div>
                                    <div class="menu" v-if="$store.getters.userProfile.profile.is_super_admin">
                                        <a @click="$router.push({name: 'firmManager'})" class="item">Firm Management</a>
                                    </div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'userManager'})" class="item">User Management</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header"><span>FORMS</span></div>
                                    <div class="menu">
                                        <div><a @click="$router.push({ name: 'clientAgreement' })" class="item">Client Agreement Profile</a></div>
                                        <div v-if="user.profile.profile_type === 2">
                                            <a @click="$router.push({ name: 'investorProfileView' })" class="item">Exhibit B - Investor Profile</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.calender" size="mini" spaced="right"/>
                                        <span>CALENDAR</span>
                                    </div>
                                    <div class="menu" @click="$router.push({name: 'calendar'})">
                                        <a class="item">Calendar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.compliance" size="mini" spaced="right"/>
                                        <span>COMPLIANCE</span>
                                    </div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'attestationManager'})" class="item">Quarterly Attestations</a>
                                    </div>
                                    <div class="menu" v-if="$store.getters.userProfile.profile.is_super_cco">
                                        <a @click="$router.push({name: 'supervisedPersonManager'})" class="item">Supervised Persons</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.investments" size="mini" spaced="right"/>
                                        <span>INVESTMENTS</span>
                                    </div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'cashRequestManager'})" class="item">Cash Requests</a>
                                    </div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'investmentStrategyManager'})" class="item">Investment Strategies</a>
                                    </div>
                                    <template v-if="user.profile.is_investments_user">
                                        <div class="menu">
                                            <a @click="$router.push({name: 'firmFeeScheduleManager'})" class="item">Firm Fee Schedules</a>
                                        </div>
                                        <div class="menu">
                                            <a @click="$router.push({name: 'firmModelManager'})" class="item">Firm Models</a>
                                        </div>
                                        <div class="menu">
                                            <a @click="$router.push({name: 'feeScheduleManager'})" class="item">Fee Schedule List</a>
                                        </div>
                                        <div class="menu">
                                            <a @click="$router.push({name: 'modelManager'})" class="item">Model List</a>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.links" size="mini" spaced="right"/>
                                        <span>LINKS</span>
                                    </div>
                                    <div class="menu">
                                        <a class="item" href="https://account.box.com/login" target="_blank">
                                            <span>
                                                Box <font-awesome-icon icon="external-link-alt" />
                                            </span>
                                        </a>
                                        <a class="item" @click="initialBlackDiamondSSO">
                                            <span>
                                                Black Diamond <font-awesome-icon icon="external-link-alt" />
                                            </span>
                                        </a>
                                        <a class="item" href="https://my.dimensional.com/login/" target="_blank">
                                            <span>
                                                Dimensional Fund Advisors <font-awesome-icon icon="external-link-alt" />
                                            </span>
                                        </a>
                                        <a class="item" href="https://www.finametrica.com/login.aspx" target="_blank">
                                            <span>
                                                FinaMetrica <font-awesome-icon icon="external-link-alt" />
                                            </span>
                                            
                                        </a>
                                        <a class="item" href="https://webaccess.nationaladvisorstrust.com/advisordesk/UserLogin.aspx" target="_blank">
                                            <span>
                                                National Advisors Trust Company <font-awesome-icon icon="external-link-alt" />
                                            </span>
                                        </a>
                                        <a class="item" href="https://login.salesforce.com/" target="_blank">
                                            <span>Salesforce <font-awesome-icon icon="external-link-alt" /></span>
                                        </a>
                                        <a class="item" href="https://invest.ameritrade.com/grid/p/login" target="_blank">
                                            <span>
                                                TD Ameritrade <font-awesome-icon icon="external-link-alt" />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui vertical menu" v-if="userProfileType === profileTypes.ADMINISTRATOR || userProfileType === profileTypes.FINANCIAL_ADVISOR">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.lifeImpact" size="mini" spaced="right"/>
                                        <span>LIFE IMPACT</span>
                                    </div>
                                    <div class="menu" @click="$router.push({name: 'lifeImpact'})">
                                        <a href="" class="item">Life Impact Stewardship</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.training" size="mini" spaced="right"/>
                                        <span>RESOURCES</span>
                                    </div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'complianceResourceList'})" class="item">Compliance</a>
                                        <a @click="$router.push({name: 'faqs'})" class="item">Frequently Asked Questions</a>
                                        <a @click="$router.push({name: 'investmentsResourceList'})" class="item">Investments</a>
                                        <a @click="$router.push({name: 'operationsResourceList'})" class="item">Operations</a>
                                        <a @click="$router.push({name: 'staffDirectory'})" class="item">Staff Directory</a>
                                        <a @click="$router.push({name: 'videosResourceList'})" class="item">Videos</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.support" size="mini" spaced="right"/>
                                        <span>SUPPORT</span>
                                    </div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'ticketManager'})" class="item">Help Requests</a>
                                        <a :href="provisionsGroupCommunityPortalUrl" target="_blank" class="item">
                                            Salesforce Help
                                            <font-awesome-icon icon="external-link-alt" style="margin-left: 8px"/>
                                        </a>
                                        <a @click="$router.push({name: 'ticketManager'})" class="item">Prayer Requests</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="userProfileType === profileTypes.CLIENT">
                <div class="ui three column grid" style="margin-top: 20px; align-content: center">
                    <div class="row">
                        <div class="column">
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header"><span>FORMS</span></div>
                                    <div class="menu">
                                        <a @click="$router.push({name: 'clientAgreement'})" class="item">Client Agreement Profile</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.documentVault" size="mini" spaced="right"/>
                                        <span>DOCUMENT VAULT</span>
                                    </div>
                                    <div class="menu">
                                        <a href="https://eversourcewa.account.box.com/login" target="_blank" class="item">Box</a>
                                        <a href="" class="item">Investment Policy Statement</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.financialPlanning" size="mini" spaced="right"/>
                                        <span>FINANCIAL PLANNING</span>
                                    </div>
                                    <div class="menu">
                                        <a class="item" href="https://wealth.emaplan.com/ema/SignIn" target="_blank">eMoney Advisor</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.accountManagement" size="mini" spaced="right"/>
                                        <span>ACCOUNT MANAGEMENT</span>
                                    </div>
                                    <div class="menu">
                                        <a class="item" @click="initialBlackDiamondSSO">
                                            <span>Black Diamond <font-awesome-icon icon="external-link-alt" /></span>
                                        </a>
                                        <a class="item" href="https://login.fidelity.com/ftgw/Fidelity/RtlCust/Login/Init" target="_blank"
                                           v-if="user.profile.is_using_fidelity">
                                            <span>Fidelity <font-awesome-icon icon="external-link-alt" /></span>
                                        </a>
                                        <a class="item" href="https://www.nationaladvisors.com/log-in/" target="_blank"
                                           v-if="user.profile.is_using_national_advisors_trust">
                                            <span>National Advisors Trust <font-awesome-icon icon="external-link-alt" /></span>
                                        </a>
                                        <a class="item" href="https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx" target="_blank"
                                           v-if="user.profile.is_using_schwab">
                                            <span>Schwab <font-awesome-icon icon="external-link-alt" /></span>
                                        </a>
                                        <a class="item" href="https://www.advisorclient.com/login" target="_blank"
                                           v-if="user.profile.is_using_td_ameritrade">
                                            <span>TD Ameritrade <font-awesome-icon icon="external-link-alt" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column" >
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.lifeImpact" size="mini" spaced="right"/>
                                        <span>LIFE IMPACT STEWARDSHIP</span>
                                    </div>
                                    <div class="menu" @click="$router.push({name: 'lifeImpact'})">
                                        <a href="" class="item">Life Impact Stewardship</a>
                                    </div>
                                </div>
                            </div>
                            <div class="ui vertical menu">
                                <div class="item">
                                    <div class="header">
                                        <sui-image :src="icons.calender" size="mini" spaced="right"/>
                                        <span>CALENDAR</span>
                                    </div>
                                    <div class="menu" @click="$router.push({name: 'calendar'})">
                                        <a class="item">Calendar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </sui-container>
    </div>
</template>
<script>
    import profileTypes from "../profileTypes";
    import {mapState} from "vuex";
    
    export default {
        name: "IndexPage",
        mounted() {
            this.userProfileType = this.user.profile.profile_type;
            this.profileTypes = profileTypes;
        },
        data() {
            return {
                userProfileType : "",
                profileTypes: {},
                icons : {
                    accountManagement: require("@/assets/icons/graph.png"),
                    calender: require("@/assets/icons/Calendar.png"),
                    compliance: require("@/assets/icons/Compliance.png"),
                    documentVault: require("@/assets/icons/cabinet.png"),
                    financialPlanning: require("@/assets/icons/FinancialPlanning.png"),
                    lifeImpact: require("@/assets/icons/LifeImpact.png"),
                    links: require("@/assets/icons/Links.png"),
                    training: require("@/assets/icons/Training.png"),
                    user: require("@/assets/icons/user.png"),
                    users: require("@/assets/icons/Users.png"),
                    investments: require("@/assets/icons/dollar.png"),
                    support: require("@/assets/icons/support.svg")
                },
                open: false,
                provisionsGroupCommunityPortalUrl: 'https://provisionsgroup.force.com/EverSourceWealth/s/login/'
            }
        },
        computed: {
            ...mapState({user: "userProfile"})
        },
        methods: {
            initialBlackDiamondSSO() {
                const payload = {
                    sp: "https://login.bdreporting.com"
                };
                this.$store.dispatch("ssoIntoBlackDiamond", payload)
                    .then(response => {
                        const url = URL.createObjectURL(
                            new Blob([response.data], {type:"text/html"})
                        );
                        window.open(url, "_blank");
                    });
            }
        }
    }
</script>
<style scoped>
    .main-header {
        margin: 20px 0px !important;
        text-align: center !important;
        font-weight: lighter !important;
        color: #667d88 !important;
    }
    .ui.vertical.menu {
        width: 18rem !important;
        font-weight: lighter;
        font-size: 16px !important;
        box-shadow: none !important;
    }
    .ui.vertical.menu .item .header span {
        font-size: small !important;
        color: #414141 !important;
    }
    .ui.vertical.menu .item .menu .item {
        padding-left: 57px !important;
        font-weight: lighter !important;
        color: #667d88 !important;
    }
    .ui.vertical.menu .item .menu .item:hover{
        color: dodgerblue !important;
    }
</style>
