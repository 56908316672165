<template>
     <sui-modal
         :closable="false"
         v-model="isOpen"
         class="firm-modal"
     >
         <sui-icon
             name="close"
             @click="handleCloseModal"
         />
         <sui-modal-header align="center">
             {{modalHeaderContent}}
         </sui-modal-header>
         <sui-modal-content>
             <MasterForm
                 ref="firmForm"
                 :is-new="isNew"
                 :firm="firm"
                 :toggle-modal="toggleModal"
                 :reset-is-new="resetIsNew"
             />
         </sui-modal-content>
         <sui-modal-actions>
             <sui-button
                 basic
                 size="small"
                 color="grey"
                 type="button"
                 content="Cancel"
                 @click="handleCancel"
             />
             <sui-button
                 primary
                 size="small"
                 type="button"
                 content="Save"
                 @click="handleSave"
             />
             </sui-modal-actions>
     </sui-modal>
</template>

<script>
    import MasterForm from "@/components/setup/firmManager/MasterForm";
    export default {
        name: "FirmModal",
        components: {
            MasterForm,
        },
        props: {
            isNew: {type: Boolean},
            isOpen: {type: Boolean},
            firm: {type: Object},
            toggleModal: {type: Function},
            resetIsNew: {type: Function},
        },
        computed: {
            modalHeaderContent() {
                if (this.isNew) return 'Add New Firm';
                return `Edit: ${this.firm.name}`;
            }
        },
        methods: {
            handleCloseModal() {
                this.toggleModal();
                this.resetIsNew();
            },
            handleCancel() {
                this.toggleModal();
                this.resetIsNew();
            },
            handleSave() {
                console.log(this.isNew);
                this.$refs.firmForm.save();
            }
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.25) !important;
    }
</style>
