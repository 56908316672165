<template>
    <sui-container class="investment-strategy-form">
        <h3 style="font-weight: lighter">New Investment Strategy</h3>
        <InvestmentStrategyForm  ref="form"/>
    <div>
        <sui-button
            basic
            color="grey"
            type="button"
            content="Cancel"
            @click.native="cancel"
        />
        <sui-button
            primary
            type="button"
            content="Submit"
            @click.native="save"
        />
    </div>
    </sui-container>
</template>

<script>
    import InvestmentStrategyForm from "./MasterForm";
    export default {
        name: "Submit",
        components: {
            InvestmentStrategyForm
        },
        methods: {
            cancel() {
                this.$refs.form.cancel()
            },
            save() {
                this.$refs.form.save();
            },
        }
    }
</script>

<style scoped>
    .investment-strategy-form {
        margin: 2em 0;
    }
</style>
