<template>
    <sui-container>
        <h3 class="sui-header">Form CRS</h3>
        <p>
            Read and/or download
            <a v-bind:href="documentUrl" target="_blank" @click="updateSalesforceContact">
            <strong>Form CRS</strong>
        </a>.
        </p>
    </sui-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import formUrl from "@/mixins/formUrl";

export default {
    name: "FormCRS",
    inject: ["notyf"],
    mixins: [formUrl],
    beforeMount() {
        if (!this.hasViewedOrDownloadedFormADV) {
            this.$router.push({ name: "formADV"});
        }
        this.getFormCrsUrl();
    },
    computed: mapState(["hasViewedOrDownloadedFormADV"]),
    data() {
        return {
            formUrls: [
                {name: "ESWA", firm: "44f11933f5604c038543ac0387a1de7b", url:"https://eversourcewa.app.box.com/s/519nm98fupuac1jn6eumijtu15e568ri"},
                {name: "BWWS", firm: "eaf18a4049c440c38d1e244fd9ae633e", url:"https://bmsswesson.app.box.com/s/mv65k7qol9d6s2eny1fkmzvx6c283cvy"},
                {name: "ESWA", firm: "1", url:"https://eversourcewa.app.box.com/s/519nm98fupuac1jn6eumijtu15e568ri"},
            ],
            documentUrl: null,
        }
    },
    methods: {
        updateSalesforceContact() {
            const date = moment(Date.now()).locale("en").format("YYYY-MM-DD");
            const user = this.$store.getters.userProfile;
            this.$store.dispatch("updateContactInitialFormCrsDeliveryDate", {
                portalUserId: user.id,
                deliveryDate: date
            })
                .then(() => {
                    this.$store.dispatch("getClientAgreement", user.id);
                }).catch();
        },
        getFormCrsUrl() {
            const user = this.$store.getters.userProfile;
            this.documentUrl = this.getFormUrl(user.profile._firm, this.formUrls);
        }
    },
}
</script>

<style scoped>
    a {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
