<template>
    <sui-modal :closable="false" v-model="isOpen" size="small">
        <sui-modal-header align="center">{{ title }}</sui-modal-header>
        <sui-modal-content>
            <sui-form>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Account Owner</label>
                        <input type="text"  name="account-owner" placeholder="Account Owner" v-model="personalSecurity.Account_Owner__c" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Account Type</label>
                        <input type="text" name="account-type" placeholder="Account Type" v-model="personalSecurity.Account_Type__c" />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Custodian</label>
                        <input type="text" name="custodian" placeholder="Custodian" v-model="personalSecurity.Custodian__c" />
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Account Number</label>
                        <input type="text" name="account-number" placeholder="Account Number" v-model="personalSecurity.Account_Number__c" />
                    </sui-form-field>
                </sui-form-fields>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="handleSecurityForm"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handleSecurity"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    export default {
        name: "PersonalSecurityForm",
        props: {
            isOpen: {type: Boolean},
            isNew: {type: Boolean},
            index: {type: Number},
            security: {type: Object},
            toggleIsNewPersonalSecurity: {type: Function},
        },
        data() {
            return {
                personalSecurity: this.security,
            }
        },
        computed: {
            title() {
                if (this.isNew) {
                    return 'New Personal Security'
                }
                return `Edit Personal Security: Account #${this.security.Account_Number__c}`;
            }
        },
        watch: {
            security: function () {
                this.personalSecurity = this.security;
            }
        },
        methods: {
            handleSecurityForm() {
                // If the user canceled after they intended to add a new personal security.
                if (this.isNew) this.toggleIsNewPersonalSecurity();
                this.$emit('handlePersonalSecurityForm');
            },
            handleSecurity() {
                if (this.isNew) {
                    this.$emit('addPersonalSecurity', this.personalSecurity);
                } else {
                    this.$emit('editPersonalSecurity', {
                        index: this.index,
                        data: this.security,
                    });
                }
            },
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.1) !important;
    }
</style>
