<template>
    <sui-modal :closable="false" v-model="isOpen" size="small">
        <sui-modal-header align="center">{{title}}</sui-modal-header>
        <sui-modal-content>
            <sui-form>
                <sui-form-field required>
                    <label>Outside Business Activity</label>
                    <input
                        type="text"  name="outside-business-activity" placeholder="Outside Business Activity"
                        v-model="outsideBusinessActivity.Name"
                    />
                </sui-form-field>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Approved By</label>
                        <input type="text" name="approved-by" placeholder="Approved By" v-model="outsideBusinessActivity.Approved_By__c" />
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Approved Date</label>
                        <input type="text" name="approved-date" placeholder="MM/DD/YYYYY" v-model="outsideBusinessActivity.Approved_Date__c" />
                    </sui-form-field>
                </sui-form-fields>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="handleOutsideBusinessActivityForm"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handleOutsideBusinessActivity"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    export default {
        name: "OutsideBusinessActivityForm",
        props: {
            isOpen: {type: Boolean},
            isNew: {type: Boolean},
            index: {type: Number},
            businessActivity: {type: Object},
            toggleIsNewOutsideBusinessActivity: {type: Function},
        },
        data() {
            return {
                outsideBusinessActivity: this.businessActivity,
            }
        },
        computed: {
            title() {
                if (this.isNew) {
                    return 'New Outside Business Activity'
                }
                return `Edit Outside Business Activity: ${this.businessActivity.Name}`;
            }
        },
        watch: {
            businessActivity: function () {
                this.outsideBusinessActivity = this.businessActivity;
            }
        },
        methods: {
            handleOutsideBusinessActivityForm() {
                // If user canceled after they had intended to add a new outside business activity.
                if (this.isNew) this.toggleIsNewOutsideBusinessActivity();
                this.$emit('handleOutsideBusinessActivityForm');
            },
            handleOutsideBusinessActivity() {
                if (this.isNew) {
                    this.$emit('addOutsideBusinessActivity', this.outsideBusinessActivity);
                } else {
                    this.$emit('editOutsideBusinessActivity', {
                        index: this.index,
                        data: this.outsideBusinessActivity,
                    });
                }
            },
        }
    }
</script>

<style scoped>
.ui.dimmer {
    background-color: rgba(0,0,0,.1) !important;
}
</style>
