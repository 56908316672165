const rootAPI = process.env.VUE_APP_ROOT_API_URL;

const endpoints = {
    baseURL: rootAPI,
    reCAPTCHA: {
        verify: rootAPI + "/api/verify_captcha/",
    },
    JWT: {
        obtainToken: rootAPI + "/api/token/",
        refreshToken: rootAPI + "api/token/refresh/",
        verifyToken: rootAPI + "/api/token/verify/",
    },
    auth: {
        confirmAccount: rootAPI + "/api/auth/account/confirm/",
        login: rootAPI + "/api/auth/login/",
        logout: rootAPI + "/api/auth/logout/",
        forgotPassword: rootAPI + "/api/auth/password/reset/",
        resetPassword: rootAPI + "/api/auth/password/reset/confirm/",
        changePassword: rootAPI + "/api/auth/password/change/"
    },
    sso: "/api/sso/init/",
    settings: {
        email: "/api/account_settings/change_email/",
        password: "/api/account_settings/change_password/",
        profile: "/api/account_settings/update_personal_info/"
    },
    accountActivationLink: "/api/users/send_account_activation_link/",
    cashRequests: "/api/cash_requests/",
    feeSchedules: "/api/fee_schedules/",
    firms: "/api/firms/",
    investmentModels: "/api/investment_models/",
    investmentStrategies: "/api/investment_strategies/",
    profileTypes: "/api/profile_types/",
    personalSecurities: "/api/personal_securities/",
    outsideBusinessActivities: "/api/outside_business_activities/",
    politicalContributions: "/api/political_contributions/",
    quarterlyAttestations: "/api/quarterly_attestations/",
    quarterlyAttestationsSubmissionStatus: "/api/quarterly_attestation_submission_status/",
    roles: "/api/roles/",
    states: "/api/states/",
    supervisedPersons: '/api/supervised_persons/',
    supportTickets: "/api/support_tickets/",
    users: "/api/users/",
    userRoles: "/api/user_roles/",
    clientAgreements: "/api/client_agreements/",
    contacts: {
        updateFormAdvDeliveryDate: "/api/update_form_adv_delivery_date/",
        updateFormCrsDeliveryDate: "/api/update_form_crs_delivery_date/",
    },
    sfAccount: "api/accounts/",
    firmFeeSchedules: "api/firm_fee_schedules/",
    firmModels: "api/firm_models/",
    investorProfile: "api/investor_profile/",
    employees: "api/employees/"
};

export default endpoints;
