<template>
    <div style="margin: 1.5em;">
        <sui-card class="centered activate-acct-card">
            <sui-card-content>
                <div class="center aligned header" style="margin: 2em;">Activate your account.</div>
                <sui-message class="activate-account-message" v-if="isAccountActivated===false">
                    Please set the password for your account.
                </sui-message>
                <sui-message class="account-activated-message" v-if="isAccountActivated">
                    Your account was activated successfully!
                </sui-message>
                <template>
                    <sui-form class="activate-account-form" v-if="isAccountActivated===false">
                        <sui-form-field>
                            <label>New Password</label>
                            <sui-input
                                    type="password"
                                    placeholder="Enter new password"
                                    autocomplete="off"
                                    v-model="password"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Confirm New Password</label>
                            <sui-input
                                    type="password"
                                    placeholder="Confirm new password"
                                    autocomplete="off"
                                    v-model="passwordConfirmation"
                            />
                        </sui-form-field>
                        <sui-button
                                v-if="isAccountActivated === false"
                                content="Activate Account"
                                type="button"
                                fluid
                                color="teal"
                                @click="submit"
                        >
                        </sui-button>
                    </sui-form>
                </template>
                <sui-divider hidden />
                <div class="login" v-if="isAccountActivated">
                    <router-link to="/account/login" class="login-link">
                        Log In
                    </router-link>
                </div>
            </sui-card-content>
        </sui-card>
        <Loader v-if="$store.getters.isRequesting"></Loader>
    </div>
</template>
<script>
    import Loader from "../common/Loader";
    export default {
        name: "AccountActivationForm",
        components: {
            Loader
        },
        data() {
            return {
                isAccountActivated: false,
                password: "",
                passwordConfirmation: "",
                uid: this.$route.params.uid,
                token: this.$route.params.token,
            }
        },
        methods: {
            submit() {
                this.$store.commit("setIsRequesting", true);
                this.$store.dispatch("confirmAccount", {
                    uid: this.uid,
                    token: this.token,
                    password: this.password,
                });
                this.isAccountActivated = true;
            }
        }
    }
</script>

<style scoped>
    .activate-acct-card {
        margin-top: 1em;
        width: 340px !important;
    }
    .activate-account-form {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
    .activate-account-message,.account-activated-message {
        background-color: #ffe3e3 !important;
        border: 1px solid #fcbdbd !important;
        color: #921515 !important;
        border: 1px solid rgba(37,40,43,0.1) !important;
        border-radius: 3px !important;
        box-shadow: 0 1px 2px rgba(37,40,43,0.1) !important;
        -webkit-transition: 0.1s !important;
        transition: 0.1s !important;
    }
    .activate-account-message{
    }
    .account-activated-message {
    }
    .login {
        text-align: center;
    }
    .login-link {
        color: #2a998b;
    }
</style>
