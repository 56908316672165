<template>
    <div class="main-container">
        <div class="manager-header">
            <sui-container>
                <div class="manager">
                    <h3 is="sui-header" style="font-weight: lighter">Firm Fee Schedules</h3>
                    <div class="search-filter">
                        <sui-dropdown
                            class="firm-dropdown-menu"
                            placeholder="Firm"
                            text="Firm"
                            selection
                            :options="firmOptions"
                            v-model="firm"
                        />
                        <sui-input
                            placeholder="Search"
                            icon="search"
                            icon-position="left"
                            v-model="searchPhrase"
                        />
                    </div>
                    <div class="actions">
                        <sui-button
                            basic
                            size="tiny"
                            color="grey"
                            content="New Firm Fee Schedule"
                            @click="handleAdd"
                        />
                    </div>
                </div>
            </sui-container>
        </div>
        <sui-container class="model-list">
            <FirmFeeScheduleList
                :firmFeeSchedules="firmFeeScheduleList"
                @handleEdit="handleEdit"
                @showDeleteWarning="showDeleteWarning"
            />
            <Modal
                :open="isOpen"
                :cancel="toggleModal"
                :mode="modalMode"
                :feeSchedules="feeScheduleOptions"
                @add="addFeeSchedule"
                @edit="editFeeSchedule"
            />
            <DeleteWarning ref="deleteWarningModal" @handleDelete="deleteModel" />
        </sui-container>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import FirmFeeScheduleList from "./FeeScheduleList";
import Modal from "./Form";
import DeleteWarning from "@/components/common/DeleteWarningModal";

export default {
    name: "Index",
    components: {
        DeleteWarning,
        Modal,
        FirmFeeScheduleList
    },
    created() {
        const loader = this.$loading.show();
        Promise.all([
            this.getAllFirmFeeSchedules(),
            this.getAllFeeSchedules(),
            this.getAllFirms()
        ])
            .finally(() => {
                loader.hide();
            });
    },
    data() {
        return {
            firm: null,
            isOpen: false,
            modalMode: null,
            searchPhrase: null
        }
    },
    inject: ['notyf'],
    computed: {
        ...mapState({ user: "userProfile" }),
        ...mapState(["feeScheduleList"]),
        ...mapGetters(["firms", "firmFeeSchedules"]),
        firmOptions() {
            if (this.firms) {
                let firms = this.firms.map(firm => {
                    return { key: firm.id, value: firm.id, text: firm.name };
                });
                firms.unshift({ key: "", value: "", text: "All" });
                return firms;
            }
            return [];
        },
        feeScheduleOptions() {
            if (this.feeScheduleList) {
                return this.feeScheduleList.map(feeSchedule => {
                    return {key: feeSchedule.id, value: feeSchedule.id, text: feeSchedule.name};
                });
            }
            return [];
        },
        firmFeeScheduleList() {
            if (this.firmFeeSchedules) {
                if (this.searchPhrase) {
                    return this.firmFeeSchedules.filter(item => {
                        if (item.fee_schedule_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                            item.firm_name.toLowerCase().includes(this.searchPhrase.toLowerCase())
                        ) return item;
                    });
                } else {
                    return this.firmFeeSchedules;
                }
            }
            return [];
        }
    },
    watch: {
        firm: {
            handler: "getFeeSchedules"
        }
    },
    methods: {
        ...mapActions([
            "getAllFirms", "getAllFeeSchedules", "getAllFirmFeeSchedules", "getFirmsFeeSchedules",
            "getFirmFeeSchedule", "createFirmFeeSchedule", "updateFirmFeeSchedule", "deleteFirmFeeSchedule"
        ]),
        toggleModal() {
            this.isOpen = !this.isOpen;
        },
        handleAdd() {
            this.modalMode = "create";
            this.toggleModal();
        },
        handleEdit(firmFeeScheduleId) {
            this.modalMode = "update";
            const loader = this.$loading.show();
            this.getFirmFeeSchedule(firmFeeScheduleId)
                .then(() => {
                    loader.hide();
                    this.toggleModal();
                }).catch();
        },
        getFeeSchedules() {
            const loader = this.$loading.show();
            if (this.firm) {
                this.getFirmsFeeSchedules(this.firm)
                    .then(() => {
                        loader.hide();
                    }).catch()
            } else {
                this.getAllFirmFeeSchedules()
                    .then(() => {
                        loader.hide();
                    }).catch()
            }
        },
        addFeeSchedule(feeSchedule) {
            const loader = this.$loading.show();
            this.createFirmFeeSchedule(feeSchedule)
                .then(() => {
                    this.getAllFirmFeeSchedules();
                })
                .then(() => {
                    loader.hide();
                    this.toggleModal();
                    this.notyf.success("Fee schedule saved");
                })
                .catch(() => {
                    this.toggleModal();
                })
        },
        editFeeSchedule(feeSchedule) {
            const loader = this.$loading.show();
            this.updateFirmFeeSchedule(feeSchedule)
                .then(() => {
                    this.getAllFirmFeeSchedules();
                })
                .then(() => {
                    loader.hide();
                    this.toggleModal();
                    this.notyf.success("Fee schedule updated");
                })
                .catch(() => {
                    this.toggleModal();
                });
        },
        deleteModel(firmFeeScheduleId) {
            const loader = this.$loading.show();
            this.deleteFirmFeeSchedule(firmFeeScheduleId)
                .then(() => {
                    this.getAllFirmFeeSchedules();
                })
                .then(() => {
                    loader.hide();
                    this.notyf.success("Fee schedule deleted");
                })
                .catch(() => loader.hide());
            this.$refs.deleteWarningModal.toggleIsOpen();
        },
        showDeleteWarning(feeSchedule) {
            this.$refs.deleteWarningModal.toggleIsOpen(feeSchedule.id, feeSchedule.fee_schedule_name);
        },
    }
}
</script>

<style scoped>
.manager {
    display: flex;
    justify-content: space-between;
    margin-top: 1em !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 10px !important;
}
.firm-dropdown-menu {
    margin-right: 2px !important;
}
</style>
