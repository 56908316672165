<template>
    <div class="main-container">
        <div>
            <sui-container fluid>
                <sui-grid>
                    <sui-grid-row>
                        <sui-grid-column>
                            <sui-grid-column>
                                <h2 class="ui  floated header">Cases</h2>
                            </sui-grid-column>
                            <sui-button
                                basic
                                floated="right"
                                size="small"
                                content="New"
                                color="grey"
                                @click="handleNewTicket"
                            />
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>
            </sui-container>
        </div>
        <div>
            <sui-container>
                <TicketListView
                    :ticketList="tickets"
                    @getTicket="getTicket"
                />
            </sui-container>
        </div>
        <TicketModal
            :open="isOpen"
            :isNew="isNew"
            :ticket="ticket"
            :toggleModal="toggleModal"
            :modalHeaderText="modalHeaderText"
        />
    </div>
</template>

<script>
    import TicketListView from "@/components/ticketManager/TicketListView";
    import TicketModal from "@/components/ticketManager/TicketModal";
    export default {
        name: "Index",
        components: {
            TicketListView,
            TicketModal,
        },
        beforeCreate() {
            const loader = this.$loading.show();
            this.$store.dispatch('getSupportTicketList')
                .then(() => {
                    loader.hide();
                });
        },
        data() {
            return {
                ticket: null,
                isOpen: false,
                isNew: false,
            }
        },
        computed: {
            tickets() {
                 return this.$store.getters.supportTicketList;
            },
        },
        methods: {
            toggleModal() {
                this.isOpen = !this.isOpen;
            },
            modalHeaderText() {
                if (this.isNew) return 'New Case';
                if (this.ticket) return this.ticket.Subject;
            },
            handleNewTicket() {
                this.isNew = true;
                this.ticket = {
                    Category__c: null,
                    Description: null,
                    Origin: 'Web',
                    Priority: null,
                    Reason: null,
                    Status: null,
                    Subject: null,
                    Type: null,
                }
                this.toggleModal();
            },
            getTicket(ticketId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getSupportTicket', ticketId)
                    .then(() => {
                        this.ticket = this.$store.getters.supportTicket;
                        this.isNew = false;
                        this.toggleModal();
                        loader.hide();
                    })
            }
        }
    }
</script>

<style scoped>
    .main-container {
         margin: .5em 2em 2em 2em;
    }
</style>
