<template>
    <div>
        <sui-table selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                        {{ column }}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="firmFeeSchedules">
                <sui-table-row v-for="(feeSchedule, index) in firmFeeSchedules" :key="feeSchedule.id">
                    <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>
                        <div class="model-name" @click="edit(feeSchedule.id)">
                            {{ feeSchedule.fee_schedule_name }}
                        </div>
                    </sui-table-cell>
                    <sui-table-cell>{{ feeSchedule.firm_name }}</sui-table-cell>
                    <sui-table-cell>{{ toDatetime(feeSchedule.created_date) }}</sui-table-cell>
                    <sui-table-cell>{{ toDatetime(feeSchedule.last_modified_date) }}</sui-table-cell>
                    <sui-table-cell collapsing>
                        <div class="table-row-menu">
                            <div class="action-icons">
                                <sui-icon
                                    class="edit-icon"
                                    name="edit"
                                    @click="edit(feeSchedule.id)"/>
                                <sui-icon
                                    class="trash-icon"
                                    name="trash"
                                    @click="remove(feeSchedule)"/>
                            </div>
                        </div>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
            <div v-else>
                <p>Not firm models found.</p>
            </div>
        </sui-table>
        <NoItemsFoundListMessage v-if="firmFeeSchedules.length === 0 " />
    </div>
</template>

<script>
import dateFormatter from "@/mixins/dateFormatter";
import NoItemsFoundListMessage from "@/components/common/NoItemsFoundListMessage";

export default {
    name: "FirmFeeScheduleList",
    components: { NoItemsFoundListMessage },
    props: {
        firmFeeSchedules: {
            required: true,
            type: Array
        },
    },
    data() {
        return {
            columns: [
                'Fee Schedule',
                'Firm',
                'Created Date',
                'Last Modified Date',
            ]
        }
    },
    mixins: [dateFormatter],
    methods: {
        edit(firmFeeScheduleId) {
            
            this.$emit("handleEdit", firmFeeScheduleId);
        },
        remove(firmFeeSchedule) {
            this.$emit("showDeleteWarning", firmFeeSchedule);
        },
    }
}
</script>

<style scoped>
.model-name {
    cursor: pointer !important;
    color: #2185d0 !important;
}
.model-name:hover {
    text-decoration: underline !important;
}
.table-row-menu {
    display: flex !important;
    justify-content: space-between !important;
}
.edit-icon, .trash-icon {
    cursor: pointer !important;
    color: #8C979A !important;
}
.trash-icon:hover {
    color: #FF6600!important;
}
</style>
