<template>
    <sui-container style="margin: 1em">
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column>
                    <sui-grid-column :width="6">
                        <h3 class="ui  floated header" style="font-weight: lighter">Cash Requests</h3>
                    </sui-grid-column>
                    <sui-button
                            floated="right"
                            basic
                            size="small"
                            content="New Cash Request"
                            color="grey"
                            @click="displayCashRequestForm"
                    />
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
        <sui-table single-line selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in tableColumns" :key=index>
                        {{ column}}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="cashRequests">
                <sui-table-row
                        v-for="(cashRequest, index) in cashRequests" :key="index">
                    <sui-table-cell>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>{{ cashRequest.Cash_Request_Type__c }}</sui-table-cell>
                    <sui-table-cell>
                        <money-format
                            :value="cashRequest.Amount__c"
                            :locale='en'
                            :currency-code='USD'
                            :subunits-value=false
                            :hide-subunits=false>
                        </money-format>
                    </sui-table-cell>
                    <sui-table-cell>{{ cashRequest.Financial_Account_Name__c }}</sui-table-cell>
                    <sui-table-cell>{{ cashRequest.Custodian_Account_Number__c }}</sui-table-cell>
                    <sui-table-cell>{{ cashRequest.Custodian__c }}</sui-table-cell>
                    <sui-table-cell>{{ cashRequest.Relationship_Name__c }}</sui-table-cell>
                    <sui-table-cell>{{ cashRequest.Status__c }}</sui-table-cell>
                    <sui-table-cell>{{ toDate(cashRequest.CreatedDate) }}</sui-table-cell>
                    <sui-table-cell collapsing>
                        <sui-dropdown simple>
                            <sui-dropdown-menu>
                                <sui-dropdown-item @click="handleView(cashRequest.Id)">View</sui-dropdown-item>
                                <sui-dropdown-item @click="handleEdit(cashRequest.Id)">Edit</sui-dropdown-item>
                            </sui-dropdown-menu>
                        </sui-dropdown>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
        <Modal v-if="cashRequest"
               :open="open"
               :cashRequest="cashRequest"
               :toggle="toggleModal"
               @toggle-modal="toggleModal"
        />
    </sui-container>
</template>

<script>
    import MoneyFormat from 'vue-money-format';
    import dateFormatter from "@/mixins/dateFormatter";
    import Modal from './Modal';
    
    export default {
        name: "Index",
        components: {Modal, MoneyFormat},
        created() {
            const loader =  this.$loading.show();
            const user = this.$store.getters.userProfile;
            this.$store.dispatch('getClientCashRequestList', user.id)
                .then( () => {
                    this.cashRequestList = this.$store.getters.cashRequestList;
                    loader.hide();
                });
        },
        data() {
            return {
                tableColumns: [
                    'Type',
                    'Amount',
                    'Account Name',
                    'Account Number',
                    'Custodian',
                    'Relationship Name',
                    'Status',
                    'Submitted Date'
                ],
                cashRequestList: null,
                cashRequest: null,
                open: false,
            }
        },
        computed: {
            cashRequests() {
                return this.$store.getters.cashRequestList;
            },
        },
        mixins: [dateFormatter],
        methods: {
            toggleModal() {
                this.open = !this.open;
            },
            displayCashRequestForm() {
                this.$router.push({name: 'cashRequestForm'})
            },
            handleView(cashRequestId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getCashRequest', cashRequestId)
                    .then( () => {
                        this.cashRequest = this.$store.getters.cashRequest;
                        loader.hide();
                    }).catch();
                this.open = !this.open;
                this.cashRequest = null;
            },
            handleEdit(cashRequestId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getCashRequest', cashRequestId)
                    .then( () => {
                        this.cashRequest = this.$store.getters.cashRequest;
                        loader.hide();
                    }).catch();
                this.open = !this.open;
                this.cashRequest = null;
            },
        }
    }
</script>

<style scoped>

</style>
