<template>
    <sui-list class="sidebar-resource-item-list">
        <div class="resource-item">
            <a><router-link :to="{name: 'complianceResourceList'}">Compliance</router-link></a>
        </div>
        <div class="resource-item">
            <a><router-link :to="{name: 'investmentsResourceList'}">Investments</router-link></a>
        </div>
        <div class="resource-item">
            <a><router-link :to="{name: 'operationsResourceList'}">Operations</router-link></a>
        </div>
        <div class="resource-item">
            <a><router-link :to="{name: 'videosResourceList'}">Videos</router-link></a>
        </div>
        <div class="resource-item">
            <a><router-link :to="{name: 'faqs'}">FAQs</router-link></a>
        </div>
    </sui-list>
</template>

<script>
    export default {
        name: "SideMenu",
        props: {
            resourceItems: {
                type: Array,
            }
        }
    }
</script>

<style scoped>
    .sidebar-resource-item-list {
        color: #8C979A !important;
    }
    .resource-item{
        margin-bottom: 15px;
        margin-left: 15px;
        font-weight: bold;
        color: #8C979A !important;
    }
    .resource-item *:hover {
        cursor: pointer;
    }
    a {
        color: teal;
    }
</style>
