<template>
    <sui-modal :closable="false" v-model="isOpen" size="small">
        <sui-modal-header align="center">{{title}}</sui-modal-header>
        <sui-modal-content>
            <sui-form>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Amount</label>
                        <currency-input name="amount" placeholder="Amount" v-model="contribution.Amount__c"/>
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Candidate Name</label>
                        <input type="text" name="candidate-name" placeholder="Candidate Name " v-model="contribution.Candidate_Name__c" />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Political Race</label>
                        <input type="text" name="political-race" placeholder="Political Race" v-model="contribution.Political_Race__c" />
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Election Date</label>
                        <input type="text" name="election-date" placeholder="MM/DD/YYYYY" v-model="contribution.Election_Date__c" />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields equal-width>
                    <sui-form-field required>
                        <label>Approved By</label>
                        <input type="text" name="approved-by" placeholder="Approved By" v-model="contribution.Approved_By__c" />
                    </sui-form-field>
                    <sui-form-field required>
                        <label>Approved Date</label>
                        <input type="text" name="approved-date" placeholder="MM/DD/YYYYY" v-model="contribution.Approved_Date__c" />
                    </sui-form-field>
                </sui-form-fields>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="handlePoliticalContributionForm"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handlePoliticalContribution"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    export default {
        name: "PoliticalContributionForm",
        props: {
            isOpen: {type: Boolean},
            isNew: {type: Boolean},
            index: {type: Number},
            politicalContribution: {type: Object},
            toggleIsNewPoliticalContribution: {type: Function},
        },
        data() {
            return {
                contribution: this.politicalContribution,
            }
        },
        computed: {
            title() {
                if (this.isNew) {
                    return 'New Political Contribution'
                } else {
                    return `Edit Political Contribution: ${this.politicalContribution.Candidate_Name__c}`;
                }
                
            }
        },
        watch: {
            politicalContribution: function () {
                this.contribution = this.politicalContribution;
            }
        },
        methods: {
            handlePoliticalContributionForm() {
                // If user canceled after they had intended to add a new political contribution.
                if (this.isNew) this.toggleIsNewPoliticalContribution();
                this.$emit('handlePoliticalContributionForm');
            },
            handlePoliticalContribution() {
                if (this.isNew) {
                    this.$emit('addPoliticalContribution', this.contribution);
                } else {
                    this.$emit('editPoliticalContribution', {
                        index: this.index,
                        data: this.contribution,
                    });
                }
            },
        }
    }
</script>

<style scoped>
.ui.dimmer {
    background-color: rgba(0,0,0,.1) !important;
}
</style>
