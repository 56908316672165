<template>
    <sui-modal
        size="large"
        :closable="false"
        v-model="isOpen"
    >
        <sui-icon name="close" @click="toggleModal" style="color: black" />
        <sui-modal-header style="text-align: center">
            {{modalHeaderContent()}}
        </sui-modal-header>
        <sui-modal-content scrolling>
            <BaseUserForm
                ref="userForm"
                :modal-action="modalAction"
                :toggle-modal="toggleModal"
                :getUsers="getUsers"
            />
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="handleCancel"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handleSave"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>

import { mapGetters } from "vuex";
import BaseUserForm from "@/components/userManger/BaseUserForm.vue";
export default {
    name: "UserModal",
    components: {
        BaseUserForm
    },
    props: {
        isOpen: {
            type: Boolean
        },
        modalAction: {
            type: String
        },
        toggleModal: {
            type: Function
        },
        getUsers: {
            type: Function
        }
    },
    computed: {
        ...mapGetters(["user"])
    },
    methods: {
        modalHeaderContent() {
            let content;
            if (this.modalAction === "create") {
                content =  "New User";
            } else {
                const user = JSON.parse(JSON.stringify(this.user));
                content = `${user.first_name} ${user.last_name}`;
            }
            return content;
        },
        handleCancel() {
            this.toggleModal();
        },
        handleSave() {
            this.$refs.userForm.handleSave();
        }
    }
}
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.06) !important;
    }
</style>
