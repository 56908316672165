<template>
  <div class="index">
    <index-page/>
  </div>
</template>

<script>
import IndexPage from "../components/Home.vue";

export default {
  name: 'Home',
  components: {
    IndexPage
  }
}
</script>
