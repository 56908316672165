<template>
    <sui-card class="centered  card">
        <sui-card-content>
            <div class="center aligned header" style="margin: 1.5em;">Reset password</div>
            <sui-form class="forgot-password-form" v-if="isEmailSent === false">
                <sui-form-field>
                    <label>Email address</label>
                    <sui-input
                            type="email"
                            placeholder="Enter email address"
                            v-model.trim="email"
                    />
                </sui-form-field>
                <sui-button
                        content="Send recovery link"
                        type="button"
                        fluid color="teal"
                        @click="forgotPassword"
                >
                </sui-button>
                <sui-divider horizontal>OR</sui-divider>
                <div class="center aligned">
                    <router-link to="/account/login" style="color: #2a998b;">Return to LogIn</router-link>
                </div>
            </sui-form>
            <sui-message info class="email-sent-msg" v-if="isEmailSent" >
                Check your inbox for the next steps. If you don't receive an email, and it's not in
                your spam folder this could mean you signed up with a different address.
            </sui-message>
        </sui-card-content>
    </sui-card>
</template>

<script>
    export default {
        name: "PasswordResetForm",
        data() {
            return {
                isEmailSent: false,
                email: ""
            }
        },
        methods: {
            forgotPassword() {
                this.$store.commit("setIsRequesting", true);
                this.$store.dispatch("forgotPassword", {"email" : this.email});
                this.isEmailSent  = true;
            }
        }
    }
</script>

<style scoped>
    .card {
        width: 340px !important;
        padding-bottom: 10px;
    }
    .forgot-password-form {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
    .email-sent-msg {
        margin-bottom: 20px !important;
    }
</style>
