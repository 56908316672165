<template>
    <div>
        <transition name="fade">
            <sui-message
                v-if="visible"
                positive
                header="You completed and viewed all forms."
                size="small"
                dismissable
                @dismiss="handleDismiss"
            />
        </transition>
    </div>
</template>

<script>
export default {
    name: "CompletedStepsMessage",
    props: {
        hasCompletedSteps: {
            type: Boolean,
        }
    },
    data() {
        return {
             visible: this.hasCompletedSteps
        }
    },
    methods: {
        handleDismiss() {
            this.visible = false;
        }
    }
}
</script>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
<style scoped>

</style>
