<template>
    <div>
        <div v-if="resourceList">
            <MasterResourceList :resource-list="resourceList" />
        </div>
        <div v-if="resourceList.length === 0">
            <NoResourceFoundMessage  :resource-name="resourceName"/>
        </div>
    </div>
</template>

<script>
    import MasterResourceList from "@/components/resourceManager/MasterResourceList";
    import NoResourceFoundMessage from "@/components/resourceManager/NoResourceFoundMessage";
    export default {
        name: "ComplianceResourceList",
        components: {
            MasterResourceList,
            NoResourceFoundMessage,
        },
        data() {
            return {
                resourceName: 'Compliance',
                resourceList: [
                    {
                        'name': 'ESWA Code of Ethics',
                        'assetUrl': 'https://res.cloudinary.com/eswa/image/upload/v1605864590/compliance/ESWA/ESWA_Code_of_Ethics_2018.12.12_v0ahbn.pdf',
                        'addedDate': '11/19/2020',
                        'lastModifiedDate': '11/19/2020',
                    },
                    {
                        'name': 'ESWA Compliance Manual',
                        'assetUrl': 'https://res.cloudinary.com/eswa/image/upload/v1605864589/compliance/ESWA/ESWA_Compliance_Manual_2020.04.09_urlq7o.pdf',
                        'addedDate': '11/19/2020',
                        'lastModifiedDate': '11/19/2020',
                    },
                ],
            }
        }
    }
</script>

<style scoped>

</style>
