<template>
    <div>
        <div v-if="resourceList">
            <MasterResourceList :resource-list="resourceList" />
        </div>
        <div v-if="resourceList.length === 0">
            <NoResourceFoundMessage :resource-name="resourceName" />
        </div>
        
    </div>
</template>

<script>
    import MasterResourceList from "@/components/resourceManager/MasterResourceList";
    import NoResourceFoundMessage from "@/components/resourceManager/NoResourceFoundMessage";
    export default {
        name: "OperationsResourceList",
        components: {
            MasterResourceList,
            NoResourceFoundMessage,
        },
        data() {
            return {
                resourceName: 'Operations',
                resourceList: [
                    {
                        'name': 'ESWA Advisors Operations Manual',
                        'assetUrl': 'https://res.cloudinary.com/eswa/image/upload/v1605861169/operations/eswa-advisors-operations-manual_fxi1jr.pdf',
                        'addedDate': '18/11/2020',
                        'lastModifiedDate': '18/11/2020',
                    },
                    {
                        'name': 'ESWA Home Office Operations Manual',
                        'assetUrl': 'https://res.cloudinary.com/eswa/image/upload/v1605861171/operations/eswa-home-office-operations-manual_mfmzse.pdf',
                        'addedDate': '18/11/2020',
                        'lastModifiedDate': '18/11/2020',
                    }
                ],
            }
        }
    }
</script>

<style scoped>

</style>
