<template>
    <sui-table selectable class="model-virtual-list">
        <sui-table-header>
            <sui-table-row>
                <sui-table-header-cell></sui-table-header-cell>
                <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                    {{ column }}
                </sui-table-header-cell>
                <sui-table-header-cell></sui-table-header-cell>
            </sui-table-row>
        </sui-table-header>
        <sui-table-body v-if="models">
            <sui-table-row
                    v-for="(model, index) in models" :key="model.id">
                <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                <sui-table-cell>{{ model.name }}</sui-table-cell>
                <sui-table-cell>{{ toDatetime(model.created_date) }}</sui-table-cell>
                <sui-table-cell>{{ toDatetime(model.last_modified_date) }}</sui-table-cell>
                <sui-table-cell collapsing>
                    <div class="model-menu">
                        <font-awesome-icon icon="eye" class="model-menu-icon eye" @click="viewModel(model.id)"/>
                        <font-awesome-icon icon="pencil-alt" class="model-menu-icon pencil-alt" @click="editModel(model.id)"/>
                        <font-awesome-icon icon="trash-alt" class="model-menu-icon trash" @click="deleteModel(model)"/>
                    </div>
                </sui-table-cell>
            </sui-table-row>
        </sui-table-body>
        <div v-else>
            <p>Not models found.</p>
        </div>
    </sui-table>
</template>

<script>
    import dateFormatter from "@/mixins/dateFormatter";

    export default {
        name: "ModelVirtualList",
        props: {
            models: {type: Array},
        },
        data() {
            return {
                columns: [
                    'Model Name',
                    'Created Date',
                    'Last Modified Date'
                ]
            }
        },
        mixins: [dateFormatter],
        methods: {
            viewModel(modelId) {
                this.$emit('handleViewModel', modelId);
            },
            editModel(modelId) {
                this.$emit('handleEditModel', modelId);
            },
            deleteModel(model) {
                this.$emit('showDeleteWarning', model);
            }
        }
    }
</script>

<style scoped>
    .model-virtual-list {
        margin: 1em 1em !important;
    }
    .model-menu {
        display: flex;
        color: #8C979A;
    }
    .model-menu-icon {
        margin: 0 10px;
        cursor: pointer;
    }
    .model-menu .eye:hover{
        color: #555F61;
    }
    .model-menu .pencil-alt:hover{
        color: #555F61;
    }
    .model-menu .trash:hover{
        color: #ff4d4d;
    }
</style>
