<template>
    <sui-form size="small" v-if="user">
        <sui-grid>
            <sui-grid-row centered>
                <sui-grid-column centered :width="8">
                    <h2 is="sui-header" class="profile-info-header">Personal info</h2>
                    <sui-form-fields>
                        <sui-form-field>
                            <label>First Name</label>
                            <sui-form-fields>
                                <sui-form-field>
                                    <sui-dropdown
                                        placeholder="Salutation"
                                        selection
                                        search
                                        floating
                                        :options="salutations"
                                        v-model="user.salutation"
                                    />
                                </sui-form-field>
                                <sui-form-field>
                                    <input type="text" placeholder="First Name" v-model="user.first_name" />
                                </sui-form-field>
                            </sui-form-fields>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Middle Name</label>
                            <input type="text" name="middle_name" placeholder="Middle Name" v-model="user.middle_name" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields fields="two">
                        <sui-form-field width="ten">
                            <label>Last Name</label>
                            <input type="text" name="last_name" placeholder="Last Name" v-model="user.last_name" />
                        </sui-form-field>
                        <sui-form-field width="six">
                            <label>Suffix</label>
                            <input type="text" name="last_name" placeholder="Suffix"  v-model="user.suffix" />
                        </sui-form-field>
                    </sui-form-fields>
                </sui-grid-column>
            </sui-grid-row>
            <sui-grid-row centered>
                <sui-grid-column width="four">
                    <sui-form-field>
                        <label>Company Name</label>
                        <input type="text" name="title" placeholder="Company Name" v-model="user.profile.company" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Title/Role</label>
                        <input type="text" name="title" placeholder="Title/Role" v-model="user.profile.title" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Department</label>
                        <input type="text"  name="department" placeholder="Department" v-model="user.profile.department" />
                    </sui-form-field>
                </sui-grid-column>
                <sui-grid-column width="four">
                    <sui-form-field disabled>
                        <label>Email</label>
                        <input type="email" name="email" placeholder="Email" v-model="user.email" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Mobile Phone</label>
                        <input type="text" name="mobile" placeholder="Mobile Phone" v-model="user.profile.mobile_phone" />
                    </sui-form-field>
                    <sui-form-fields>
                        <sui-form-field width="ten">
                            <label>Phone</label>
                            <sui-form-field>
                                <input type="text" name="phone" placeholder="Phone" v-model="user.profile.phone" />
                            </sui-form-field>
                        </sui-form-field>
                        <sui-form-field width="six">
                            <label>Extension</label>
                            <input type="text" name="phone_extension"  placeholder="Extension" v-model="user.profile.phone_extension" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-field>
                        <label>Fax</label>
                        <input type="text" name="fax" placeholder="Fax" v-model="user.profile.fax"/>
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
            <sui-grid-row centered>
                <sui-grid-column centered width="eight">
                    <sui-form-field>
                        <label>Address Line 1</label>
                        <input type="text" name="address_line_1" placeholder="Street Address" v-model="user.profile.address_line_1" />
                    </sui-form-field>
                    <sui-form-field class="field">
                        <label>Address Line 2</label>
                        <input type="text" name="address_line_2" placeholder="C/O, Apt, Suite, Unit, Building, Floor" v-model="user.profile.address_line_2" />
                    </sui-form-field>
                    <sui-form-fields class="two fields">
                    </sui-form-fields>
                    <sui-form-field>
                        <sui-form-fields fields="two" equal-width>
                            <sui-form-field>
                                <label>City</label>
                                <input type="text" name="city" placeholder="City" v-model="user.profile.city" />
                            </sui-form-field>
                            <sui-form-field>
                                <label>State/Province</label>
                                <input type="text" name="state" placeholder="State/Province" v-model="user.profile.state" />
                            </sui-form-field>
                        </sui-form-fields>
                    </sui-form-field>
                    <sui-form-fields equal-width>
                        <sui-form-field>
                            <label>Zip/Postal Code</label>
                            <input type="text" name="zip" placeholder="Zip/Postal Code" v-model="user.profile.zip" />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Country</label>
                            <input type="text" name="country" placeholder="Country" v-model="user.profile.country" />
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-button
                        type="button"
                        basic
                        size="small"
                        content="Update personal info"
                        color="blue"
                        @click="save"
                    />
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
import {mapActions, mapState} from "vuex";

    export default {
        name: "PersonalInfo",
        created() {
            this.user = Object.assign({}, this.userProfile);
        },
        data() {
            return {
                user: {},
                salutations: [
                    { value: "Miss.", text: "Miss" },
                    { value: "Mr.", text: "Mr." },
                    { value: "Mrs.", text: "Mrs." },
                    { value: "Ms.", text: "Ms." },
                ]
            }
        },
        computed: {
            ...mapState(["userProfile"]),
        },
        inject: ["notyf"],
        methods: {
            ...mapActions(["getUserProfile","updatePersonalInfo"]),
            save() {
                const loader = this.$loading.show();
                this.updatePersonalInfo(this.user)
                    .then(() => {
                        this.getUserProfile(this.user.id);
                    })
                    .then(() => {
                        loader.hide()
                        this.notyf.success("Personal info updated");
                    })
                    .catch(() => loader.hide());
            }
        },
    }
</script>

<style scoped>
    .profile-info-header {
        font-weight: lighter !important;
        color: #667d88 !important;
    }
</style>
