<template>
    <div>
        <sui-table basic="very" selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                        {{column.text}}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="staff">
                <sui-table-row
                    v-for="(member, index) in staff" :key="index">
                    <sui-table-cell collapsing>{{ index + 1 }}</sui-table-cell>
                    <sui-table-cell>
                        <h4 is="sui-header">
                            <sui-icon name="user circle" color="grey" size="big"/>
                            <sui-header-content>
                                {{ member.PreferredName__c + " " + member.Name}}
                                <sui-header-subheader>{{member.Title__c}}</sui-header-subheader>
                            </sui-header-content>
                        </h4>
                    </sui-table-cell>
                    <sui-table-cell collapsing>
                        <h4 is="sui-header">
                            <sui-icon name="building" color="grey" size="big"/>
                            <sui-header-content>
                                <sui-header-subheader>{{ member.FirmId__r.Name }}</sui-header-subheader>
                            </sui-header-content>
                        </h4>
                    </sui-table-cell>
                    <sui-table-cell collapsing>{{ member.WorkPhone__c }}</sui-table-cell>
                    <sui-table-cell collapsing>{{ member.Mobile_Phone__c }}</sui-table-cell>
                    <sui-table-cell collapsing>{{ member.WorkEmail__c }}</sui-table-cell>
                    <sui-table-cell collapsing v-if="user.is_manager">
                        <sui-dropdown class="icon" icon="chevron down" pointing="center left">
                            <sui-dropdown-menu>
                                <sui-dropdown-item @click="getStaffMember(member.Id)">
                                    <sui-icon name="address card outline" />View member
                                </sui-dropdown-item>
                                <sui-dropdown-item @click="getStaffMember(member.Id)">
                                    <sui-icon name="edit" />Edit member
                                </sui-dropdown-item>
                            </sui-dropdown-menu>
                        </sui-dropdown>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        name: "StaffList",
        props: {
            staff: { type: Array },
            user: { type: Object }
        },
        computed: {
            ...mapState({ user: "userProfile" })
        },
        data() {
            return {
                columns: [
                    { key: 'member', value: 'name',  text: 'Member' },
                    { key: 'company', value: 'company',  text: 'Company' },
                    { key: 'phone', value: 'phone',  text: 'Phone' },
                    { key: 'mobile', value: 'mobile',  text: 'Mobile' },
                    { key: 'email', value: 'email',  text: 'Email' },
                ]
            }
        },
        methods: {
            toggleModal() {
                this.$emit('toggleModal');
            },
            getStaffMember(staffMemberId) {
                this.$emit('getStaffMember', staffMemberId);
            },
            showDeleteAlert(staffMember) {
                this.$emit('showDeleteAlert', staffMember);
            },
        }
    }
</script>

<style scoped>

</style>
