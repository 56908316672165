import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SuiVue from 'semantic-ui-vue'
import 'semantic-ui-css/semantic.min.css'
import VueSession from "vue-session"
import VuePapaParse from 'vue-papa-parse'

import { library } from '@fortawesome/fontawesome-svg-core'
import {faBuilding,faCaretDown, faCaretUp, faCog, faAngleDown, faAngleUp, faEye, faEyeSlash, faSignOutAlt, faUser, faUserCircle,
  faList,faListAlt, faExternalLinkAlt, faExternalLinkSquareAlt, faPen, faPenSquare, faPencilAlt, faUsers, faInfo, faInfoCircle,
  faBalanceScale, faTrash, faTrashAlt, faFileExcel, faTimes, faPaperPlane,

} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import Vuelidate from 'vuelidate'
import money from 'v-money'
import JsonCSV from 'vue-json-csv'
import VueCurrencyInput from 'vue-currency-input'


import filters from './filters/index.js'

library.add(
    faBalanceScale, faBuilding,
    faCaretDown, faCaretUp,
    faCog,
    faAngleDown, faAngleUp,
    faEye, faEyeSlash,
    faSignOutAlt,
    faUser,faUsers, faUserCircle,
    faList, faListAlt,
    faExternalLinkAlt, faExternalLinkSquareAlt,
    faPen, faPenSquare, faPencilAlt, faInfoCircle,faInfo,
    faTrash, faTrashAlt, faFileExcel, faTimes, faPaperPlane
)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('downloadCsv', JsonCSV);

Vue.use(SuiVue)
Vue.use(VueSession)
Vue.use(Vuelidate);
Vue.use(money, {
  prefix: '$ ',
  precision: 2,
  decimal: ',',
  thousands: '.',
  masked: false
});
Vue.use(filters);
Vue.use(VuePapaParse);
Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: 'en',
    currency: 'USD',
    valueAsInteger: false,
    distractionFree: true,
    autoDecimalMode: false,
    allowNegative: false
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  provide: () => {
    return {
      notyf: new Notyf({
        duration: 5000,
        position: {
          x: 'center',
          y: 'top',
        },
        ripple: false,
        dismissible: true,
      })
    }
  },
  render: h => h(App)
}).$mount('#app');
