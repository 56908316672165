export default {
    methods: {
        currentFiscalQuarter() {
            let today = new Date();
            let mo = Math.floor((today.getMonth() / 3) + 1);
            return mo > 4 ? mo - 4 : mo;
        },
        previousFiscalQuarter() {
            return ((this.currentFiscalQuarter() - 1) === 0) ? 4 : this.currentFiscalQuarter() - 1;
        },
        currentFiscalQuarterStartDate() {
            const today =  new Date();
            const month = today.getMonth() - today.getMonth() % 3;
            return new Date(today.getFullYear(), month, 1);
        }
    }
}
