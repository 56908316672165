<template>
    <div>
        <sui-table selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                        {{ column}}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="supervisedPersons">
                <sui-table-row v-for="(person, index) in supervisedPersons" :key="person.id">
                    <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>
                        <div class="supervised-person-full-name" @click="handleEdit(person.id)">
                            {{`${person.last_name}, ${person.first_name}`}}
                        </div>
                    </sui-table-cell>
                    <sui-table-cell>{{ person.first_name }}</sui-table-cell>
                    <sui-table-cell>{{ person.last_name }}</sui-table-cell>
                    <sui-table-cell>{{ person.email }}</sui-table-cell>
                    <sui-table-cell>{{ person.firm_name }}</sui-table-cell>
                    <sui-table-cell collapsing>
                        <div class="table-row-menu">
                            <div class="action-icons">
                                <sui-icon
                                    class="edit-icon"
                                    name="edit"
                                    @click="handleEdit(person.id)"/>
                                <sui-icon
                                    class="trash-icon"
                                    name="trash"
                                    @click="removePerson(person)"/>
                            </div>
                        </div>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
            <div v-else>
                <p>Not models found.</p>
            </div>
        </sui-table>
        <NoItemsFoundListMessage v-if="supervisedPersons.length === 0 " />
    </div>
</template>

<script>
    import NoItemsFoundListMessage from "@/components/common/NoItemsFoundListMessage";
    export default {
        name: "SupervisedPersonList",
        components: {NoItemsFoundListMessage},
        props: {
            supervisedPersons: {
                type: Array
            },
        },
        data() {
            return {
                columns: [
                    'Supervised Person',
                    'First Name',
                    'Last Name',
                    'Email',
                    'Firm'
                ]
            }
        },
        methods: {
            handleEdit(personId) {
                this.$emit("handleEdit", personId);
            },
            removePerson(person) {
                this.$emit("showDeleteWarning", person);
            },
        }
    }
</script>

<style scoped>
    .supervised-person-full-name {
        color: #2185d0;
    }
    .supervised-person-full-name:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    .table-row-menu {
        display: flex !important;
        justify-content: space-between !important;
    }
    .edit-icon, .trash-icon {
        cursor: pointer !important;
        color: #8C979A !important;
    }
    .trash-icon:hover {
        color: #FF6600!important;
    }
</style>
