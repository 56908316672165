import moment from "moment";

export default {
    methods: {
        toDate(date) {
            if (date) return moment(date).locale("en").format("MM/DD/YYYY");
            return "";
        },
        toDatetime(date) {
            if (date) return moment(date).locale("en").format("MM/DD/YYYY, hh:mm:ss A");
            return "";
        },
    }
}
