<template>
    <div id="app">
        <AppNavigation v-if="routes.indexOf(this.$route.name) === -1" />
        <AppFooter v-if="routes.indexOf(this.$route.name) > -1" />
        <router-view/>
    </div>
</template>

<script>
    import AppNavigation from "./components/common/NavigationBar";
    import AppFooter from "./components/common/AppFooter";
    export default {
        name: "App",
        components: {
            AppNavigation,
            AppFooter
        },
        data() {
            return {
                routes: [
                    "login",
                    "logout",
                    "forgotPassword",
                    "passwordReset",
                    "passwordResetDone"
                ],
            }
        },
    }
</script>

<style>
    #app {
        margin: 0;
        padding: 0;
    }
    /* Hide the Google reCAPTCHA badge */
    .grecaptcha-badge {
        visibility: hidden !important;
    }
</style>

=
