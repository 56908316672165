<template>
    <sui-modal v-model="open" size="large" :closable="false">
        <sui-icon name="close" @click="toggleModal"/>
        <sui-modal-header>{{modalHeaderText()}}</sui-modal-header>
        <sui-modal-content scrolling>
            <TicketForm
                ref="ticketForm"
                :isNew="isNew"
                :ticket="ticket"
                :toggleModal="toggleModal"
            />
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                type="button"
                color="grey"
                size="small"
                content="Cancel"
                @click="toggleModal"
            />
            <sui-button
                primary
                type="button"
                size="small"
                content="Save"
                @click="save"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    import TicketForm from "@/components/ticketManager/TicketForm";
    export default {
        name: "TicketModal",
        components: {
            TicketForm,
        },
        props: {
            open: {
                type: Boolean,
            },
            isNew: {
                type: Boolean,
            },
            toggleModal: {
                type: Function,
            },
            ticket: {
                type: Object,
            },
            modalHeaderText: {
                type: Function,
            }
        },
        methods: {
            save() {
                this.$refs.ticketForm.save();
            },
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.2) !important;
    }
</style>
