<template>
    <sui-message class="message">
        {{ messageContent}}
    </sui-message>
</template>

<script>
    export default {
        name: "Message",
        props: {
            messageContent: {
                type: String,
                default: ""
            }
        }
    }
</script>

<style scoped>
    .message {
        background-color: #ffe3e3 !important;
        border: 1px solid #fcbdbd !important;
        color: #921515 !important;
        border: 1px solid rgba(37,40,43,0.1) !important;
        border-radius: 3px !important;
        box-shadow: 0 1px 2px rgba(37,40,43,0.1) !important;
        -webkit-transition: 0.1s !important;
        transition: 0.1s !important;
    }
</style>
