<template>
    <div>
        <sui-modal class="attestation-modal" size="large" :closable="false" v-model="isOpen">
            <sui-icon name="close" @click="closeModal" />
            <sui-modal-header class="attestation-modal-header">
                {{ attestation.Name}}<br/>{{toDate(attestation.Submitted_Date__c)}}
            </sui-modal-header>
            <sui-modal-content scrolling>
                <MasterForm
                    id="attestationForm"
                    ref="attestationForm"
                    :firm="firm"
                    :attestation="attestation"
                    :get-attestaions="getAttestations"
                    @closeModal="closeModal"
                />
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button
                    basic
                    type="button"
                    icon="arrow down"
                    content="PDF"
                    size="small"
                    floated="left"
                    @click="createPDF"
                >
                </sui-button>
                <sui-button
                    basic
                    size="small"
                    color="grey"
                    type="button"
                    content="Cancel"
                    @click="closeModal"
                />
                <sui-button
                    primary
                    size="small"
                    type="button"
                    content="Save"
                    @click="handleSave"
                />
            </sui-modal-actions>
        </sui-modal>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            v-bind:filename="attestation.Name"
            :paginate-elements-by-height="1100"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            margin="2"
            ref="html2Pdf"
        >
            <section slot="pdf-content">
                <MasterForm
                    :firm="firm"
                    :attestation="attestation"
                    :get-attestaions="getAttestations"
                />
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import MasterForm from "./Forms/MasterForm.vue";
    import dateFormatter from "@/mixins/dateFormatter";
    export default {
        name: "Modal",
        components: {MasterForm, VueHtml2pdf},
        props: {
            isOpen: {type: Boolean},
            attestation: {type: Object},
            getAttestations: {type: Function},
        },
        mixins: [dateFormatter],
        data() {
            return {
                isModalToggled: this.isOpen,
            }
        },
        computed: {
            firm() {
                if (this.$store.getters.quarterlyAttestation) {
                    const attestation = this.$store.getters.quarterlyAttestation;
                    if (attestation) {
                        return {
                            name: attestation.Firm_Name__c,
                        };
                    }
                }
                return {};
            },
        },
        methods: {
            closeModal() {
                this.$emit("toggleModal");
            },
            handleSave() {
                this.$refs.attestationForm.handleUpdate();
            },
            createPDF() {
                this.$refs.html2Pdf.generatePdf()
            }
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.25) !important;
    }
    .attestation-modal {
        margin-top: 2em !important;
    }
    .attestation-modal-header {
        text-align: center !important;
        font-size: small !important;
    }
</style>

