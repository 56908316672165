<template>
    <div class="loader-container">
        <div class="loader"></div>
    </div>
</template>

<script>
    export default {
        name: "Loader"
    }
</script>

<style scoped>
     .loader-container {
         position: fixed;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
         width: 100%;
         background: rgba(0, 0, 0, 0.25);
         z-index: 1000;
     }
    .loader {
        /*border: 5px solid #f3f3f3;*/
        border: 5px solid #FFF;
        border-top: 5px solid #373D3F;
        /*border-top: 5px solid #2a998b;*/
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1.5s linear infinite;
        margin: 25% auto;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
