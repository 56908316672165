<template>
    <sui-table basic="very" celled>
        <sui-table-header>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell v-for="(column, index) in columns" :key="index">
                {{column}}
            </sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
        </sui-table-header>
        <sui-table-body v-if="personalSecurities">
            <sui-table-row v-for="(security, index) in personalSecurities" :key="index">
                <sui-table-cell>{{ index + 1}}</sui-table-cell>
                <sui-table-cell>{{ security.Account_Owner__c }}</sui-table-cell>
                <sui-table-cell>{{ security.Account_Number__c }}</sui-table-cell>
                <sui-table-cell>{{ security.Account_Type__c }}</sui-table-cell>
                <sui-table-cell>{{ security.Custodian__c }}</sui-table-cell>
                <sui-table-cell collapsing>
                    <div class="table-row-menu">
                        <div class="action-icons">
                            <sui-icon class="edit-icon" name="edit" @click="editPersonalSecurity(index)"/>
                            <sui-icon
                                class="trash-icon"
                                name="trash"
                                @click="removePersonalSecurity({index: index, id: security.Id})"
                            />
                        </div>
                    </div>
                </sui-table-cell>
            </sui-table-row>
        </sui-table-body>
        <sui-table-footer full-width>
            <sui-table-row>
                <sui-table-header-cell />
                <sui-table-header-cell colspan="5">
                    <sui-button
                        type="button"
                        basic
                        content="Add"
                        floated="left"
                        size="small"
                        @click="handleSecurityForm"
                    />
                </sui-table-header-cell>
            </sui-table-row>
        </sui-table-footer>
    </sui-table>
</template>

<script>
    export default {
        name: "PersonalSecurityTable",
        props: {
            personalSecurities: {type: Array},
            toggleIsNewPersonalSecurity: {type: Function},
            reset: {type: Function},
        },
        data() {
            return {
                columns: [
                    'Account Owner',
                    'Account #',
                    'Account Type',
                    'Custodian',
                ],
            }
        },
        methods: {
            handleSecurityForm() {
                this.reset();
                this.toggleIsNewPersonalSecurity();
                this.$emit('handlePersonalSecurityForm');
            },
            editPersonalSecurity(index) {
                this.$emit('getPersonalSecurity', index);
            },
            removePersonalSecurity(data) {
                this.$emit('removePersonalSecurity', data);
            }
        }
    }
</script>

<style scoped>
    .table-row-menu {
        display: flex;
        justify-content: space-between;
    }
    .edit-icon, .trash-icon {
        cursor: pointer;
        color: #8C979A;
    }
</style>
