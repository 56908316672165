<template>
    <sui-card class="centered card" >
        <sui-card-content>
            <div class="center aligned header" style="margin: 1.5em;">Log in</div>
            <sui-form class="login-form" >
                <Message v-if="authenticationFailed" :messageContent="message"></Message>
                <sui-form-field>
                    <label>Email address</label>
                    <sui-input
                        type="email"
                        icon="user"
                        icon-position="left"
                        placeholder="Email address"
                        autocomplete="false"
                        v-model.trim="userCredentials.email"
                    />
                    <div class="error-message" hidden>Please enter your email address.</div>
                </sui-form-field>
                <sui-form-field>
                    <div class="password-label">
                        <label class="label-text">Password</label>
                        <font-awesome-icon
                            :icon="passwordLabelIcon"
                            class="label-icon"
                            @click="togglePasswordInputType"
                        />
                    </div>
                    <sui-input
                            :type="passwordInputType"
                            icon="lock"
                            icon-position="left"
                            placeholder="Password"
                            autocomplete="false"
                            v-model.trim="userCredentials.password"
                    />
                    <div class="error-message" hidden>Please enter your password.</div>
                </sui-form-field>
                <sui-grid>
                    <sui-grid-row>
                        <sui-grid-column>
                            <sui-button
                                class="login-button"
                                content="Log In"
                                type="button"
                                fluid
                                color="teal"
                                @click="login"
                            >
                            </sui-button>
                        </sui-grid-column>
                    </sui-grid-row>
                    <sui-grid-row>
                        <sui-grid-column>
                            <div class="forgotten-password">
                                <router-link :to="{name: 'forgotPassword'}" class="router-link">
                                    Forgot your password?
                                </router-link>
                            </div>
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>
            </sui-form>
        </sui-card-content>
    </sui-card>
</template>

<script>
    import Message from "../common/Message";
    export default {
        name: "Login",
        components: {
            Message,
        },
        data () {
            return {
                passwordInputType: 'password',
                passwordLabelIcon: 'eye',
                userCredentials: {
                    email: null,
                    password: null,
                },
                authenticationFailed: false,
                message: "Incorrect email address and/or password.",
                googleReCaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
            }
        },
        methods: {
            togglePasswordInputType() {
                if (this.passwordInputType === 'password') {
                    this.passwordInputType = 'text';
                    this.passwordLabelIcon = 'eye-slash'
                } else {
                    this.passwordInputType = 'password';
                    this.passwordLabelIcon = 'eye';
                }
            },
            login() {
                let loader = this.$loading.show();
                this.$store.dispatch("login", this.userCredentials)
                    .then(() =>{
                        loader.hide();
                        this.$router.push({name: 'home'});
                    })
                    .catch(() => {
                        this.authenticationFailed = true;
                        loader.hide();
                    });
            },
            verifyCaptcha() {
                const googleReCaptchaSiteKey = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
                // eslint-disable-next-line no-undef
                grecaptcha.ready(() => {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(googleReCaptchaSiteKey, {action: 'submit'}).then(token => {
                        this.$store.dispatch('verifyCaptcha', {token: token})
                            .then(() => {
                                const googleResponse = this.$store.getters.siteVerifyResponse;
                                if (googleResponse.success && googleResponse.score >= .3) {
                                    this.login();
                                }
                            });
                    });
                });
            
            },
        }
    }
</script>

<style scoped>
    .card {
        width: 340px !important;
    }
    .login-form {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
    input[type="checkbox"]{
        color: #2a998b !important;
    }
    .password-label {
        margin-bottom: 4px;
    }
    .password-label .label-text {
        margin: 0 0 .8571429rem 0;
        color: rgba(0,0,0,.87);
        font-size: .92857143em;
        font-weight: 700;
        text-transform: none;
        color: #414141 !important;

    }
    .password-label .label-icon {
        float: right;
        margin-right: 4px;
        cursor: pointer;
    }
    .forgotten-password {
        text-align: center;
    }
    .forgotten-password .router-link {
        color: #2a998b;
    }
</style>
