<template>
        <sui-form size="medium">
            <div class="section portfolio-information">
                <div class="section-header">
                    <h5 is="sui-header">PORTFOLIO INFORMATION</h5>
                </div>
                <div class="section-content">
                    <sui-grid>
                        <sui-grid-row>
                            <sui-grid-column :width="8">
                                <sui-form-field required>
                                    <label>Account Name</label>
                                    <input type="text"  name="account-name" placeholder="Account Name" v-model.trim="$v.strategy.Financial_Account_Name__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Financial_Account_Name__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Financial_Account_Name__c.required" class="invalid-feedback">
                                        Enter the account name.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Account Number</label>
                                    <input type="text"  name="account-number" placeholder="Account Number" v-model.trim="$v.strategy.Custodian_Account_Number__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Custodian_Account_Number__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Custodian_Account_Number__c.required" class="invalid-feedback">
                                        Enter the account number.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Account Master Code</label>
                                    <input type="text"  name="account-master-code" placeholder="Account Master Code" v-model.trim="$v.strategy.Account_Master_Code__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Account_Master_Code__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Account_Master_Code__c.required" class="invalid-feedback">
                                        Enter the account master code.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Custodian</label>
                                    <input type="text"  name="custodian" placeholder="Custodian" v-model.trim="$v.strategy.Custodian__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Custodian__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Custodian__c.required" class="invalid-feedback">
                                        Enter the custodian.
                                    </div>
                                </sui-form-field>
                            </sui-grid-column>
                            <sui-grid-column :width="8">
                                <sui-form-field required>
                                    <label>Advisor</label>
                                    <input type="text" name="advisor" placeholder="Advisor" v-model.trim="$v.strategy.Advisor__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Advisor__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Advisor__c.required" class="invalid-feedback">
                                        Enter the advisor.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Relationship Name</label>
                                    <input type="text" name="relationship-name" placeholder="Relationship Name" v-model.trim="$v.strategy.Relationship_Name__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Relationship_Name__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Relationship_Name__c.required" class="invalid-feedback">
                                        Enter the relationship name.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Fee Schedule</label>
                                    <sui-dropdown
                                        selection
                                        search
                                        direction="downward"
                                        placeholder="Fee Schedule"
                                        :options="feeSchedules"
                                        v-model.trim="$v.strategy.Fee_Schedule__c.$model"
                                        :class="{ 'is-invalid': submitted && $v.strategy.Fee_Schedule__c.$error}"
                                    />
                                    <div v-if="submitted && !$v.strategy.Fee_Schedule__c.required" class="invalid-feedback">
                                        Enter the fee schedule.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Fee Minimum</label>
                                    <currency-input  type="text" name="fee-minimum" placeholder="Fee Minimum" v-model.trim="$v.strategy.Fee_Minimum__c.$model"
                                                     :class="{ 'is-invalid': submitted && $v.strategy.Fee_Minimum__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Fee_Minimum__c.required" class="invalid-feedback">
                                        Enter the fee minimum.
                                    </div>
                                </sui-form-field>
                            </sui-grid-column>
                        </sui-grid-row>
                    </sui-grid>
                    <sui-grid class="client-info">
                        <sui-grid-row>
                            <sui-grid-column :width="8">
                                <sui-form-field required>
                                    <label>Client Name</label>
                                    <input type="text"  name="client-name" placeholder="Client Name" v-model.trim="$v.strategy.Client_Name__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Client_Name__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Client_Name__c.required" class="invalid-feedback">
                                        Enter the client name.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Client Address</label>
                                    <input type="text"  name="client-street" placeholder="Street" v-model.trim="$v.strategy.Client_Street__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Client_Street__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Client_Street__c.required" class="invalid-feedback">
                                        Enter the street.
                                    </div>
                                </sui-form-field>
                                <sui-form-field>
                                    <sui-form-fields>
                                        <sui-form-field required width="eight">
                                            <input type="text" name="joint-client-city" placeholder="City" v-model.trim="$v.strategy.Client_City__c.$model"
                                                   :class="{ 'is-invalid': submitted && $v.strategy.Client_City__c.$error}" />
                                            <div v-if="submitted && !$v.strategy.Client_City__c.required" class="invalid-feedback">
                                                Enter the city.
                                            </div>
                                        </sui-form-field>
                                        <sui-form-field required width="five">
                                            <input type="text"  name="joint-client-state" placeholder="State" v-model.trim="$v.strategy.Client_State__c.$model"
                                                   :class="{ 'is-invalid': submitted && $v.strategy.Client_State__c.$error}" />
                                            <div v-if="submitted && !$v.strategy.Client_State__c.required" class="invalid-feedback">
                                                Enter the state.
                                            </div>
                                        </sui-form-field>
                                        <sui-form-field required width="three">
                                            <input type="text"  name="joint-account-zip-code" placeholder="Zip" v-model.trim="$v.strategy.Client_Zip_Code__c.$model"
                                                   :class="{ 'is-invalid': submitted && $v.strategy.Client_Zip_Code__c.$error}" />
                                            <div v-if="submitted && !$v.strategy.Client_Zip_Code__c.required" class="invalid-feedback">
                                                Enter the zip code.
                                            </div>
                                        </sui-form-field>
                                    </sui-form-fields>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Client Email</label>
                                    <input type="text" name="client-email" placeholder="Email" v-model.trim="$v.strategy.Client_Email__c.$model"
                                           :class="{ 'is-invalid': submitted && $v.strategy.Client_Email__c.$error}" />
                                    <div v-if="submitted && !$v.strategy.Client_Email__c.required" class="invalid-feedback">
                                        Enter the client email.
                                    </div>
                                </sui-form-field>
                            </sui-grid-column>
                            <sui-grid-column :width="8">
                                <sui-form-field>
                                    <label>Joint Client Name</label>
                                    <input type="text"  name="joint-client-name" placeholder="Joint Client Name" v-model="strategy.Joint_Client_Name__c"/>
                                </sui-form-field>
                                <sui-form-field>
                                    <label>Joint Client Address</label>
                                    <sui-form-field>
                                        <input type="text"  name="joint-client-street" placeholder="Street" v-model="strategy.Joint_Client_Street__c"/>
                                    </sui-form-field>
                                </sui-form-field>
                                <sui-form-field>
                                    <sui-form-fields equal-width>
                                        <sui-form-field width="eight">
                                            <input type="text" name="joint-client-city" placeholder="City" v-model="strategy.Joint_Client_City__c"/>
                                        </sui-form-field>
                                        <sui-form-field width="five">
                                            <input type="text"  name="joint-client-state" placeholder="State" v-model="strategy.Joint_Client_State__c"/>
                                        </sui-form-field>
                                        <sui-form-field width="three">
                                            <input type="text"  name="joint-account-zip-code" placeholder="Zip" v-model="strategy.Joint_Client_Zip_Code__c"/>
                                        </sui-form-field>
                                    </sui-form-fields>
                                </sui-form-field>
                                <sui-form-field>
                                    <label>Joint Client Email</label>
                                    <input type="text" name="joint-client-email" placeholder="Email" v-model="strategy.Joint_Client_Email__c"/>
                                </sui-form-field>
                            </sui-grid-column>
                        </sui-grid-row>
                    </sui-grid>
                    <div style="margin-top: 1em">
                        <sui-form-fields inline>
                            <label>Has the client been provided a copy of EverSource Wealth Advisors Form ADV, Form CRS, and Privacy Policy?</label>
                            <sui-form-field>
                                <sui-checkbox name="form-adv-and-privacy-policy" v-model="strategy.Has_Received_Form_ADV_And_Privacy_Policy__c" />
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-form-fields inline>
                            <label>Has the client signed a new or an updated Investment Policy Statement that includes this financial account?</label>
                            <sui-form-field>
                                <sui-checkbox name="has-signed-new-or-update-IPS" v-model="strategy.Has_Signed_New_Or_Updated_IPS__c" />
                            </sui-form-field>
                        </sui-form-fields>
                    </div>
                </div>
            </div>
            <div class="section investment-model section">
                <div class="section-header">
                    <h5 is="sui-header">INVESTMENT MODEL</h5>
                </div>
                <div class="section-content">
                    <sui-grid>
                        <sui-grid-row>
                            <sui-grid-column :width="8">
                                <sui-form-field required>
                                    <label>Investment Model</label>
                                    <sui-dropdown
                                        selection
                                        search
                                        direction="downward"
                                        placeholder="Search Investment Models..."
                                        :options="investmentModels"
                                        v-model.trim="$v.strategy.Investment_Model__c.$model"
                                        :class="{ 'is-invalid': submitted && $v.strategy.Investment_Model__c.$error}"
                                    />
                                    <div v-if="submitted && !$v.strategy.Investment_Model__c.required" class="invalid-feedback">
                                        Enter the investment model.
                                    </div>
                                </sui-form-field>
                                <sui-form-field required>
                                    <label>Trading Authority</label>
                                    <sui-dropdown
                                        selection
                                        direction="downward"
                                        placeholder="Trading Authority"
                                        :options="tradingAuthorities"
                                        v-model.trim="$v.strategy.Trading_Authority__c.$model"
                                        :class="{ 'is-invalid': submitted && $v.strategy.Trading_Authority__c.$error}"
                                    />
                                    <div v-if="submitted && !$v.strategy.Trading_Authority__c.required" class="invalid-feedback">
                                        Enter the trading authority.
                                    </div>
                                </sui-form-field>
                            </sui-grid-column>
                        </sui-grid-row>
                    </sui-grid>
                </div>
            </div>
            <div class="section cash-management">
                <div class="section-header">
                    <h5 is="sui-header">CASH MANAGEMENT</h5>
                </div>
                <div class="section-content">
                    <sui-grid>
                        <sui-grid-row>
                            <sui-grid-column :width="8">
                                <sui-form-field>
                                    <label>Cash Target (%)</label>
                                    <input type="number"  name="cash-target-percentage" placeholder="Cash Target (%)" v-model.number="strategy.Cash_Target_Percentage__c"/>
                                </sui-form-field>
                                <sui-form-fields>
                                    <sui-form-fields inline>
                                        <label>Cash Distributions</label>
                                        <sui-form-field>
                                            <sui-checkbox name="is-cash-contributions" v-model="strategy.Is_Cash_Distributions__c" />
                                        </sui-form-field>
                                    </sui-form-fields>
                                    <sui-form-field v-if="strategy.Is_Cash_Distributions__c" width="twelve">
                                        <label>Amount</label>
                                        <currency-input name="amount" placeholder="Amount" v-model="strategy.Amount__c" />
                                    </sui-form-field>
                                </sui-form-fields>
                            </sui-grid-column>
                            <sui-grid-column :width="8">
                                <sui-form-field>
                                    <label>Cash Target ($)</label>
                                    <currency-input name="cash-target-dollar" placeholder="Cash Target ($)" v-model="strategy.Cash_Target_Dollars__c"/>
                                </sui-form-field>
                                <sui-form-field>
                                    <label>Frequency</label>
                                    <sui-dropdown
                                        selection
                                        direction="downward"
                                        placeholder="Frequency"
                                        :options="cashManagementFrequencyOptions"
                                        v-model="strategy.Cash_Management_Frequency__c"
                                    />
                                </sui-form-field>
                            </sui-grid-column>
                        </sui-grid-row>
                    </sui-grid>
                    <sui-form-field>
                        <label>Notes</label>
                        <textarea name="cash-management-notes" rows="3" v-model="strategy.Cash_Management_Notes__c"></textarea>
                    </sui-form-field>
                </div>
            </div>
        </sui-form>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import { required } from "vuelidate/lib/validators";
    import currency from "@/filters/filters/currency";
    import profiles from "@/profiles";
    
    export default {
        name: "InvestmentStrategyForm",
        props: {
            strategy: {type: Object, default: () => ({
                    Financial_Account_Name__c: null,
                    Custodian_Account_Number__c: null,
                    Custodian__c: null,
                    Account_Master_Code__c: null,
                    Advisor__c: null,
                    Relationship_Name__c: null,
                    Fee_Schedule__c: null,
                    Client_Name__c: null,
                    Client_Street__c: null,
                    Client_City__c: null,
                    Client_State__c: null,
                    Client_Zip_Code__c: null,
                    Client_Email__c: null,
                    Joint_Client_Name__c: null,
                    Joint_Client_Street__c: null,
                    Joint_Client_City__c: null,
                    Joint_Client_State__c: null,
                    Joint_Client_Zip_Code__c: null,
                    Joint_Client_Email__c: null,
                    Has_Received_Form_ADV_And_Privacy_Policy__c: false,
                    Has_Signed_New_Or_Updated_IPS__c: false,
                    Investment_Model__c: null,
                    Cash_Target_Percentage__c: null,
                    Cash_Target_Dollars__c: null,
                    Is_Cash_Distributions__c: false,
                    Amount__c: null,
                    Cash_Management_Frequency__c: null,
                    Cash_Management_Notes__c: null,
                    Fee_Minimum__c: null,
                    Trading_Authority__c: null
                })
            },
        },
        created() {
            const profileTypeTk = this.user.profile.profile_type;
            const firmId = this.user.profile._firm;
        
            if (profileTypeTk === profiles.FINANCIAL_ADVISOR) {
                Promise.all([
                    this.getAdvisorFirmFeeSchedules({ advisor: this.user.id, firm: firmId }),
                    this.getAdvisorFirmModels({ advisor: this.user.id, firm: firmId })
                ]).finally();
            } else {
                Promise.all([
                    this.getFirmsFeeSchedules(firmId),
                    this.getFirmModels(firmId)
                ]).finally();
            }
        },
        validations: {
            strategy: {
                Financial_Account_Name__c: { required },
                Custodian_Account_Number__c: { required },
                Account_Master_Code__c: { required },
                Custodian__c: { required },
                Advisor__c: { required },
                Relationship_Name__c: { required },
                Fee_Schedule__c: { required },
                Client_Name__c: { required },
                Client_Street__c: { required },
                Client_City__c: { required },
                Client_State__c: { required },
                Client_Zip_Code__c: { required },
                Client_Email__c: { required },
                Investment_Model__c: { required },
                Fee_Minimum__c: { required },
                Trading_Authority__c: { required }
            }
        },
        data() {
            return {
                cashManagementFrequencyOptions: [
                    {key: "monthly", value: "Monthly", text: "Monthly"},
                    {key: "quarterly", value: "Quarterly", text: "Quarterly"},
                    {key: "annually", value: "Annually", text: "Annually"},
                    {key: "other", value: "Other", text: "Other"}
                ],
                portfolioRequestTypeOptions: [
                    {key: 'raiseCash', value: 'Raise Cash', text: 'Raise Cash'},
                    {key: 'investCash', value: 'Invest Cash', text: 'Invest Cash'}
                ],
                tradingAuthorities: [
                    {value: "Advisor", text:"Advisor"},
                    {value: "EverSource Investment Department", text: "EverSource Investment Department"}
                ],
                submitted: false
            }
        },
        computed: {
            ...mapState({ user: "userProfile"}),
            ...mapState(["firmFeeSchedules", "firmModels"]),
            feeSchedules() {
                if (this.firmFeeSchedules) {
                    return this.firmFeeSchedules.map(firmFeeSchedule => {
                        return {
                            key: firmFeeSchedule.id,
                            value: firmFeeSchedule.fee_schedule_name,
                            text: firmFeeSchedule.fee_schedule_name
                        };
                    });
                }
                return [];
            },
            investmentModels() {
                if (this.firmModels) {
                    return this.firmModels.map(firmModel => {
                        return {
                            key: firmModel.id,
                            value: firmModel.investment_model_name,
                            text: firmModel.investment_model_name
                        };
                    });
                }
                return [];
            },
        },
        directives: {
            twoWay: true,
            currency: currency
        },
        inject: ["notyf"],
        methods: {
            ...mapActions(["getAdvisorFirmFeeSchedules", "getFirmsFeeSchedules", "getAdvisorFirmModels", "getFirmModels"]),
            cancel() {
                this.$router.push({name: 'investmentStrategyManager'});
            },
            save() {
                this.submitted = true;
                this.$v.$touch();
    
                if (this.$v.$invalid) {
                    this.notyf.error("Required fields not provided.");
                    return;
                }
                
                this.strategy.Submitter_ID__c = this.user.id;
                this.strategy.ESWA_Portal_Owner_ID__c = this.user.id;

                const loader = this.$loading.show();
                this.$store.dispatch('saveInvestmentStrategy', this.strategy)
                    .then( () => {
                        loader.hide();
                        this.$router.push({name: 'investmentStrategyManager'});
                        this.notyf.success('Investment strategy submitted');
                    })
                    .catch();
            },
            update() {
                this.submitted = true;
                this.$v.$touch();
    
                if (this.$v.$invalid) {
                    this.notyf.error("Required fields not provided.");
                    return;
                }
                
                this.strategy.Status__c = 'Submitted';
                const loader = this.$loading.show();
                this.$store.dispatch('updateInvestmentStrategy', this.strategy)
                    .then( () => {
                        loader.hide();
                        this.$store.dispatch('getClientInvestmentStrategyList', this.user.id);
                        this.notyf.success('Investment strategy updated');
                    })
                    .catch();
            },
        }
    }
</script>

<style scoped>
    .section {
        border-top: 3px solid #037397;
        margin: 2em 0;
    }
    .section-header {
        margin: .5em 0;
    }
    .section-content {
    }
    .client-info {
        margin: 4em 0 0 0;
    }
    .invalid-feedback {
        margin-top: 1px;
        margin-left: 1px;
        color: #D63301;
    }
</style>
