<template>
    <div class="password-settings">
        <sui-form>
            <sui-grid centered>
                <sui-grid-row>
                    <sui-grid-column centered width="six">
                        <h2 is="sui-header" class="password-form-header">
                            Change password
                        </h2>
                        <sui-form-field>
                        <label>Current password</label>
                        <input type="password" placeholder="Enter your current password" autocomplete="off" v-model="password.current_password" />
                        </sui-form-field>
                        <sui-form-field>
                            <label>New password</label>
                            <input type="password" placeholder="Enter new password" autocomplete="off" v-model="password.new_password" />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Confirm new password</label>
                            <input type="password" placeholder="Confirm new password" autocomplete="off" v-model="password.new_password_confirmation" />
                        </sui-form-field>
                        <sui-button
                            type="button"
                            basic
                            content="Change password"
                            color="blue"
                            size="small"
                            @click="save"
                        />
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>
        </sui-form>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "ChangePasswordForm",
        data() {
            return{
                password: {
                    current_password: null,
                    new_password: null,
                    new_password_confirmation: null
                },
            }
        },
        inject: ["notyf"],
        methods: {
            ...mapActions(["changePassword"]),
            save() {
                const loader = this.$loading.show();
                this.changePassword(this.password)
                    .then(() => {
                        loader.hide();
                        this.notyf.success("Password changed successfully")
                    })
                    .catch(() => loader.hide());
            }
        }
    }
</script>

<style scoped>
    .password-form-header {
        color: #667d88 !important;
        font-weight: lighter !important;
    }
</style>
