<template>
    <sui-modal size="tiny" :closable="false" v-model="open" class="supervised-person-manager-modal">
        <sui-modal-header class="supervised-person-manager-modal-header">
            {{ title }}
        </sui-modal-header>
        <sui-modal-content>
            <sui-form>
                <sui-form-field>
                    <label>Firm</label>
                    <sui-dropdown
                        selection
                        search
                        direction="downward"
                        placeholder="Search Firms..."
                        :options="firmOptions"
                        v-model="firmFeeSchedule.firm"
                    />
                </sui-form-field>
                <sui-form-field>
                    <label>Fee Schedule</label>
                    <sui-dropdown
                        selection
                        search
                        direction="downward"
                        placeholder="Search Fee Schedules..."
                        :options="feeSchedules"
                        v-model="firmFeeSchedule.fee_schedule"
                    />
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="cancel"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handleSave"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "Modal",
    props:  {
        open: {
            type: Boolean
        },
        mode: {
            type: String
        },
        feeSchedules: {
            required: true,
            type: Array
        },
        cancel: {
            type: Function
        },
    },
    data() {
        return {
            firmFeeSchedule: {},
            title: null
        }
    },
    computed: {
        ...mapState({ schedule: "firmFeeSchedule" }),
        ...mapGetters(["firms"]),
        firmOptions() {
            if (this.firms) {
                return this.firms.map(firm => {
                    return { key: firm.id, value: firm.id, text: firm.name };
                });
            }
            return [];
        },
    },
    watch: {
        mode: {
            handler: "setFormData"
        },
        schedule: {
            deep: true,
            handler: "setFormData"
        }
    },
    methods: {
        setFormData() {
            if (this.mode === "create") {
                console.log(this.mode);
                this.title = "New Firm Fee Schedule"
                this.firmFeeSchedule = {
                    firm: null,
                    fee_schedule: null
                };
            } else if (this.mode === "update") {
                const schedule = Object.assign({}, this.schedule)
                this.title = schedule.fee_schedule_name;
                this.firmFeeSchedule = this.schedule;
            }
        },
        
        handleSave() {
            if (this.mode === "create") {
                this.$emit('add', this.firmFeeSchedule);
            } else {
                this.$emit('edit', this.firmFeeSchedule);
            }
        },
    }
}
</script>

<style scoped>
.ui.dimmer {
    background-color: rgba(0,0,0,.05) !important;
}
.supervised-person-manager-modal-header {
    text-align: center;
}
</style>

