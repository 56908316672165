<template>
    <div>
        <div>
            <sui-container>
                <div class="manager-header">
                    <div class="title">
                        <span><h2>Staff Directory</h2></span>
                    </div>
                    <div class="search-filter">
                        <sui-input
                            placeholder="Search directory..."
                            icon="search"
                            icon-position="left"
                            v-model.trim="searchPhrase"
                        />
                    </div>
                </div>
            </sui-container>
        </div>
        <sui-container>
            <StaffList
                :staff="staff"
                @getStaffMember="getStaffMember"
                @toggleModal="toggleModal"
                @showDeleteAlert="showDeleteAlert"
            />
            <StaffModal
                :isOpen="isOpen"
                :isNew="isNew"
                :staffMember="staffMember"
                :toggle-modal="toggleModal"
                :reset-is-new="resetIsNew"
            />
            <DeleteAlert ref="deleteAlert" @handleDelete="deleteStaffMember" />
        </sui-container>
    </div>
</template>

<script>
    import StaffList from "@/components/staffDirectory/List";
    import DeleteAlert from "@/components/common/DeleteWarningModal";
    import StaffModal from "@/components/staffDirectory/Modal";
    export default {
        name: "Index",
        components: {
            StaffList,
            StaffModal,
            DeleteAlert,
            
        },
        beforeCreate() {
            const loader = this.$loading.show();
            this.$store.dispatch('getStaff')
                .then(() => {
                    loader.hide();
                }).catch();
        },
        data() {
            return {
                searchPhrase: null,
                staffMember: {
                    Id: null,
                    Name: null,
                    First_Name__c: null,
                    PreferredName__c: null,
                    MiddleName__c: null,
                    Bio__c: null,
                    Birthdate__c: null,
                    HomeAddressLine1__c: null,
                    HomeAddressLine2__c: null,
                    HomeCity__c: null,
                    HomeState__c: null,
                    HomePostalCode__c : null,
                    Mobile_Phone__c: null,
                    Role__c: null,
                    Suffix__c: null,
                    Title__c: null,
                    WorkAddressLine1__c: null,
                    WorkAddressLine2__c: null,
                    WorkCity__c: null,
                    WorkState__c: null,
                    WorkPostalCode__c: null,
                    WorkEmail__c: null,
                    WorkPhone__c: null
                },
                isOpen: false,
                isNew: false,
            }
        },
        inject: ['notyf'],
        computed: {
            staff() {
                const staffList =  this.$store.getters.staff;
                if (staffList) {
                    if (this.searchPhrase) {
                        return staffList.filter(staff => {
                            if (staff.First_Name__c.toLowerCase().startsWith(this.searchPhrase.toLowerCase()) ||
                                staff.Name.toLowerCase().startsWith(this.searchPhrase.toLowerCase()) ||
                                staff.PreferredName__c.toLowerCase().startsWith(this.searchPhrase.toLowerCase())
                            ) return staff;
                        });
                    } else {
                        return  staffList;
                    }
                }
                return [];
            },
        },
        methods: {
            toggleModal() {
                this.isOpen = !this.isOpen;
            },
            resetIsNew() {
                this.isNew = false;
            },
            handleAddStaff() {
                this.isNew = true;
                this.staffMember = Object.assign({}, {...this.staffMember,
                    name: null,
                    abbreviation: null,
                    street: null,
                    city: null,
                    state: null,
                    zip_code: null,
                })
                this.toggleModal();
            },
            getStaffMember(staffMemberId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getStaffMember', staffMemberId)
                    .then(() => {
                        loader.hide();
                        this.staffMember = JSON.parse(JSON.stringify(this.$store.getters.staffMember));
                        this.toggleModal();
                    }).catch();
            },
            deleteStaffMember(staffMemberId) {
                const loader = this.$loading.show();
                this.$store.dispatch('deleteStaffMember', staffMemberId)
                    .then(() => {
                        this.$store.dispatch('getStaff');
                        loader.hide();
                        this.$refs.deleteAlert.toggleIsOpen();
                        this.notyf.success('Staff member removed.');
                    }).catch()
            },
            showDeleteAlert(staffMember) {
                this.$refs.deleteAlert.toggleIsOpen(staffMember.Id, staffMember.FullName);
            },
        }
    }
</script>

<style scoped>
    .manager-header {
        display: flex;
        justify-content: space-between;
        margin-top: 1em !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        margin-bottom: 2em;
    }
</style>
