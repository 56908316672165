<template>
    <sui-container fluid class="password-reset-form">
        <Header/>
        <PasswordResetForm/>
    </sui-container>
</template>

<script>
    import Header from "./Header";
    import PasswordResetForm from "../../components/auth/PasswordResetForm";
    export default {
        name: "PasswordResetComplete",
        components: {
            Header,
            PasswordResetForm
        }
    }
</script>

<style scoped>
    .password-reset-form {
        min-height: 100vh;
        width: 100%;
        padding-top: 52px;
        background: #f4f6f9;
    }
</style>
