<template>
    <sui-form size="small">
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column :width="16">
                    <sui-form-field>
                        <label>Name</label>
                        <sui-form-fields equal-width>
                            <sui-form-field>
                                <input type="text"  name="first-name" placeholder="First" v-model="staffMember.First_Name__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <input type="text"  name="middle-name" placeholder="Middle" v-model="staffMember.Middle_Name__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <input type="text"  name="last-name" placeholder="Last" v-model="staffMember.Name" />
                            </sui-form-field>
                            <sui-form-field width="five">
                                <input type="text"  name="suffix" placeholder="Suffix" v-model="staffMember.Suffix__c" />
                            </sui-form-field>
                        </sui-form-fields>
                    </sui-form-field>
                </sui-grid-column>
                <sui-form-fields fields="two" equal-width>
                </sui-form-fields>
            </sui-grid-row>
            <sui-grid-row>
                <sui-grid-column :width="8">
                    <sui-form-field>
                        <label>Title</label>
                        <input type="text"  name="title" placeholder="Title" v-model="staffMember.Title__c" />
                    </sui-form-field>
                </sui-grid-column>
                <sui-grid-column :width="8">
                    <sui-form-field>
                        <label>Phone</label>
                        <input type="text"  name="phone" placeholder="Phone" v-model="staffMember.WorkPhone__c" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Mobile</label>
                        <input type="text"  name="first-name" placeholder="First" v-model="staffMember.Mobile_Phone__c" />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Email</label>
                        <input type="email"  name="email" placeholder="Email" v-model="staffMember.WorkEmail__c" />
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
            <sui-grid-row>
                <sui-grid-column :width="8">
                    <sui-form-field>
                        <label>Work Address</label>
                        <sui-form-field>
                            <input type="text" name="work-address-line-1" placeholder="Address line 1" v-model="staffMember.WorkAddressLine1__c">
                        </sui-form-field>
                        <sui-form-field>
                            <input type="text" name="work-address-line-2" placeholder="Address line 2" v-model="staffMember.WorkAddressLine2__c">
                        </sui-form-field>
                        <sui-form-fields equal-width>
                            <sui-form-field>
                                <input type="text" name="city" placeholder="City" v-model="staffMember.WorkCity__c" />
                            </sui-form-field>
                            <sui-form-field>
                                <sui-dropdown
                                    placeholder="State"
                                    selection
                                    search
                                    :options="states"
                                    v-model="staffMember.WorkState__c"
                                />
                            </sui-form-field>
                            <sui-form-field>
                                <input type="text" name="home-postal-code" placeholder="Zip Code" v-model="staffMember.WorkPostalCode__c" />
                            </sui-form-field>
                        </sui-form-fields>
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
    import states from "@/mixins/states";
    export default {
        name: "MasterForm",
        props: {
            isNew: {type: Boolean},
            staffMember: {type: Object},
            toggleModal: {type: Function},
            resetIsNew: {type: Function},
        },
        mixins: [states],
        inject: ['notyf'],
        methods: {
            save() {
                const loader = this.$loading.show();
                this.$store.dispatch('updateStaffMember', this.staffMember)
                    .then(() => {
                        this.$store.dispatch('getStaff');
                        this.toggleModal();
                        loader.hide();
                        this.notyf.success('Member updated.');
                    }).catch()
                
            },
        }
    }
</script>

<style scoped>
</style>
