<template>
    <div class="main-container">
        <sui-container>
            <CompletedStepsMessage
                v-if="hasCompletedAllSteps"
                :has-completed-steps="hasCompletedAllSteps"
            />
            <sui-step-group stackable size="mini">
                <a is="sui-step">
                    <router-link :to="{ name: 'clientAgreement'}">
                        <sui-step
                            title="Client Agreement Profile"
                            icon="file"
                            v-bind:completed="hasSubmittedClientAgreement"
                        />
                    </router-link>
                </a>
                <a
                    v-if="user.profile.is_exhibit_b_investor_profile_form_user"
                    is="sui-step"
                >
                    <router-link :to="{name: 'investorProfile'}">
                        <sui-step
                            title="Exhibit B: Investor(s) Profile"
                            icon="file"
                            v-bind:disabled="isInvestorProfileFormDisabled"
                            v-bind:completed="isInvestorProfileSubmitted"
                        />
                    </router-link>
                </a>
                <a is="sui-step">
                    <router-link :to="{name: 'formADV'}">
                        <sui-step
                            title="Form ADV Part 2A and 2B"
                            icon="file"
                            v-bind:disabled="isFormAdvDisabled"
                            v-bind:completed="hasViewedOrDownloadedFormADV"
                        />
                    </router-link>
                </a>
                <a is="sui-step">
                    <router-link :to="{name: 'formCRS'}">
                        <sui-step
                            title="Form CRS"
                            icon="file"
                            v-bind:disabled="isFormCrsDisabled"
                            v-bind:completed="hasViewedOrDownloadedFormCRS"
                        />
                    </router-link>
                </a>
            </sui-step-group>
            <router-view></router-view>
        </sui-container>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";
    import CompletedStepsMessage from "@/components/clientForms/CompletedStepsMessage";
    export default {
        name: "Index",
        components: {
            CompletedStepsMessage
        },
        created() {
            if (this.user.profile.profile_type !== 2) {
                Promise.all([
                    this.getClientAgreement(this.user.id),
                    this.getInvestorProfile(this.user.email)
                ]).finally(() => {
                    this.checkStatus();
                });
            } else {
                this.setHasSubmittedClientAgreement(false)
                this.setIsInvestorProfileSubmitted(false);
                this.setHasViewedOrDownloadedFormADV(false);
                this.setHasViewedOrDownloadedFormCRS(false);
            }
        },
        data() {
            return {
                isInvestorProfileFormDisabled: true,
                isFormAdvDisabled: true,
                isFormCrsDisabled: true,
                hasCompletedAllSteps: false
            }
        },
        computed: {
            ...mapState([
                "clientAgreement",
                "hasSubmittedClientAgreement",
                "hasViewedOrDownloadedFormADV",
                "hasViewedOrDownloadedFormCRS",
                "investorProfile",
                "isInvestorProfileSubmitted"
            ]),
            ...mapState({user: "userProfile"})
        },
        watch: {
            clientAgreement: {
                deep: true,
                handler: "checkStatus"
            },
            isInvestorProfileSubmitted: {
                handler: "checkStatus"
            },
            hasCompletedAllSteps: {
                handler: "showCompletedStepsMessage"
            }
        },
        methods: {
            ...mapActions(["getClientAgreement", "getInvestorProfile"]),
            ...mapMutations([
                "setHasSubmittedClientAgreement", "setHasViewedOrDownloadedFormADV",
                "setIsInvestorProfileSubmitted", "setHasViewedOrDownloadedFormCRS"
            ]),
            checkStatus() {
                const clientAgreement = this.$store.getters.clientAgreement;
                if (clientAgreement) {
                    if (clientAgreement.Id && clientAgreement.Client_Agreement_Date__c) {
                        this.setHasSubmittedClientAgreement(true);
                        if (this.user.profile.is_exhibit_b_investor_profile_form_user) {
                            this.isInvestorProfileFormDisabled = false;
                            if (this.investorProfile && this.investorProfile.Investor_Profile_Status__c) {
                                this.setIsInvestorProfileSubmitted(true);
                                this.isFormAdvDisabled = false;
                            } else {
                                this.isFormAdvDisabled = true;
                            }
                        } else {
                            this.isFormAdvDisabled = false;
                        }
                    }
                    
                    if (clientAgreement.Client__r) {
                        const client = clientAgreement.Client__r;
                        if (client.Initial_Form_ADV_Delivery_Date__c) {
                            this.setHasViewedOrDownloadedFormADV(true);
                            this.isFormCrsDisabled = false;
                        }
                        if (client.Initial_Form_CRS_Delivery_Date__c) {
                            this.setHasViewedOrDownloadedFormCRS(true);
                        }
                    } else if (clientAgreement.Joint_Client__r) {
                        const client = clientAgreement.Joint_Client__r;
                        if (client.Initial_Form_ADV_Delivery_Date__c) {
                            this.setHasViewedOrDownloadedFormADV(true);
                            this.isFormCrsDisabled = false;
                        }
                        if (client.Initial_Form_CRS_Delivery_Date__c) {
                            this.setHasViewedOrDownloadedFormCRS(true);
                        }
                    }
                } else {
                    this.setHasSubmittedClientAgreement(false)
                    this.setIsInvestorProfileSubmitted(false);
                    this.setHasViewedOrDownloadedFormADV(false);
                    this.setHasViewedOrDownloadedFormCRS(false);
                }
                this.showCompletedStepsMessage();
            },
            showCompletedStepsMessage() {
                if (this.user.profile.profile_type !== 2) {
                    if (this.user.profile.is_exhibit_b_investor_profile_form_user) {
                        if (this.hasSubmittedClientAgreement && this.hasViewedOrDownloadedFormADV && this.hasViewedOrDownloadedFormCRS &&
                            this.isInvestorProfileSubmitted) {
                            this.hasCompletedAllSteps = true;
                        } else {
                            this.hasCompletedAllSteps = false;
                        }
                    } else {
                        if (this.hasSubmittedClientAgreement && this.hasViewedOrDownloadedFormADV && this.hasViewedOrDownloadedFormCRS) {
                            this.hasCompletedAllSteps = true;
                        } else {
                            this.hasCompletedAllSteps = false;
                        }
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .main-container {
        margin: .5em !important;
    }
</style>
