<template>
     <sui-modal :closable="false" v-model="isOpen" size="large">
         <sui-icon name="close" @click="toggleSupervisedPersons"/>
         <sui-modal-header>
             <sui-menu compact style="box-shadow: none !important;">
                 <a is="sui-menu-item" @click="filterAttestedSupervisedPersons">
                     <sui-icon name="users" /> Attested
                     <sui-label color="olive" floating>
                         {{numberOfAttestedPersons}}
                     </sui-label>
                 </a>
                 <a is="sui-menu-item" @click="filterPendingSupervisedPersons">
                     <sui-icon name="users" /> Pending
                     <sui-label color="brown" floating>
                         {{numberOfPendingPersons}}
                     </sui-label>
                 </a>
             </sui-menu>
             <sui-button basic icon="undo" floated="right" @click="resetList"/>
         </sui-modal-header>
         <sui-modal-content scrolling>
             <sui-table basic="very" celled selectable>
                 <sui-table-header>
                     <sui-table-row>
                         <sui-table-header-cell></sui-table-header-cell>
                         <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                             {{ column}}
                         </sui-table-header-cell>
                         <sui-table-header-cell></sui-table-header-cell>
                     </sui-table-row>
                 </sui-table-header>
                 <sui-table-body v-if="persons">
                     <sui-table-row v-for="(person, index) in persons" :key="person.id">
                         <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                         <sui-table-cell>{{ person.first_name }} {{ person.last_name }}</sui-table-cell>
                         <sui-table-cell>{{ person.first_name }}</sui-table-cell>
                         <sui-table-cell>{{ person.last_name }}</sui-table-cell>
                         <sui-table-cell>{{ person.email }}</sui-table-cell>
                         <sui-table-cell positive v-if="person.has_attested">
                             <sui-icon name="check" /> Attested
                         </sui-table-cell>
                         <sui-table-cell warning v-if="!person.has_attested">
                             <sui-icon name="attention" /> Not Submitted
                         </sui-table-cell>
                         <sui-table-cell>{{ toDatetime(person.attested_date) }}</sui-table-cell>
                         <sui-table-cell>
                             <SendEmail :supervisedPersonUserId="person.user_id" />
                         </sui-table-cell>
                     </sui-table-row>
                 </sui-table-body>
             </sui-table>
             <NoItemsFoundListMessage v-if="Array.isArray(persons) && !persons.length"/>
         </sui-modal-content>
     </sui-modal>
</template>

<script>
    import NoItemsFoundListMessage from "@/components/common/NoItemsFoundListMessage";
    import SendEmail from "@/components/compliance/SendEmail";
    import dateFormatter from "@/mixins/dateFormatter";
    export default {
        name: "SupervisedPersonsListModal",
        components: {NoItemsFoundListMessage, SendEmail},
        props: {
            isOpen: {type: Boolean},
            supervisedPersons: {type: Array},
            toggleSupervisedPersons: {type: Function}
        },
        mixins: [dateFormatter],
        computed: {
            numberOfAttestedPersons() {
                return this.supervisedPersons.filter(person => {
                    return !!person.has_attested;
                    
                }).length;
            },
            numberOfPendingPersons() {
                return this.supervisedPersons.filter(person => {
                    return !person.has_attested;
                    
                }).length;
            },
        },
        methods: {
            filterAttestedSupervisedPersons() {
                let attestedPersons;
                attestedPersons = this.supervisedPersons.filter(person => {
                    if (person.has_attested) return person;
                });
                this.persons = attestedPersons;
            },
            filterPendingSupervisedPersons() {
                let pendingPersons;
                pendingPersons = this.supervisedPersons.filter(person => {
                    if (!person.has_attested) return person;
                });
                this.persons = pendingPersons;
            },
            resetList() {
                this.persons = this.supervisedPersons;
            }
        },
        watch: {
            supervisedPersons: function () {
                this.persons = this.supervisedPersons;
            }
        },
        data() {
            return {
                persons: this.supervisedPersons,
                columns: [
                    'Supervised Person',
                    'First Name',
                    'Last Name',
                    'Email',
                    'Attested',
                    'Attested Date'
                ],
            }
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.2) !important;
    }
</style>
