<template>
    <sui-container>
        <div class="manager">
            <div class="title">
                <h3 is="sui-header" style="font-weight: lighter">Fee Schedules</h3>
            </div>
            <sui-input
                placeholder="Search list..."
                icon="search"
                icon-position="left"
                v-model="searchPhrase"
            />
            <div class="actions">
                <sui-button
                    basic
                    size="tiny"
                    content="New Fee Schedule"
                    color="grey"
                    @click="handleNewFeeSchedule"
                />
            </div>
        </div>
        <FeeScheduleList
            :feeSchedules="feeSchedules"
            @handleViewFeeSchedule="handleViewFeeSchedule"
            @handleEditFeeSchedule="handleEditFeeSchedule"
            @showDeleteWarning="showDeleteWarning"
        />
        <Modal
            ref="modal"
            :title="title"
            :feeSchedule="feeSchedule"
            :firms="firms"
            :financialAdvisors="financialAdvisors"
            :reset="reset"
        />
        <DeleteWarning
            ref="deleteWarningModal"
            @handleDelete="handleDeleteFeeSchedule"
        />
    </sui-container>
</template>

<script>
    import FeeScheduleList from "./FeeScheduleList";
    import Modal from "./BaseModal";
    import DeleteWarning from "@/components/common/DeleteWarningModal";
    
    export default {
        name: "Index",
        components: {
            FeeScheduleList,
            Modal,
            DeleteWarning,
        },
        inject: ['notyf'],
        beforeCreate() {
            const loader = this.$loading.show();
            Promise.all([
                this.$store.dispatch('getAllFeeSchedules'),
            this.$store.dispatch('getAllFirms'),
            this.$store.dispatch("getAllAdvisors", 2)
            ])
                .finally(() => loader.hide());
        },
        data(){
            return {
                firmTk: '',
                open: false,
                title: null,
                feeSchedule: {
                    fee_schedule_name: null,
                    firm: null,
                    financial_advisor: null,
                },
                isCreate: false,
                searchPhrase: null
            }
        },
        computed: {
            feeSchedules() {
                const schedules = this.$store.getters.feeScheduleList;
                if (schedules) {
                    if (this.searchPhrase) {
                        return schedules.filter(schedule => {
                            if (schedule.name.toLowerCase().includes(this.searchPhrase.toLowerCase())){
                                return schedule;
                            }
                        });
                    } else {
                        return  schedules;
                    }
                }
                return [];
            },
            firms() {
                const firms = this.$store.getters.firms;
                if (firms) {
                    let firmList = firms.map(firm => {
                        return {key: firm.id, value: firm.id, text: firm.name};
                    });
                    firmList.unshift({key: 0, value: '', text: 'All'});
                    return firmList;
                }
                return [];
            },
            financialAdvisors() {
                const advisors = this.$store.getters.users;
                if (advisors) {
                    return advisors.map(advisor => {
                        const fullName = advisor.first_name.concat(' ',  advisor.last_name);
                        return {key: advisor.id, value: advisor.id, text: fullName};
                    })
                }
                return [];
            },
        },
        watch: {
            firmTk: {
                handler: 'getFeeSchedules',
            },
        },
        methods: {
            toggleModal() {
                this.open = !this.open;
            },
            initialState() {
                return {
                    open: false,
                    title: null,
                    feeSchedule: {
                        fee_schedule_name: null,
                        firm: null,
                        financial_advisor: null,
                    },
                }
            },
            reset() {
                Object.assign(this.$data, this.initialState());
            },
            getFeeSchedule(feeScheduleId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getFeeSchedule', feeScheduleId)
                    .then(() => {
                        this.feeSchedule = this.$store.getters.feeSchedule;
                        loader.hide();
                    }).catch();
            },
            getFeeSchedules() {
                const user = this.$store.getters.userProfile;
                const loader = this.$loading.show();
                if (this.firmTk) {
                    this.$store.dispatch('getFirmFeeSchedules', this.firmTk, user.id)
                        .then(() => {
                            this.$store.dispatch('getFirmAdvisors', this.firmTk);
                            loader.hide();
                        }).catch()
                } else {
                    this.$store.dispatch('getAllFeeSchedules')
                        .then(() => {
                            const profileTypeTk = 2;
                            this.$store.dispatch('getAllAdvisors', profileTypeTk);
                            loader.hide();
                        }).catch()
                }
            },
            getAllAdvisors() {
                this.$store.dispatch('getAllAdvisors');
            },
            getFirmAdvisors(firmTk) {
                this.$store.dispatch('getFirmAdvisors', firmTk);
            },
            handleNewFeeSchedule() {
                this.$refs.modal.toggleIsCreate();
                this.$refs.modal.toggleModal();
            },
            handleViewFeeSchedule(feeScheduleId) {
                this.getFeeSchedule(feeScheduleId);
                this.$refs.modal.toggleModal();
            },
            handleEditFeeSchedule(feeScheduleId) {
                this.getFeeSchedule(feeScheduleId);
                this.$refs.modal.toggleModal();
            },
            showDeleteWarning(feeSchedule) {
                this.$refs.deleteWarningModal.toggleIsOpen(feeSchedule.id, feeSchedule.fee_schedule_name);
            },
            handleDeleteFeeSchedule(feeScheduleId) {
                const loader = this.$loading.show();
                this.$store.dispatch('deleteFeeSchedule', feeScheduleId)
                    .then(() => {
                        this.$store.dispatch('getAllFeeSchedules');
                        loader.hide();
                        this.$refs.deleteWarningModal.toggleIsOpen();
                        this.notyf.success('Fee schedule deleted successfully.');
                    })
                    .catch();
            }
        }
    }
</script>

<style scoped>
    .manager {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5em !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
    .manager .title {
        padding-left: 20px;
    }
    .manager .filter {
    }
    .manager .actions {
}

</style>
