<template>
    <div class="main-container">
        <sui-container>
            <InvestorProfileForm />
        </sui-container>
    </div>
</template>

<script>
import InvestorProfileForm from "@/components/clientForms/exhibit-b-investor-profile/InvestorProfileForm";

export default {
    name: "InvestorProfile",
    components: {InvestorProfileForm}
}
</script>

<style scoped>
    .main-container {
        margin: 1em 0em !important;
    }
</style>
