<template>
    <div>
        <sui-table selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                        {{ column }}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="models">
                <sui-table-row v-for="(model, index) in models" :key="model.id">
                    <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>
                        <div class="model-name" @click="edit(model.id)">
                            {{ model.investment_model_name }}
                        </div>
                    </sui-table-cell>
                    <sui-table-cell>{{ model.firm_name }}</sui-table-cell>
                    <sui-table-cell collapsing>{{ toDatetime(model.created_date) }}</sui-table-cell>
                    <sui-table-cell>{{ toDatetime(model.last_modified_date) }}</sui-table-cell>
                    <sui-table-cell >
                        <div class="table-row-menu">
                            <div class="action-icons">
                                <sui-icon
                                    class="edit-icon"
                                    name="edit"
                                    @click="edit(model.id)"/>
                                <sui-icon
                                    class="trash-icon"
                                    name="trash"
                                    @click="remove(model)"/>
                            </div>
                        </div>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
            <div v-else>
                <p>Not firm models found.</p>
            </div>
        </sui-table>
        <NoItemsFoundListMessage v-if="models.length === 0 " />
    </div>
</template>

<script>
    import dateFormatter from "@/mixins/dateFormatter";
    import NoItemsFoundListMessage from "@/components/common/NoItemsFoundListMessage";
    
    export default {
        name: "ModelList",
        components: { NoItemsFoundListMessage },
        props: {
            models: {
                required: true,
                type: Array
            },
        },
        data() {
            return {
                columns: [
                    'Model',
                    'Firm',
                    'Created Date',
                    'Last Modified Date',
                ]
            }
        },
        mixins: [dateFormatter],
        methods: {
            edit(firmModelId) {
                this.$emit("handleEdit", firmModelId);
            },
            remove(firmModel) {
                this.$emit("showDeleteWarning", firmModel);
            },
        }
    }
</script>

<style scoped>
    .model-name {
        cursor: pointer !important;
        color: #2185d0 !important;
    }
    .model-name:hover {
        text-decoration: underline !important;
    }
    .table-row-menu {
        display: flex !important;
        justify-content: space-between !important;
    }
    .edit-icon, .trash-icon {
        cursor: pointer !important;
        color: #8C979A !important;
    }
    .trash-icon:hover {
        color: #FF6600!important;
    }
</style>
