<template>
    <div>
        <sui-table selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                            <span class="att-table-col-header" @click="sortList(column.value)">
                                {{ column.key }}
                                <sui-icon name="sort" />
                            </span>
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <template v-if="attestations">
                <sui-table-body>
                    <sui-table-row
                        v-for="(attestation, index) in attestations" :key="index">
                        <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                        <sui-table-cell collapsing class="attestation-item-link" @click="getAttestation(attestation.Id)">
                            <span> {{attestation.Name}}</span>
                        </sui-table-cell>
                        <sui-table-cell>{{attestation.Last_Name__c}}, {{attestation.First_Name__c}}</sui-table-cell>
                        <sui-table-cell>{{ attestation.Status__c }}</sui-table-cell>
                        <sui-table-cell>{{ toDate(attestation.Submitted_Date__c) }}</sui-table-cell>
                        <sui-table-cell>{{attestation.Fiscal_Year__c}}</sui-table-cell>
                        <sui-table-cell>{{attestation.Fiscal_Quarter__c}}</sui-table-cell>
                        <sui-table-cell collapsing>
                            <sui-dropdown pointing="center left">
                                <sui-dropdown-menu>
                                    <sui-dropdown-item @click="getAttestation(attestation.Id)">View</sui-dropdown-item>
                                    <sui-dropdown-item @click="getAttestation(attestation.Id)">
                                        <sui-icon name="pencil square" />Edit
                                    </sui-dropdown-item>
                                    <sui-dropdown-item @click="showDeleteAttestationAlert(attestation)">
                                        <sui-icon name="trash" />Delete
                                    </sui-dropdown-item>
                                </sui-dropdown-menu>
                            </sui-dropdown>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </template>
        </sui-table>
        <NoItemsFoundListMessage v-if="attestations.length === 0" />
        <div v-if="attestation">
            <AttestationModal
                @toggleModal="toggleModal"
                :isOpen="isOpen"
                :attestation="attestation"
                :get-attestations="getAttestations"
            />
        </div>
        <DeleteAlert />
    </div>
</template>

<script>
    import NoItemsFoundListMessage from "@/components/common/NoItemsFoundListMessage";
    import AttestationModal from "./Modal.vue";
    import dateFormatter from "@/mixins/dateFormatter";
    import fiscalQuarter from "@/mixins/fiscalQuarter";
    import quarterlyAttestationUtils from "@/mixins/quarterlyAttestationUtils";
    import DeleteAlert from "@/components/common/DeleteWarningModal";
    export default {
        name: "List",
        components: {NoItemsFoundListMessage, AttestationModal, DeleteAlert},
        props: {
            searchPhrase: {type: String},
        },
        mixins: [quarterlyAttestationUtils, dateFormatter, fiscalQuarter],
        inject: ['notyf'],
        data() {
            return {
                currentSortColumn: null,
                currentSortOrder: null,
                sortOrder: {
                    ASC: 'ASC',
                    DESC: 'DESC',
                },
                columns: [
                    {key: 'Attestation Name', value: 'Name',  text: 'Attestation Name'},
                    {key: 'Supervised  Person', value: 'Last_Name__c',  text: 'Supervised  Person'},
                    {key: 'Status', value: 'Status__c', text: 'Status'},
                    {key: 'Submitted', value: 'Submitted_Date__c', text: 'Submitted Date'},
                    {key: 'Fiscal Year', value: 'Fiscal_Year__c', text: 'FY'},
                    {key: 'Fiscal Quarter', value: 'Fiscal_Quarter__c', text: 'Q'},
                ],
                isOpen: false,
                attestation: null,
                supervisedPerson: null,
            }
        },
        computed: {
            attestations() {
                const attestations = JSON.parse(JSON.stringify(this.$store.getters.quarterlyAttestations));
                if (attestations) {
                    const sortedList =  attestations.sort((a, b) => {
                        let modifier = 1;
                        if (this.currentSortOrder === 'DESC') modifier = -1;
                        if (a[this.currentSortColumn] > b[this.currentSortColumn]) return -1 * modifier;
                        if (a[this.currentSortColumn] < b[this.currentSortColumn]) return modifier;
                        return 0;
                    });
                    return sortedList.filter(attestation => {
                        if (this.searchPhrase) {
                            if (attestation.Name.toLowerCase().includes(this.searchPhrase.toLowerCase())) {
                                return attestation;
                            }
                        } else {
                            return attestation;
                        }
                    });
                } else {
                    return attestations;
                }
            },
        },
        methods: {
            toggleModal() {
                this.isOpen = !this.isOpen;
            },
            sortList(clickedColumn) {
                if (clickedColumn === this.currentSortColumn) {
                    this.currentSortOrder = this.currentSortOrder === this.sortOrder.ASC ? this.sortOrder.DESC :  this.sortOrder.ASC;
                }
                this.currentSortColumn = clickedColumn;
            },
            setAttestation() {
                if (this.$store.getters.quarterlyAttestation) {
                    let  att = JSON.parse(JSON.stringify(this.$store.getters.quarterlyAttestation));
                    if (att) {
                        delete att.Personal_Securities__r;
                        delete att.Outside_Business_Activities__r;
                        delete att.Political_Contributions__r;
                        delete att.Firm__r;
                
                        // Parse and add state where advisor is registered and states where clients reside.
                        this.attestation = Object.assign({}, this.attestation,
                            {...att, States_Registered__c: this.statesRegistered(att.States_Registered__c),
                                States_Clients_Reside__c: this.statesClientsReside(att.States_Clients_Reside__c)});
                    }
                }
            },
            getAttestation(attestationId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getQuarterlyAttestation', attestationId)
                    .then(() => {
                        this.setAttestation();
                        this.setPersonalSecurities();
                        this.setOutsideBusinessActivities();
                        this.setPoliticalContributions();
                        this.getSupervisedPerson();
                        loader.hide();
                        this.toggleModal();
                    });
            },
            getAttestations() {
                this.$emit('getAttestations');
            },
            showDeleteAttestationAlert(attestation) {
                this.$emit('showDeleteAttestationAlert', attestation);
            },
            getSupervisedPerson() {
                const supervisedPersonUserId  = this.$store.getters.quarterlyAttestation.Owner_Tk__c;
                this.$store.dispatch("getSupervisedPersonByUserId", supervisedPersonUserId);
            }
        }
    }
</script>

<style scoped>
    .att-table-col-header {
        cursor: pointer;
    }
    .attestation-item-link {
        color: #2185d0;
    }
    .attestation-item-link:hover {
        text-decoration: underline !important;
        cursor: pointer !important;
    }
    .no-items-found-message {
        padding: 2em 2em !important;
        text-align: center !important;
    }
</style>
