<template>
    <sui-modal size="tiny" :closable="false" v-model="isOpen">
        <sui-modal-header class="delete-alert-modal-header">
            Delete {{ recordName }}
        </sui-modal-header>
        <sui-modal-content>
            <p>Are you sure you want to delete {{recordName}}?</p>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                type="button"
                content="Cancel"
                @click="handleCancel"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Delete"
                @click="handleDelete"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
export default {
    name: "DeleteWarningModal",
    data() {
        return {
            recordId: null,
            recordName: null,
            isOpen: false,
        }
    },
    methods: {
        toggleIsOpen(recordId=null, recordName=null) {
            if (recordId) this.recordId = recordId;
            if (recordName) this.recordName = recordName;
            this.isOpen = !this.isOpen;
        },
        handleCancel() {
            this.toggleIsOpen();
        },
        handleDelete() {
            this.$emit('handleDelete', this.recordId);
        }
    }
}
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.1) !important;
    }
    .delete-alert-modal-header {
        text-align: center !important;
        font-size: medium !important;
    }
</style>
