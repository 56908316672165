<template>
    <div class="main-container">
        <sui-container>
            <Manager />
        </sui-container>
    </div>
</template>

<script>
import Manager from "@/components/investments/firm-fee-schedule-manager/Index";

export default {
    name: "FirmFeeSchedules",
    components: {
        Manager
    }
}
</script>

<style scoped>
    .main-container {
        margin: 10px 0em !important;
    }
    .manager-header {
        font-weight: lighter !important;
    }
</style>
