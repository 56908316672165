<template>
    <sui-container fluid class="login-form">
        <Header/>
        <LoginForm/>
    </sui-container>
</template>
<script>
    import Header from "./Header";
    import LoginForm from '../../components/auth/LoginForm.vue';
    export default {
        name: "login",
        components: {
            Header,
            LoginForm,
        }
    }
</script>

<style scoped>
    .login-form {
        min-height: 100vh;
        width: 100%;
        padding-top: 52px;
        background: #f4f6f9;
    }
</style>

