<template>
    <div class="bgimg">
        <img class="coming-soon" v-bind:src="image">
        <div class="middle">
            <h1 class="header">COMING SOON</h1>
            <sui-divider></sui-divider>
            <div class="countdown">
                <div class="block">
                    <p class="digit">{{ days | twoDigits}}</p>
                    <p class="text">Days</p>
                </div>
                <div class="block">
                    <p class="digit">{{ hours | twoDigits }}</p>
                    <p class="text">Hours</p>
                </div>
                <div class="block">
                    <p class="digit">{{ minutes | twoDigits }}</p>
                    <p class="text">Minutes</p>
                </div>
                <div class="block">
                    <p class="digit">{{ seconds | twoDigits }}</p>
                    <p class="text">Seconds</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CountDown",
        props: {
            countDownDate: {type: String},
        },
        data() {
            return {
                image: require("../../assets/coming-soon.jpg"),
                now: Math.trunc((new Date()).getTime() / 1000),
            }
        },
        mounted() {
            window.setInterval(() => {
                this.now = Math.trunc((new Date()).getTime() / 1000);
            },1000);
        },
        computed: {
            dateInMilliseconds() {
                return Math.trunc(Date.parse(this.countDownDate) / 1000)
            },
            seconds() {
                return (this.dateInMilliseconds - this.now) % 60;
            },
            minutes() {
                return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
            },
            hours() {
                return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
            },
            days() {
                return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
            }
        },
    }
</script>

<style scoped>
    .coming-soon {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        position: relative;
        color: white;
        font-family: "Courier New", Courier, monospace;
        font-size: 25px;
    }
    .header {
        color: #283747;
    }
    .middle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .countdown {
        display: flex;
    }
    .block {
        display: flex;
        flex-direction: column;
        margin: 20px;
    }
    .digit {
        /*color: #154360;*/
        color: #283747;
        font-size: 100px;
        font-weight: 100;
        margin: 10px;
        text-align: center;
    }
    .text {
        color: #ecf0f1;
        font-size: 25px;
        /*font-family: 'Roboto Condensed', serif;*/
        /*font-family: "Courier New", Courier, monospace;*/
        font-weight: 400;
        margin-top:10px;
        margin-bottom: 10px;
        text-align: center;
    }
</style>
