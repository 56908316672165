<template>
    <sui-form size="small">
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column>
                    <sui-form-field>
                        <label>Model Name</label>
                        <input
                            type="text"
                            name="model-name"
                            placeholder="Model Name"
                            v-model="model.name"
                        />
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
    export default {
        name: "MasterForm",
        props: {

            model: {type: Object},
            firms: {type: Array},
            financialAdvisors: {type: Array},
            reset: {type: Function},
            isCreate: {type: Boolean}
        },
        inject: ['notyf'],
        methods: {
            save() {
                const loader = this.$loading.show();
                if (this.isCreate) {
                    this.$store.dispatch('createInvestmentModel', this.model)
                        .then(() => {
                            loader.hide();
                            this.$store.dispatch('getAllInvestmentModels');
                            this.notyf.success('Model created successfully.');
                            this.$emit('toggleIsCreate');
                            this.$emit('toggleModal');
                        }).catch();
                    this.reset();
                    this.$emit('toggleIsUpdate');
                } else {
                    this.$store.dispatch('updateInvestmentModel', this.model)
                        .then(() =>{
                            loader.hide();
                            this.$store.dispatch('getAllInvestmentModels');
                            this.notyf.success('Modal updated successfully');
                            this.$emit('toggleModal');
                        }).catch()
                    this.reset();
                }
            },
        }
    }
</script>

<style scoped>

</style>
