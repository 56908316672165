<template>
    <NoResourceFoundMessage :resource-name="resourceName"/>
</template>

<script>
    import NoResourceFoundMessage from "@/components/resourceManager/NoResourceFoundMessage";
    export default {
        name: "FAQsList",
        components: {
            NoResourceFoundMessage,
        },
        data() {
            return {
                resourceName: 'Frequently Asked Questions',
                resourceList: []
            }
        }
    }
</script>

<style scoped>

</style>
