import Vue from "vue";
import Router from "vue-router"
import store from "./store";

import Home from "@/views/Home";
import AccountActivationView from "@/views/auth/AccountActivationView";
import LoginView from "@/views/auth/LoginView";
import LogoutView from "@/views/auth/LogoutView";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView";
import PasswordResetView from "@/views/auth/PasswordResetView";
import UserManager from "@/components/userManger/Index";
import SettingsManager from "@/views/AccountSettings";
import SettingsProfileForm from "@/components/account-settings/Profile";
import SettingsEmailForm from "@/components/account-settings/Email";
import SettingsPasswordForm from "@/components/account-settings/Password";
import AttestationManager from "@/components/compliance/AttestationManager/Index";
import AttestationForm from "@/components/compliance/AttestationManager/NewAttestation";
import LifeImpact from "@/components/lifeImpact/IndexPage";
import Calendar from "@/components/calendar/IndexPage";
import InvestmentStrategyManager from "@/components/investments/investment-stategy-manager/Index";
import SubmitInvestmentStrategyView from "@/components/investments/investment-stategy-manager/Submit";
import CashRequestManager from "@/components/investments/cash-request-manager/Index";
import CashRequestForm from "@/components/investments/cash-request-manager/Submit";
import ModelManager from "@/components/investments/model-list-manager/Index";
import FeeScheduleManager from "@/components/investments/fee-schedule-list-manager/Index";
import SupervisedPersonManager from "@/components/compliance/SupervisedPersonManager/Index";
import AttestationSubmissionStatus from  '@/components/compliance/AttestationSubmissionStatus/Index';
import FirmManager from "@/components/setup/firmManager/Index";
import ResourceManager from "@/components/resourceManager/Index";
import ComplianceResourceList from "@/components/resourceManager/ComplianceResourceList";
import InvestmentsResourceList from "@/components/resourceManager/InvestmentsResourceList";
import OperationsResourceList from "@/components/resourceManager/OperationsResourceList";
import VideoResourceList from "@/components/resourceManager/VideoResourceList";
import FrequentlyAskedQuestions from "@/components/resourceManager/FAQsList";
import TicketManager from "@/components/ticketManager/Index";
import ClientFormManager from "@/components/clientForms/Index";
import ClientAgreementForm from "@/components/clientForms/client-agreement/Index";
import FormADV from "@/components/clientForms/FormADV";
import FormCRS from "@/components/clientForms/FormCRS";
import InvestorProfileForm from "@/components/clientForms/exhibit-b-investor-profile/InvestorProfileForm";
import InvestorProfileView from "@/views/InvestorProfile";
import FirmModels from "@/views/FirmModels";
import FirmFeeSchedules from "@/views/FirmFeeSchedules";
import StaffDirectory from "@/components/staffDirectory/Index"


Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {path: "/", name: "home", component: Home, meta: {authRequired: true}},

        {path: "/account/activate/:uid/:token", name: "activateAccount", component: AccountActivationView},
        {path: "/account/login", name: "login", component: LoginView},
        {path: "/account/logout", name: "logout", component: LogoutView},
        {path: "/account/password/reset", name: "forgotPassword", component: ForgotPasswordView},
        {path: "/account/password/reset/:uid/:token", name: "passwordReset", component: PasswordResetView},

        {path: "/users/", name: "userManager", component: UserManager, meta: {authRequired: true}},

        {path: "/settings", name: "SettingsManager", component: SettingsManager, children: [
                {name: 'Profile', path: 'profile', component: SettingsProfileForm},
                {name: 'Email', path: 'email', component: SettingsEmailForm},
                {name: 'Password', path: 'password', component: SettingsPasswordForm}
            ],
            meta: { authRequired: true},
        },

        {path: "/life-impact/", name: "lifeImpact", component: LifeImpact,  props: true, meta:
                {authRequired: true}
        },

        // Compliance
        { path:"/compliance/attestations", name: "attestationManager", component: AttestationManager, meta: { authRequired: true } },
        { path:"/compliance/attestations/:id/new", name: "attestationForm", component: AttestationForm, props: true,
            meta: { authRequired: true }},
        { path: "/compliance/supervised-persons/", name: 'supervisedPersonManager', component: SupervisedPersonManager, meta: {authRequired: true} },
        { path: "/compliance/quarterly-attestation-submission-status/", name: 'attestationSubmissionStatus',
            component: AttestationSubmissionStatus, meta: { authRequired: true }},

        // Calendar
        { path: "/calendar", name: "calendar", component: Calendar, props: true, meta: {authRequired: true}},

        // Investments
        { path: "/investments/investment-strategies/", name: 'investmentStrategyManager', component: InvestmentStrategyManager,
            meta: { authRequired: true}},
        { path: "/investments/investment-strategy/new", name: 'submitInvestmentStrategy', component: SubmitInvestmentStrategyView,
            meta: {authRequired: true} },
        { path: "/investments/cash-requests/", name: "cashRequestManager", component: CashRequestManager, meta: { authRequired: true } },
        { path: "/investments/cash-request/new", name: "cashRequestForm", component: CashRequestForm, meta: { authRequired: true } },
        { path: "/investments/models/", name: "modelManager", component: ModelManager, meta: { authRequired: true} },
        { path: "/investments/fee-schedules/", name: "feeScheduleManager", component: FeeScheduleManager, meta: { authRequired: true} },
        { path: "/investments/firm-models/",  name: "firmModelManager", component: FirmModels, meta: { authRequired: true }},
        { path: "/investments/firm-fee-schedules/", name: "firmFeeScheduleManager", component: FirmFeeSchedules, meta: {authRequired: true} },

        // Firm Manager
        { path: "/setup/firms", name: "firmManager", component: FirmManager, meta: { authRequired: true} },

        // Resource Manager
        {path: "/resources/", name: "resourceManager", component: ResourceManager, children: [
                {name: 'complianceResourceList', path: "compliance", component: ComplianceResourceList },
                {name: 'investmentsResourceList', path: "investments", component: InvestmentsResourceList},
                {name: 'operationsResourceList', path: "operations", component: OperationsResourceList},
                {name: 'videosResourceList', path: "videos", component: VideoResourceList},
                {name: 'faqs', path: "faqs", component: FrequentlyAskedQuestions}
            ],
            meta: { authRequired: true},
        },

        // Support
        { path: "/support/", name: "ticketManager", component: TicketManager, meta: { authRequired: true} },

        // Forms
        { path: "/forms/", name: "forms", component: ClientFormManager, children: [
                {name: "clientAgreement", path: "client-agreement/", component: ClientAgreementForm},
                {name: "formADV", path: "form-adv/", props: true, component: FormADV},
                {name: "formCRS", path: "form-crs/", props: true, component: FormCRS},
                {name: "investorProfile", path: "investor-profile/", props: true, component: InvestorProfileForm}
            ], meta: { authRequired: true} },
        { path: "/forms/exhibit-b-investor-profile", name: "investorProfileView", component: InvestorProfileView },
        { path: "/staff-directory/", name: "staffDirectory", component: StaffDirectory}
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        store.dispatch('inspectToken');
        if (!store.state.isAuthenticated) {
            next({
                path: '/account/login'
            });
        } else {
            const user = store.state.userProfile;
            store.dispatch('getUserProfile', user.id);
            next();
        }
    } else {
        next();
    }
});

export default router;
