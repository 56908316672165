<template>
    <div>
        <sui-table basic="very" selectable padded>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in tableColumns" :key=index>
                        {{ column}}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="ticketList">
                <sui-table-row v-for="(ticket, index) in ticketList" :key="index">
                    <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>
                        <a @click="getTicket(ticket.Id)">{{ticket.CaseNumber}}</a>
                    </sui-table-cell>
                    <sui-table-cell>
                        <a @click="getTicket(ticket.Id)">{{ticket.Subject}}</a>
                    </sui-table-cell>
                    <sui-table-cell>{{ ticket.Status }}</sui-table-cell>
                    <sui-table-cell>{{ ticket.Category__c }}</sui-table-cell>
                    <sui-table-cell>{{ ticket.Type }}</sui-table-cell>
                    <sui-table-cell>{{ ticket.Priority }}</sui-table-cell>
                    <sui-table-cell>{{ toDateTime(ticket.CreatedDate) }}</sui-table-cell>
                    <sui-table-cell>{{ toDateTime(ticket.ClosedDate) }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
        <div v-if="ticketList.length === 0" class="no-records-msg">
            <p>No records to display. You haven't submitted any support tickets recently.</p>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        name: "TicketListView",
        props: {
            ticketList: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                tableColumns: [
                    'Case #',
                    'Subject',
                    'Status',
                    'Category',
                    'Type',
                    'Priority',
                    'Date/Time Opened',
                    'Date/Time Closed'
                ],
            }
        },
        methods: {
            toDateTime(date) {
                if (date) {
                    return moment(date).locale('en').format('MM/DD/YYYY hh:mm A');
                }
            },
            getTicket(ticketId) {
                this.$emit('getTicket', ticketId);
            }
        }
    }
</script>

<style scoped>
    .no-records-msg {
        margin: auto;
        text-align: center;
    }
    a:hover {
        cursor: pointer;
    }
</style>
