<template>
     <div>
         <div v-for="firm in firms" :key="firm.id">
             <sui-list class="client-list">
                 <div class="client-list-item">
                     <div @click="getFirmData(firm.id)">
                         <sui-list-icon name="building" />
                         <label>{{ firm.name }}</label>
                     </div>
                 </div>
             </sui-list>
         </div>
     </div>
</template>

<script>
    export default {
        name: "SideBar",
        data() {
            return {
                companies: this.firms
            }
        },
        computed: {
            firms() {
                const firms = this.$store.getters.firms;
                if (firms) {
                    return firms;
                }
                return [];
            }
        },
        methods: {
            getFirmData(firmId) {
                this.$emit('getFirmData', firmId);
            },
        }
    }
</script>

<style scoped>
    .client-list {
        color: #8C979A;
    }
    .client-list .client-list-item {
        margin-bottom: 15px;
    }
    .client-list-item *:hover {
        cursor: pointer;
        color: teal;
    }
</style>
