<template>
    <div class="main-container">
        <div class="sidebar">
            <SideBar
                :firms="firms"
                @getFirmData="getFirmAttestationSubmissionStatus"
            />
        </div>
        <div class="attestation-submission-status-list">
            <div class="manager">
                <div class="title">
                    <span><h2>Attestation Submission Status</h2></span>
                </div>
                <sui-input
                    placeholder="Search persons"
                    icon="search"
                    icon-position="left"
                    v-model="searchPhrase"
                />
            </div>
            <AttestationSubmissionStatusList
                :attestation-submission-status="attestationsSubmissionStatus"
            />
        </div>
    </div>
</template>

<script>
    import AttestationSubmissionStatusList from "./AttestationSubmissionStatusList";
    import SideBar from "@/components/compliance/SideBar";
    export default {
        name: "Index",
        components: {
            AttestationSubmissionStatusList,
            SideBar
        },
        data(){
            return {
                searchPhrase: null,
            }
        },
        beforeCreate() {
            this.$store.dispatch('getAllAttestationASubmissionStatus');
            this.$store.dispatch('getAllFirms');
        },
        computed: {
            attestationsSubmissionStatus() {
                const submissionStatus = this.$store.getters.quarterlyAttestationSubmissionStatus;
                if (submissionStatus) {
                    if (this.searchPhrase) {
                        return submissionStatus.filter(status => {
                            if (status.first_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                                status.last_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                                status.email.toLowerCase().includes(this.searchPhrase.toLowerCase())
                            ) return status;
                        });
                    } else {
                        return  submissionStatus;
                    }
                }
                return [];
            },
            firms() {
                const firms = this.$store.getters.quarterlyAttestationSubmissionStatus;
                if (firms) {
                    return  firms;
                }
                return [];
            },
        },
        methods: {
            getFirmAttestationSubmissionStatus(firmId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getFirmAttestationSubmissionStatus', firmId)
                    .then(() => {
                        loader.hide();
                    }).catch();
            }
        },
    }
</script>

<style scoped>
    .main-container {
        width: 95%;
        height: 200px;
        margin: auto;
        padding: 10px;
    }
    .sidebar {
        width: 18%;
        height: 100vh;
        float: left;
        padding-top: 60px;
        
    }
    .attestation-submission-status-list {
        margin-left: 20%;
        height: 100vh;
    }
    .manager {
        display: flex;
        justify-content: space-between;
        margin-top: 1em !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
    .manager .title {
    }
    .manager .actions {
    }
</style>
