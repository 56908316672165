<template>
    <div class="main-container">
        <div class="manager-header">
            <sui-container>
                <div class="manager">
                    <h3 is="sui-header" style="font-weight: lighter">Supervised Persons</h3>
                    <div class="search-filter">
                        <sui-dropdown
                            class="firm-dropdown-menu"
                            placeholder="Firm"
                            text="Firm"
                            selection
                            :options="firmOptions"
                            v-model="firm"
                        />
                        <sui-input
                            placeholder="Search persons list..."
                            icon="search"
                            icon-position="left"
                            v-model="searchPhrase"
                        />
                    </div>
                    <div class="actions">
                        <sui-button
                            basic
                            size="tiny"
                            content="Add Supervised Person"
                            color="grey"
                            @click="handleAdd"
                        />
                    </div>
                </div>
            </sui-container>
        </div>
        <sui-container class="supervised-persons-list">
            <SupervisedPersonList
                :supervisedPersons="supervisedPersons"
                @handleEdit="handleEdit"
                @showDeleteWarning="showDeleteWarning"
            />
            <Modal
                :open="isOpen"
                :cancel="toggleModal"
                :modalAction="modalAction"
                @addPerson="addPerson"
                @editPerson="editPerson"
            />
            <DeleteWarning ref="deleteWarningModal" @handleDelete="removePerson" />
        </sui-container>
    </div>
</template>

<script>
    import { mapState, mapActions } from "vuex";
    import SupervisedPersonList from "./SupervisedPersonList";
    import Modal from "./Modal";
    import DeleteWarning from "@/components/common/DeleteWarningModal";
    
    export default {
        name: "Index",
        components: {
            DeleteWarning,
            Modal,
            SupervisedPersonList,
        },
        created() {
            const loader = this.$loading.show();
            this.getAllSupervisedPersons()
                .then(() => {
                    loader.hide();
                    this.getAllFirms();
                }).catch()
        },
        data() {
            return {
                firm: '',
                searchPhrase: null,
                isOpen: false,
                modalAction: null
            }
        },
        inject: ['notyf'],
        computed: {
            ...mapState({ user: "userProfile" }),
            firmOptions() {
                const firms = this.$store.getters.firms;
                if (firms) {
                    let firmsOptions = firms.map(firm => {
                        return {key: firm.id, value: firm.id, text: firm.name};
                    });
                    firmsOptions.push({key: '', value: '', text: 'All'});
                    return firmsOptions;
                }
                return [];
            },
            supervisedPersons() {
                const persons = this.$store.getters.supervisedPersons;
                if (persons) {
                    if (this.searchPhrase) {
                        return persons.filter(person => {
                            if (person.first_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                                person.last_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                                person.email.toLowerCase().includes(this.searchPhrase.toLowerCase())
                            ) return person;
                        });
                    } else {
                        return  persons;
                    }
                }
                return [];
            },
            firms() {
                const clients = this.$store.getters.firms;
                if (clients) return clients;
                return [];
            }
        },
        watch: {
            firm: {
                handler: "getPersons",
            }
        },
        methods: {
            ...mapActions(["getAllSupervisedPersons", "getFirmSupervisedPersons", "getSupervisedPerson",
                "getAllFirms", "addSupervisedPerson", "updateSupervisedPerson", "removeSupervisedPerson"
            ]),
            toggleModal() {
                this.isOpen = !this.isOpen;
            },
            handleAdd() {
                this.modalAction = "add";
                this.toggleModal();
            },
            handleEdit(personId) {
                this.modalAction = "edit";
                const loader = this.$loading.show();
                this.getSupervisedPerson(personId).
                then(() => {
                    loader.hide();
                    this.toggleModal();
                });
            },
            getPersons(firmId) {
                const loader = this.$loading.show();
                if (this.firm) {
                    this.getFirmSupervisedPersons(firmId)
                        .then(() => {
                            loader.hide();
                        }).catch()
                } else {
                    this.getAllSupervisedPersons()
                        .then(() => {
                            loader.hide();
                        }).catch()
                }
            },
            addPerson(person) {
                const loader = this.$loading.show();
                this.addSupervisedPerson(person)
                    .then(() => {
                        this.getAllSupervisedPersons();
                        loader.hide();
                        this.notyf.success("Supervised person added.");
                    }).catch()
                this.toggleModal();
            },
            editPerson(person) {
                const loader = this.$loading.show();
                this.updateSupervisedPerson(person)
                    .then(() => {
                        loader.hide();
                        this.notyf.success("Supervised person updated.");
                    }).catch();
                this.toggleModal();
            },
            removePerson(personId) {
                const loader = this.$loading.show();
                this.removeSupervisedPerson(personId)
                    .then(() => {
                        this.getAllSupervisedPersons();
                        loader.hide();
                        this.notyf.success("Supervised person removed.");
                    }).catch();
                this.$refs.deleteWarningModal.toggleIsOpen();
            },
            showDeleteWarning(person) {
                const title = `${person.first_name} ${person.last_name}`;
                this.$refs.deleteWarningModal.toggleIsOpen(person.id, title);
            },
        }
    }
</script>

<style scoped>
    .manager {
        display: flex;
        justify-content: space-between;
        margin-top: 1em !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        margin-bottom: 10px !important;
    }
    .firm-dropdown-menu {
        margin-right: 2px !important;
    }
</style>
