<template>
     <sui-table basic="very" celled padded selectable>
         <sui-table-header>
             <sui-table-row>
                 <sui-table-header-cell></sui-table-header-cell>
                 <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                     {{ column}}
                 </sui-table-header-cell>
             </sui-table-row>
         </sui-table-header>
         <sui-table-body v-if="attestationSubmissionStatus">
            <sui-table-row :positive="hasSubmitted(submission.status)" v-for="(submission, index) in attestationSubmissionStatus" :key="index">
                <sui-table-cell>{{ index + 1 }}</sui-table-cell>
                <sui-table-cell>{{ name(submission) }}</sui-table-cell>
                <sui-table-cell>{{ submission.first_name }}</sui-table-cell>
                <sui-table-cell>{{ submission.last_name }}</sui-table-cell>
                <sui-table-cell>{{ submission.email }}</sui-table-cell>
                <sui-table-cell>{{ submission.status }}</sui-table-cell>
                <sui-table-cell>{{ submission.firm_name }}</sui-table-cell>
            </sui-table-row>
         </sui-table-body>
     </sui-table>
</template>

<script>
    export default {
        name: "AttestationSubmissionStatusList",
        props: {
            attestationSubmissionStatus: {
                type: Array,
                default: () => ([]),
            },
        },
        data() {
            return {
                columns: [
                    'Supervised Person',
                    'First Name',
                    'Last Name',
                    'Email',
                    'Status',
                    'Firm'
                ],
            }
        },
        methods: {
            name(status) {
                const firstName = status.first_name,
                    lastName = status.last_name;
                return `${firstName} ${lastName}`;
            },
            hasSubmitted(status) {
                return status === 'Submitted' || status === 'Delinquent';
             }
        }
    }
</script>

<style scoped>

</style>
