<template>
    <sui-form @submit.prevent="handleSave" size="small">
        <h3 is="sui-header">Client Agreement Profile</h3>
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column :width="8">
                    <div v-if="user.profile.profile_type === 2">
                        <sui-form-fields equal-width>
                            <sui-form-field>
                                <label>Client</label>
                                <sui-dropdown
                                    selection
                                    floating
                                    direction="downward"
                                    placeholder="Client"
                                    :options="clients"
                                    v-model="clientId"
                                />
                            </sui-form-field>
                            <sui-form-field>
                                <label>Client Type</label>
                                <sui-dropdown
                                    placeholder="Select form"
                                    selection
                                    floating
                                    :options="formTypeOptions"
                                    v-model="agreement.Form_Type__c"
                                />
                            </sui-form-field>
                        </sui-form-fields>
                    </div>
                    <div v-else>
                        <sui-dropdown style="margin-bottom: 12px !important;"
                            placeholder="Select form type"
                            selection
                            floating
                            :options="formTypeOptions"
                            v-model="agreement.Form_Type__c"
                        />
                    </div>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
        <sui-grid>
            <sui-grid-row v-if="this.formType === 'Individual Client' ">
                <ClientInput
                    :agreement="agreement"
                    :states="states"
                    :mailPreferenceOptions="mailPreferenceOptions"
                    :employmentStatusOptions="employmentStatusOptions"
                    :submitted="submitted"
                />
            </sui-grid-row>
            <JointClientInput
                v-if="this.formType === 'Joint Clients' "
                :agreement="agreement"
                :states="states"
                :mailPreferenceOptions="mailPreferenceOptions"
                :employmentStatusOptions="employmentStatusOptions"
                :submitted="submitted"
            />
            <sui-grid-row v-if="agreement.Form_Type__c">
                <sui-grid-column>
                    <div>
                        <sui-button
                            basic
                            type="button"
                            color="grey"
                            size="small"
                            content="Cancel"
                        />
                        <sui-button
                            primary
                            size="small"
                            content="Save"
                        />
                    </div>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import moment from "moment";
    import { required } from "vuelidate/lib/validators";
    import states from "@/mixins/states";
    import ClientInput from "@/components/clientForms/client-agreement/ClientFormInput";
    import JointClientInput from "@/components/clientForms/client-agreement/JointClientFormInput";
    
    export default {
        name: "MasterForm",
        components: { ClientInput, JointClientInput },
        created() {
            const loader = this.$loading.show();
            if (this.user.profile.profile_type === 2) {
                this.getAdvisorClients({firmId: this.user.profile._firm, advisorId: this.user.id })
                    .then(() => loader.hide())
                    .catch(() => loader.hide());
            } else {
                this.$store.dispatch("getClientAgreement", this.user.id)
                    .then(() => {
                        this.formData();
                        loader.hide();
                    }).catch(() => loader.hide());
            }
        },
        data() {
            return {
                formTypeOptions: [
                    {value: "Individual Client", text: "Individual Client"},
                    {value: "Joint Clients", text: "Joint Clients"},
                ],
                mailPreferenceOptions: [
                    {key: "email", value: "Email", text: "Email"},
                    {key: "mail", value: "U.S. Mail", text: "U.S. Mail"},
                    {key: "emailAndMail", value: "Email and U.S. Mail", text: "Email and U.S. Mail"},
                ],
                employmentStatusOptions: [
                    {key: "employed", value: "Employed", text: "Employed"},
                    {key: "selfEmployed", value: "Self-Employed", text: "Self-Employed"},
                    {key: "retired", value: "Retired", text: "Retired"},
                    {key: "notEmployed", value: "Not Employed", text: "Not Employed"},
                ],
                agreement: {},
                formType: null,
                submitted: false,
                error: "",
                clientId: null,
            };
        },
        computed: {
            ...mapState(["clientAgreement", "users", "hasSubmittedClientAgreement"]),
            ...mapState({user: "userProfile"}),
            ...mapGetters(["clientAgreement"]),
            clients() {
                if (this.users) {
                    return this.users.map(user => {
                        return {
                            key: user.id,
                            value: user.id,
                            text: `${user.first_name} ${user.last_name}`
                        }
                    });
                }
                return [];
            }
        },
        watch: {
            agreement: {
                deep: true,
                handler: function () {
                    if (this.agreement) {
                         if (this.agreement.Form_Type__c) {
                             this.formType = this.agreement.Form_Type__c;
                         } else {
                             this.formType = "Individual Client";
                             this.agreement.Form_Type__c = this.formType;
                         }
                    }
                }
            },
            clientId: function () {
                if (this.clientId) {
                    const loader = this.$loading.show();
                    this.getClientAgreement(this.clientId)
                        .then(() => {
                            this.formData();
                            loader.hide()
                        }).catch(() => loader.hide());
                }
                return {};
            }
        },
        mixins: [states],
        inject: ["notyf"],
        validations() {
            if (this.formType === "Individual Client") {
                return {
                    agreement: {
                        ClientName__c: { required },
                        ClientHomeAddressStreet__c: { required },
                        ClientHomeAddressCity__c: { required },
                        ClientHomeAddressState__c: { required },
                        ClientHomeAddressZipCode__c: { required },
                        ClientMailingAddressStreet__c: { required },
                        ClientMailingAddressCity__c: { required },
                        ClientMailingAddressState__c: { required },
                        ClientMailingAddressZipCode__c: { required },
                        ClientCellPhone__c: { required },
                        ClientBusinessPhone__c: { required },
                        ClientHomePhone__c: { required },
                        ClientEmailAddress__c: { required },
                        ClientMailPreference__c: {required},
                        ClientEmploymentStatus__c: {required},
                        ClientDoB__c: {required},
                        ClientSSN__c: { required },
                        ClientDriversLicense__c: {required},
                        ClientDriversLicenseIssueState__c: {required},
                        ClientDriversLicenseIssueDate__c: {required},
                        ClientDriversLicenseExpirationDate__c: {required}
                    }
                }
            } else {
                return {
                    agreement: {
                        ClientName__c: { required },
                        ClientHomeAddressStreet__c: { required },
                        ClientHomeAddressCity__c: { required },
                        ClientHomeAddressState__c: { required },
                        ClientHomeAddressZipCode__c: { required },
                        ClientMailingAddressStreet__c: { required },
                        ClientMailingAddressCity__c: { required },
                        ClientMailingAddressState__c: { required },
                        ClientMailingAddressZipCode__c: { required },
                        ClientCellPhone__c: { required },
                        ClientBusinessPhone__c: { required },
                        ClientHomePhone__c: { required },
                        ClientEmailAddress__c: { required },
                        ClientMailPreference__c: {required},
                        ClientEmploymentStatus__c: {required},
                        ClientSSN__c: { required },
                        ClientDoB__c: {required},
                        ClientDriversLicense__c: {required},
                        ClientDriversLicenseIssueState__c: {required},
                        ClientDriversLicenseIssueDate__c: {required},
                        ClientDriversLicenseExpirationDate__c: {required},
                        JtCltName__c: { required },
                        JtCltHomeAddressStreet__c: { required },
                        JtCltHomeAddressCity__c: { required },
                        JtCltHomeAddressState__c: { required },
                        JtCltHomeAddressZipCode__c: { required },
                        JtCltEmailAddress__c: { required },
                        JtCltMailingAddressStreet__c: { required },
                        JtCltMailingAddressCity__c: { required },
                        JtCltMailingAddressState__c: { required },
                        JtCltMailingAddressZipCode__c: { required },
                        JtCltCellPhone__c: { required },
                        JtCltBusinessPhone__c: { required },
                        JtCltHomePhone__c: { required },
                        JtCltMailPreference__c: { required },
                        JtCltEmploymentStatus__c: { required },
                        JtCltDoB__c: { required },
                        JtCltSSN__c: { required },
                        JtCltDriversLicense__c: { required },
                        JtCltDriversLicenseIssueDate__c: { required },
                        JtCltDriversLicenseIssueState__c: { required },
                        JtCltDriversLicenseExpirationDate__c: { required }
                    }
                }
            }
        },
        methods: {
            ...mapActions(["getAdvisorClients", "getClientAgreement"]),
            formData() {
                if (this.clientAgreement) this.agreement = this.clientAgreement;
                else {
                    this.agreement = {
                        ClientName__c: null,
                        CompanyOrTrust__c: null,
                        TrusteeOrCorporateOfficer__c: null,
                        CompanyOrTrustTaxID__c: null,
                        ClientHomeAddressStreet__c: null,
                        ClientHomeAddressCity__c: null,
                        ClientHomeAddressState__c: null,
                        ClientHomeAddressZipCode__c: null,
                        ClientMailingAddressStreet__c: null,
                        ClientMailingAddressCity__c: null,
                        ClientMailingAddressState__c: null,
                        ClientMailingAddressZipCode__c: null,
                        ClientCellPhone__c: null,
                        ClientBusinessPhone__c: null,
                        ClientHomePhone__c: null,
                        ClientEmailAddress__c: null,
                        ClientDoB__c: null,
                        ClientSSN__c: null,
                        ClientMailPreference__c: null,
                        ClientEmploymentStatus__c: null,
                        ClientEmployer__c: null,
                        ClientOccupation__c: null,
                        ClientWorkAddressStreet__c: null,
                        ClientWorkAddressCity__c: null,
                        ClientWorkAddressState__c: null,
                        ClientWorkAddressZipCode__c: null,
                        IsClientEmployedByBrokerDealer__c: false,
                        IsClientSeniorOfficerOfPublicCompany__c: false,
                        ClientPublicCompany__c: null,
                        ClientDriversLicense__c: null,
                        ClientDriversLicenseIssueDate__c: null,
                        ClientDriversLicenseIssueState__c: null,
                        ClientDriversLicenseExpirationDate__c: null,
                        JtCltName__c: null,
                        JtCltHomeAddressStreet__c: null,
                        JtCltHomeAddressCity__c: null,
                        JtCltHomeAddressState__c: null,
                        JtCltHomeAddressZipCode__c: null,
                        JtCltMailingAddressStreet__c: null,
                        JtCltMailingAddressCity__c: null,
                        JtCltMailingAddressState__c: null,
                        JtCltMailingAddressZipCode__c: null,
                        JtCltCellPhone__c: null,
                        JtCltBusinessPhone__c: null,
                        JtCltHomePhone__c: null,
                        JtCltEmailAddress__c: null,
                        JtCltDoB__c: null,
                        JtCltSSN__c: null,
                        JtCltMailPreference__c: null,
                        JtCltEmployer__c: null,
                        JtCltOccupation__c: null,
                        JtCltEmploymentStatus__c: null,
                        JtCltWorkAddressStreet__c: null,
                        JtCltWorkAddressCity__c: null,
                        JtCltWorkAddressState__c: null,
                        JtCltWorkAddressZipCode__c: null,
                        IsJtCltEmployedByBrokerDealer__c: false,
                        IsJtCltSeniorOfficerOfPublicCompany__c: false,
                        JtCltPublicCompany__c: null,
                        JtCltDriversLicense__c: null,
                        JtCltDriversLicenseIssueState__c: null,
                        JtCltDriversLicenseIssueDate__c: null,
                        JtCltDriversLicenseExpirationDate__c: null,
                        Form_Type__c: null
                    }
                }
            },
            recordName() {
                let name =  (this.agreement.ClientName__c !== null) ? this.agreement.ClientName__c : this.agreement.JtCltName__c;
                return `${name} - Client Agreement`;
            },
            handleSave() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.notyf.error("Required field(s) not provided.");
                    return;
                }
                
                const user = this.$store.getters.userProfile;
                const loader = this.$loading.show();
                if (this.agreement.Id) {
                    this.$store.dispatch("updateClientAgreement", this.agreement)
                        .then(() => {
                            loader.hide();
                            this.$store.dispatch("getClientAgreement", user.id);
                            this.notyf.success("Client agreement updated");
                        }).catch(() => loader.hide());
                } else {
                    // Set Client agreement owner/user.
                    if (this.user.profile.profile_type === 2) {
                        this.agreement.ESWA_Portal_User_ID__c = this.clientId;
                    }
                    else {
                        this.agreement.ESWA_Portal_User_ID__c = user.id;
                    }
                    
                    this.agreement.Name = this.recordName();
                    this.agreement.Client_Agreement_Date__c = moment(Date.now()).locale("en").format("YYYY-MM-DD");
                    this.$store.dispatch("createClientAgreement", this.agreement)
                        .then(() => {
                            this.$store.dispatch("getClientAgreement", user.id)
                                .then(() => {
                                    this.$store.commit("setHasSubmittedClientAgreement", true);
                                    if(this.user.profile.is_exhibit_b_investor_profile_form_user && this.hasSubmittedClientAgreement) {
                                        this.$router.push({name: "investorProfile"});
                                    } else if (this.hasSubmittedClientAgreement) {
                                        this.$router.push({name: "formADV"});
                                    }
                                });
                            loader.hide();
                            this.notyf.success("Client agreement submitted");
                        }).catch(() => loader.hide());
                }
            },
        }
    }
</script>

<style scoped>
    .client-header, .joint-client-header {
        margin-bottom: 5px !important;
    }
    .client-header-underline, .joint-client-header-underline {
        border: 1px solid #037397;
    }
</style>
