<template>
    <sui-message info>
        <p v-if="profileType !== 2">
            <sui-icon name="info circle" />
            Please promptly notify your Advisor of any material change in life or
            financial circumstances that may change the investment objectives for managing the Portfolio.
        </p>
        <p v-else-if="profileType == 2">
            <sui-icon name="info circle" />
            Do not use Exhibit B Investor(s) Profile Form if private assets are held.
        </p>
    </sui-message>
</template>

<script>
export default {
    name: "InvestorProfileNote",
    props: {
        profileType: {
            required: true,
            type: Number
        }
    }
}
</script>

<style scoped>
</style>
