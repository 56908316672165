export default {
    methods: {
        profileTypeName(profileTypeId) {
            const profileTypes = {
                systemAdministrator: 'System Administrator',
                financialAdvisor: 'Financial Advisor',
                standardUser: 'Standard User',
                Client: 'Client',
            }
            let profileTypeName = null;
            switch (profileTypeId) {
                case 1:
                    profileTypeName =profileTypes.systemAdministrator;
                    break;
                case 2:
                    profileTypeName =profileTypes.financialAdvisor;
                    break;
                case 3:
                    profileTypeName =profileTypes.standardUser;
                    break;
                case 4:
                    profileTypeName =profileTypes.Client;
                    break;
                default:
                    break;
            }
            return profileTypeName
        }
    }
}
