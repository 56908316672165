<template>
    <div>
        <div>
            <sui-container>
                <div class="manager-header">
                    <div class="title">
                        <span><h2>Firms</h2></span>
                    </div>
                    <div class="search-filter">
                        <sui-input
                            placeholder="Search firm list..."
                            icon="search"
                            icon-position="left"
                            v-model="searchPhrase"
                        />
                    </div>
                    <div class="actions">
                        <sui-button
                            basic
                            size="tiny"
                            content="New Firm"
                            color="blue"
                            @click.native="handleNewFirm"
                        />
                    </div>
                </div>
            </sui-container>
        </div>
        <sui-container>
            <FirmList
                :firms="firms"
                @getFirm="getFirm"
                @toggleModal="toggleModal"
                @showDeleteAlert="showDeleteAlert"
            />
            <FirmModal
                :isOpen="isOpen"
                :isNew="isNew"
                :firm="firm"
                :toggle-modal="toggleModal"
                :reset-is-new="resetIsNew"
            />
            <DeleteAlert ref="deleteAlert" @handleDelete="deleteFirm" />
        </sui-container>
    </div>
</template>

<script>
    import FirmList from "@/components/setup/firmManager/FirmList";
    import DeleteAlert from "@/components/common/DeleteWarningModal";
    import FirmModal from "@/components/setup/firmManager/FirmModal";
    export default {
        name: "Index",
        components: {
            FirmModal,
            DeleteAlert,
            FirmList
        },
        beforeCreate() {
            const loader = this.$loading.show();
            this.$store.dispatch('getAllFirms')
                .then(() => {
                    loader.hide();
                }).catch();
        },
        data() {
            return {
                searchPhrase: null,
                firm: {
                    name: null,
                    abbreviation: null,
                    street: null,
                    city: null,
                    state: null,
                    zip_code: null,
                },
                isOpen: false,
                isNew: false,
            }
        },
        inject: ['notyf'],
        computed: {
            firms() {
                const firmData =  this.$store.getters.firms;
                if (firmData) {
                    if (this.searchPhrase) {
                        return firmData.filter(firm => {
                            if (firm.name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                                firm.abbreviation.toLowerCase().includes(this.searchPhrase.toLowerCase())
                            ) return firm;
                        });
                    } else {
                        return  firmData;
                    }
                }
                return [];
            },
        },
        methods: {
            toggleModal() {
                this.isOpen = !this.isOpen;
            },
            resetIsNew() {
                this.isNew = false;
            },
            handleNewFirm() {
                this.isNew = true;
                this.firm = Object.assign({}, {...this.firm,
                    name: null,
                    abbreviation: null,
                    street: null,
                    city: null,
                    state: null,
                    zip_code: null,
                })
                console.log(this.isNew);
                this.toggleModal();
            },
            getFirm(firmId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getFirm', firmId)
                    .then(() => {
                        loader.hide();
                        this.firm = JSON.parse(JSON.stringify(this.$store.getters.firm));
                        this.toggleModal();
                    }).catch();
            },
            deleteFirm(firmId) {
                const loader = this.$loading.show();
                this.$store.dispatch('deleteFirm', firmId)
                    .then(() => {
                        this.$store.dispatch('getAllFirms');
                        loader.hide();
                        this.$refs.deleteAlert.toggleIsOpen();
                        this.notyf.success('Firm deleted successfully.');
                    }).catch()
            },
            showDeleteAlert(firm) {
                this.$refs.deleteAlert.toggleIsOpen(firm.id, firm.name);
            },
        }
    }
</script>

<style scoped>
    .manager-header {
        display: flex;
        justify-content: space-between;
        margin-top: 1em !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        margin-bottom: 2em;
    }
</style>
