<template>
    <div>
        <div v-if="resourceList">
            <MasterResourceList :resource-list="resourceList" />
        </div>
        <div v-if="resourceList.length === 0">
            <NoResourceFoundMessage  :resource-name="resourceName"/>
        </div>
    </div>
</template>

<script>
    import MasterResourceList from "@/components/resourceManager/MasterResourceList";
    import NoResourceFoundMessage from "@/components/resourceManager/NoResourceFoundMessage";
    export default {
        name: "VideoResourceList",
        components: {
            MasterResourceList,
            NoResourceFoundMessage
        },
        data() {
            return {
                resourceName: 'Video',
                resourceList: [],
            }
        }
    }
</script>

<style scoped>

</style>
