<template>
    <sui-form size="small">
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column>
                    <sui-form-field>
                        <label>Fee Schedule Name</label>
                        <input
                            type="text"
                            name="fee-schedule-name"
                            placeholder="Fee Schedule Name"
                            v-model="feeSchedule.name"
                        />
                    </sui-form-field>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-form>
</template>

<script>
export default {
    name: "MasterForm",
    props: {
        feeSchedule: {type: Object},
        firms: {type: Array},
        financialAdvisors: {type: Array},
        reset: {type: Function},
        isCreate: {type: Boolean}
    },
    inject: ['notyf'],
    methods: {
        save() {
            const loader = this.$loading.show();
            if (this.isCreate) {
                this.$store.dispatch('createFeeSchedule', this.feeSchedule)
                    .then(() => {
                        loader.hide();
                        this.$store.dispatch('getAllFeeSchedules');
                        this.notyf.success('Fee schedule created successfully.');
                        this.$emit('toggleIsCreate');
                        this.$emit('toggleModal');
                    }).catch();
                this.reset();
                this.$emit('toggleIsUpdate');
            } else {
                this.$store.dispatch('updateFeeSchedule', this.feeSchedule)
                    .then(() =>{
                        loader.hide();
                        this.$store.dispatch('getAllFeeSchedules');
                        this.notyf.success('Fee schedule updated successfully.');
                        this.$emit('toggleModal');
                    }).catch()
                this.reset();
            }
        },
    }
}
</script>

<style scoped>
</style>
