<template>
    <div class="email-settings">
        <sui-form size="small">
            <sui-grid centered>
                <sui-grid-row>
                    <sui-grid-column centered width="six">
                        <h2 is="sui-header" class="email-form-header">Email</h2>
                        <span>Your current email address is <strong>{{ user.email }}</strong>.</span>
                    </sui-grid-column>
                </sui-grid-row>
                <sui-grid-row>
                    <sui-grid-column width="six">
                        <sui-form-field>
                            <input type="email" placeholder="Email" v-model.trim="email" />
                        </sui-form-field>
                        <sui-grid-column centered width="eight">
                            <sui-button
                                type="button"
                                basic
                                color="blue"
                                content="Update email"
                                size="small"
                                @click="save"
                            />
                        </sui-grid-column>
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>
        </sui-form>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

    export default {
        name: "EmailForm",
        data() {
            return {
               email: null,
            }
        },
        computed: {
            ...mapState({user: "userProfile"}),
        },
        inject: ["notyf"],
        methods: {
            ...mapActions(["changeEmail"]),
            save() {
                const loader = this.$loading.show();
                this.changeEmail({ email: this.email })
                    .then(response => {
                        console.log(response);
                        loader.hide();
                        this.notyf.success("Email changed successfully")
                    })
                    .catch(() => loader.hide());
            }
        },
    }
</script>

<style scoped>
    .email-form-header {
        font-weight: lighter !important;
        color: #667d88 !important;
    }
</style>
