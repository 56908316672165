<template>
    <sui-container style="margin: 1em">
        <sui-grid>
            <sui-grid-row>
                <sui-grid-column>
                    <sui-grid-column>
                        <h3 class="ui  floated header" style="font-weight: lighter">Investment Strategies</h3>
                    </sui-grid-column>
                    <sui-button
                        basic
                        floated="right"
                        size="small"
                        content="New Investment Strategy"
                        color="grey"
                        @click="displayInvestmentStrategyForm"
                    />
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
        <sui-table single-line selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                        {{ column }}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="investmentStrategies">
                <sui-table-row v-for="(investmentStrategy, index) in investmentStrategies" :key="index">
                    <sui-table-cell>{{ index + 1 }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Financial_Account_Name__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Custodian_Account_Number__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Account_Master_Code__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Custodian__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Relationship_Name__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Investment_Model__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.Status__c }}</sui-table-cell>
                    <sui-table-cell>{{ investmentStrategy.CreatedDate | date }}</sui-table-cell>
                    <sui-table-cell>
                        <sui-dropdown simple>
                            <sui-dropdown-menu>
                                <sui-dropdown-item @click="handleView(investmentStrategy)">View</sui-dropdown-item>
                                <sui-dropdown-item @click="handleEdit(investmentStrategy)">Edit</sui-dropdown-item>
                            </sui-dropdown-menu>
                        </sui-dropdown>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
        <Modal v-if="investmentStrategy"
            :open="open"
            :investmentStrategy="investmentStrategy"
            :toggle="toggleModal"
            @toggle-modal="toggleModal"
        />
    </sui-container>
</template>

<script>
    import Modal from './Modal';
    
    export default {
        name: "Index",
        components: {
            Modal,
        },
        created() {
            const loader =  this.$loading.show();
            const user = this.$store.getters.userProfile;
            this.$store.dispatch('getClientInvestmentStrategyList', user.id)
                .then( () => {
                    this.investmentStrategyList = this.$store.getters.investmentStrategyList;
                    loader.hide();
                });
        },
        data() {
            return {
                columns: [
                    'Account Name',
                    'Account Number',
                    'Account Master Code',
                    'Custodian',
                    'Relationship Name',
                    'Investment Model',
                    'Status',
                    'Submitted Date'
                ],
                investmentStrategyList: null,
                investmentStrategy: null,
                open: false,
            }
        },
        computed: {
             investmentStrategies(){
                 return this.$store.getters.investmentStrategyList;
             },
        },
        methods: {
            toggleModal() {
                this.open = !this.open;
            },
            displayInvestmentStrategyForm() {
                this.$router.push({name: 'submitInvestmentStrategy'})
            },
            handleView(investmentStrategy) {
                const loader = this.$loading.show();
                this.$store.dispatch('getInvestmentStrategy', investmentStrategy.Id)
                    .then( () => {
                        this.investmentStrategy = this.$store.getters.investmentStrategy;
                        loader.hide();
                    }).catch();
                this.open = !this.open;
                this.investmentStrategy = null;
            },
            handleEdit(investmentStrategy) {
                const loader = this.$loading.show();
                this.$store.dispatch('getInvestmentStrategy', investmentStrategy.Id)
                    .then( () => {
                        this.investmentStrategy = this.$store.getters.investmentStrategy;
                        loader.hide();
                    }).catch();
                this.open = !this.open;
                this.investmentStrategy = null;
            },
        }
    }
</script>

<style scoped>

</style>
