<template>
    <sui-form size="medium">
        <div class="section portfolio-information">
            <div class="section-header">
                <h5 is="sui-header">PORTFOLIO INFORMATION</h5>
            </div>
            <div class="section-content">
                <sui-grid>
                    <sui-grid-row>
                        <sui-grid-column :width="8">
                            <sui-form-field required>
                                <label>Account Name</label>
                                <input type="text"  name="account-name" placeholder="Account Name" v-model.trim="$v.cashRequest.Financial_Account_Name__c.$model"
                                       :class="{ 'is-invalid': isSubmitted && $v.cashRequest.Financial_Account_Name__c.$error }" />
                                <div v-if="isSubmitted && !$v.cashRequest.Financial_Account_Name__c.required" class="invalid-feedback">
                                   Enter the account name.
                                </div>
                            </sui-form-field>
                            <sui-form-field required>
                                <label>Account Number</label>
                                <input type="text"  name="account-number" placeholder="Account Number" v-model.trim="$v.cashRequest.Custodian_Account_Number__c.$model"
                                       :class="{ 'is-invalid': isSubmitted && $v.cashRequest.Custodian_Account_Number__c.$error }" />
                                <div v-if="isSubmitted && !$v.cashRequest.Custodian_Account_Number__c.required" class="invalid-feedback">
                                    Enter the account number.
                                </div>
                            </sui-form-field>
                            <sui-form-field required>
                                <label>Custodian</label>
                                <input type="text"  name="custodian" placeholder="Custodian" v-model.trim="$v.cashRequest.Custodian__c.$model"
                                       :class="{ 'is-invalid': isSubmitted && $v.cashRequest.Custodian__c.$error }" />
                                <div v-if="isSubmitted && !$v.cashRequest.Custodian__c.required" class="invalid-feedback">
                                    Enter the custodian.
                                </div>
                            </sui-form-field>
                        </sui-grid-column>
                        <sui-grid-column :width="8">
                            <sui-form-field>
                                <label>Advisor</label>
                                <input type="text" name="advisor" placeholder="Advisor" v-model="cashRequest.Advisor__c"/>
                            </sui-form-field>
                            <sui-form-field required>
                                <label>Relationship Name</label>
                                <input type="text" name="relationship-name" placeholder="Relationship Name" v-model.trim="$v.cashRequest.Relationship_Name__c.$model"
                                       :class="{ 'is-invalid': isSubmitted && $v.cashRequest.Relationship_Name__c.$error }" />
                                <div v-if="isSubmitted && !$v.cashRequest.Relationship_Name__c.required" class="invalid-feedback">
                                    Enter the relationship name.
                                </div>
                            </sui-form-field>
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>
            </div>
        </div>
        <div class="section cash-request">
            <div class="section-header">
                <h5 is="sui-header">CASH REQUEST</h5>
            </div>
            <div class="section-content">
                <sui-grid>
                    <sui-grid-row>
                        <sui-grid-column :width="8">
                            <sui-form-field required>
                                <label>Cash Request Type</label>
                                <sui-dropdown
                                    placeholder="Cash Request Type"
                                    selection
                                    direction="downward"
                                    :options="cashRequestTypeOptions"
                                    v-model.trim="$v.cashRequest.Cash_Request_Type__c.$model"
                                    :class="{ 'is-invalid': isSubmitted && $v.cashRequest.Cash_Request_Type__c.$error }"
                                />
                                <div v-if="isSubmitted && !$v.cashRequest.Cash_Request_Type__c.required" class="invalid-feedback">
                                    Enter the cash request type.
                                </div>
                            </sui-form-field>
                        </sui-grid-column>
                        <sui-grid-column :width="8">
                            <sui-form-field required>
                                <label>Amount</label>
                                <currency-input name="amount" placeholder="Amount" v-model.trim="$v.cashRequest.Amount__c.$model"
                                                :class="{ 'is-invalid': isSubmitted && $v.cashRequest.Amount__c.$error }"  />
                                <div v-if="isSubmitted && !$v.cashRequest.Amount__c.required" class="invalid-feedback">
                                    Enter an amount.
                                </div>
                            </sui-form-field>
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>
                <sui-grid>
                    <sui-grid-row>
                        <sui-grid-column>
                            <sui-form-field>
                                <label>Notes</label>
                                <textarea name="cash-management-notes" rows="3" v-model="cashRequest.Cash_Request_Notes__c"></textarea>
                            </sui-form-field>
                        </sui-grid-column>
                    </sui-grid-row>
                </sui-grid>
            </div>
        </div>
    </sui-form>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    
    export default {
        name: "CashRequestForm",
        props: {
            cashRequest: {type: Object, default: () => ({
                    Financial_Account_Name__c: null,
                    Custodian_Account_Number__c: null,
                    Custodian__c: null,
                    Advisor__c: null,
                    Relationship_Name__c: null,
                    Cash_Request_Type__c: null,
                    Amount__c: null,
                    Cash_Request_Notes__c: null,
                })
            },
            title: {type: String, default: ''}
        },
        inject: ['notyf'],
        validations: {
            cashRequest: {
                Financial_Account_Name__c: { required },
                Custodian_Account_Number__c: { required },
                Custodian__c: { required },
                Relationship_Name__c: { required },
                Cash_Request_Type__c: { required },
                Amount__c: { required }
            }
        },
        data() {
            return {
                cashRequestTypeOptions: [
                    {key: 'raiseCash', value: 'Raise Cash', text: 'Raise Cash'},
                    {key: 'investCash', value: 'Invest Cash', text: 'Invest Cash'},
                ],
                isSubmitted: false
            }
        },
        methods: {
            cancel() {
                this.$router.push({name: 'cashRequestManager'});
            },
            save() {
                this.isSubmitted = true;
    
                this.$v.$touch();
    
                if (this.$v.$invalid) {
                    this.notyf.error("Required field(s) not provided.");
                    return;
                }
                
                const user = this.$store.getters.userProfile;
                
                this.cashRequest.Submitter_ID__c = user.id;
                this.cashRequest.ESWA_Portal_Owner_ID__c = user.id;
                this.cashRequest.Submitter_Email__c = user.email;
                this.cashRequest.Submitter_First_Name__c = user.first_name;
                this.cashRequest.Submitter_Last_Name__c = user.last_name;
                
                const loader = this.$loading.show();
                this.$store.dispatch('saveCashRequest', this.cashRequest)
                    .then( () => {
                        this.isSubmitted = false;
                        loader.hide();
                        this.$router.push({name: 'cashRequestManager'});
                        this.notyf.success('Cash request submitted');
                    })
                    .catch();
            },
            update() {
                const loader = this.$loading.show();
                this.$store.dispatch('updateCashRequest', this.cashRequest)
                    .then( () => {
                        this.isSubmitted = false;
                        const user = this.$store.getters.userProfile;
                        this.$store.dispatch('getClientCashRequestList', user.id);
                        loader.hide();
                        this.notyf.success('Cash request updated');
                    })
                    .catch();
            },
        }
    }
</script>

<style scoped>
    .section {
        border-top: 3px solid #037397;
        margin: 2em 0;
    }
    .section-header {
        margin: .5em 0;
    }
    .section-content {
    }
    .invalid-feedback {
        margin-top: 1px;
        margin-left: 1px;
        color: #D63301;
    }
</style>
