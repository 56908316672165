<template>
     <div class="main-container">
         <div style="margin-bottom: 4em">
             <div class="form-header">
                 <div>
                     <sui-button
                         basic
                         size="mini"
                         color="blue"
                         floated="right"
                         content="Return to Attestations"
                         @click="$router.push({name: 'attestationManager'})"
                     />
                 </div>
             </div>
             <div>
                 <sui-container class="attestation-form-container">
                     <MasterForm
                         ref="attestationForm"
                         :firm="firm"
                         :is-new-attestation="isNewAttestation"
                         :attestation="defaultAtt"
                     />
                 </sui-container>
             </div>
             <div class="form-action-buttons">
                 <sui-container>
                     <sui-button
                         primary
                         type="button"
                         size="small"
                         floated="right"
                         content="Submit"
                         @click.native="handleSubmit"
                     />
                     <sui-button
                         basic
                         type="button"
                         size="small"
                         color="grey"
                         floated="right"
                         content="Cancel"
                         @click.native="handleCancel"
                     />
                 </sui-container>
             </div>
         </div>
     </div>
</template>

<script>
    import MasterForm from "@/components/compliance/AttestationManager/Forms/MasterForm";
    import quarterlyAttestationUtils from "@/mixins/quarterlyAttestationUtils";
    export default {
        name: "NewAttestation",
        components: {
            MasterForm,
        },
        mixins: [quarterlyAttestationUtils],
        beforeCreate() {
            const loader = this.$loading.show();
            this.$store.dispatch('getMostRecentQuarterlyAttestation', this.$route.params.id)
                .then(() => {
                    const user = this.$store.getters.userProfile;
                    this.$store.dispatch('getFirm', user.profile._firm);
                    this.$store.dispatch("getSupervisedPerson", user.id);
                    loader.hide();
                })
        },
        beforeUpdate() {
            this.setAttestation();
            this.setPersonalSecurities();
            this.setOutsideBusinessActivities();
            this.setPoliticalContributions();
        },
        data() {
            return {
                isNewAttestation: true,
                defaultAtt: {
                    First_Name__c: null,
                    Middle_Name__c: null,
                    Last_Name__c: null,
                    Job_Title__c: null,
                    Employment_Date__c: null,
                    Work_Phone__c: null,
                    Home_Phone__c: null,
                    Mobile_Phone__c: null,
                    Work_Email__c: null,
                    Personal_Email__c: null,
                    Address_Line_1__c: null,
                    Address_Line_2__c: null,
                    City__c: null,
                    State__c: null,
                    Zip_Code__c: null,
                    CRD_Number__c: null,
                    CFP__c: false,
                    ChFC__c: false,
                    CFA__c: false,
                    PFS__c: false,
                    CIC__c: false,
                    Series_7__c: false,
                    Series_24__c: false,
                    Series_65__c: false,
                    Series_66__c: false,
                    States_Registered__c: [],
                    States_Clients_Reside__c: [],
                    Broker_Dealer__c: null,
                    Solicitor_Referral_Relationships__c: null,
                    Non_Advisory_Compensation_Arrangements__c: null,
                    Other_Qualifications__c: null,
                    Insurance_License__c: null,
                    LinkedIn__c: false,
                    Facebook__c: false,
                    Twitter__c: false,
                    YouTube__c: false,
                    Other_Social_Media__c: null,
                    BusinessFinancialOrPersonalRelationships__c: null,
                    ServesAs__c: null,
                    Is_Covered_Associate__c: false,
                    Is_Access_Person__c: false,
                    Has_Reported_Previous_Securities__c: false,
                    Has_Received_Gratuities__c: false,
                    Code_of_Ethics_Attestation_Date__c: null,
                    IA_Manual_Attestation_Date__c: null,
                    Is_Compliant__c: false,
                    hasNotUsedPersonalDevice__c: false,
                    Firm_Tk__c: this.$store.getters.firm.firm_tk,
                    Firm_Name__c: this.$store.getters.firm.name,
                    Owner_Tk__c: this.$store.getters.userProfile.id,
                  
                }
            }
        },
        computed: {
            firm() {
                const firm = this.$store.getters.firm;
                if (firm) {
                    return {
                        name: firm.name,
                        abbreviation: ''
                    }
                }
                return {};
            },
        },
        methods: {
            setAttestation() {
                if (this.$store.getters.quarterlyAttestation) {
                    let  attData = JSON.parse(JSON.stringify(this.$store.getters.quarterlyAttestation));
                    if (attData) {
                        delete attData.Personal_Securities__r;
                        delete attData.Outside_Business_Activities__r;
                        delete attData.Political_Contributions__r;
                        delete attData.Firm__r;
                        
                        // Parse and add state where advisor is registered and states where clients reside.
                        this.defaultAtt = Object.assign({}, this.defaultAtt,
                            {...attData, States_Registered__c: this.statesRegistered(attData.States_Registered__c),
                            States_Clients_Reside__c: this.statesClientsReside(attData.States_Clients_Reside__c)});
                    }
                } else {
                    return this.defaultAtt;
                }
                
            },
            handleCancel() {
                 this.$router.push({name: 'attestationManager'});
            },
            handleSubmit() {
                this.$refs.attestationForm.handleSubmission();
            }
        }
    }
</script>

<style scoped>
    .main-container {
        padding-bottom: 2em;
    }
    .form-header {
        height: 20px;
        margin-right: 2em;
        margin-top: 1em;
        margin-bottom: 1em;
    }
    .attestation-form-container {
        margin-top: .5em;
        padding: 2em;
        border-style: solid;
        border-width: 1px;
        border-color: #f2f3f4;
    }
    .form-action-buttons {
        margin-top: 1em;
    }
</style>
