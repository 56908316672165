<template>
    <div class="_524cbd31">
        <div class="_6f35a76d">
            <header class="top-nav" id="topNavBar">
                <router-link :to="{name: 'home'}" exact>
                    <sui-image :src="logo" size="small" />
                </router-link>
                <div v-if="this.$route.name !== 'home'">
                    <div class="dropdown" @click="$router.push({name: 'home'})">
                        <div class="dropbtn">
                            <div class="_BC7CF055">Home</div>
                        </div>
                    </div>
                    <div v-if="profileType !== profileTypes.CLIENT">
                        <div class="dropdown" @click="$router.push({name: 'lifeImpact'})">
                            <div class="dropbtn">
                                <div class="_BC7CF055">Life Impact&reg;</div>
                            </div>
                            <div class="dropdown-content">
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    Compliance
                                    <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <router-link :to="{name: 'attestationManager'}">Quarterly Attestations</router-link>
                                <router-link :to="{name: 'supervisedPersonManager'}" v-if="user.profile.is_super_cco">
                                    Supervised Persons
                                </router-link>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    <span>
                                        Investments <font-awesome-icon icon="angle-down" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <router-link :to="{name: 'cashRequestManager'}">Cash Requests</router-link>
                                <router-link :to="{name: 'investmentStrategyManager'}">Investment Strategies</router-link>
                                <template v-if="user.profile.is_investments_user">
                                    <router-link :to="{name: 'firmFeeScheduleManager'}">Firm Fee Schedules</router-link>
                                    <router-link :to="{name: 'firmModelManager'}">Firm Models</router-link>
                                    <router-link :to="{name: 'feeScheduleManager'}">Fee Schedule List</router-link>
                                    <router-link :to="{name: 'modelManager'}">Model List</router-link>
                                </template>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn" >
                                    Forms <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <router-link :to="{name: 'clientAgreement'}">Client Agreement Profile</router-link>
                                <span v-if="user.profile.profile_type === 2">
                                    <router-link :to="{name: 'investorProfileView'}">Exhibit B - Investor Profile</router-link>
                                </span>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    <span>
                                        Training Resources <font-awesome-icon icon="angle-down" />
                                    </span>
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <router-link :to="{name: 'complianceResourceList'}">Compliance</router-link>
                                <router-link :to="{name: 'faqs'}">Frequently Asked Questions</router-link>
                                <router-link :to="{name: 'investmentsResourceList'}">Investments</router-link>
                                <router-link :to="{name: 'operationsResourceList'}">Operations</router-link>
                                <router-link :to="{name: 'videosResourceList'}">Videos</router-link>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    Support <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <router-link :to="{name: 'ticketManager'}">Help Requests</router-link>
                                <a :href="provisionsGroupSalesforceCommunityPortal" target="_blank" class="item">
                                    Salesforce Help
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <router-link :to="{name: 'ticketManager'}">Prayer Requests</router-link>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    Links
                                    <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <a class="item" href="https://account.box.com/login" target="_blank">
                                    Box
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://login.bdreporting.com/" target="_blank">
                                    Black Diamond
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://my.dimensional.com/login/" target="_blank">
                                    Dimensional Fund Advisors
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://www.finametrica.com/login.aspx" target="_blank">
                                    FinaMetrica
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://webaccess.nationaladvisorstrust.com/advisordesk/UserLogin.aspx" target="_blank">
                                    National Advisors Trust Company
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://login.salesforce.com/" target="_blank">
                                    Salesforce
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://invest.ameritrade.com/grid/p/login" target="_blank">
                                    TD Ameritrade
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <template v-else>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn" >
                                    Forms <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <router-link :to="{name: 'clientAgreement'}">Client Agreement Profile</router-link>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn" >
                                    Document Vault
                                    <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <a href="https://eversourcewa.account.box.com/login" target="_blank" class="item">
                                    <span>Box</span>
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a href="" class="item">Investment Policy Statement</a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    Account Management
                                    <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <a class="item" href="https://bd3.bdreporting.com" target="_blank">
                                    Black Diamond
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                                <a class="item" href="https://www.tdameritrade.com/home.page" target="_blank">
                                    TD Ameritrade
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                            </div>
                        </div>
                        <div class="dropdown">
                            <div>
                                <div class="dropbtn">
                                    Financial Planning
                                    <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                            <div class="dropdown-content">
                                <a class="item" href="https://wealth.emaplan.com/ema/SignIn" target="_blank">
                                    <span>eMoney Advisor</span>
                                    <font-awesome-icon icon="external-link-alt" />
                                </a>
                            </div>
                        </div>
                        <div class="dropdown" @click="$router.push({name: 'lifeImpact'})">
                            <div>
                                <div class="dropbtn">
                                    Life Impact
                                    <font-awesome-icon icon="angle-down" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="dropdown" @click="$router.push({name: 'calendar'})">
                        <div>
                            <div class="dropbtn">Calendar</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="dropdown profile">
                        <div>
                            <div class="dropbtn">
                                <font-awesome-icon icon="user-circle" />
                                {{ displayName }}
                            </div>
                        </div>
                        <div class="dropdown-content profile-dropdown-content">
                            <router-link :to="{ name: 'Profile'}">Settings</router-link>
                            <a @click="logout">Log Out</a>
                        </div>
                    </div>
                    <div v-if="this.$route.name !== 'home'">
                        <div class="dropdown setup" v-if="profileType === profileTypes.FINANCIAL_ADVISOR || profileType === profileTypes.ADMINISTRATOR">
                            <div>
                                <div class="dropbtn">
                                    <font-awesome-icon icon="cog" />
                                </div>
                            </div>
                            <div class="dropdown-content setup-dropdown-content">
                                <a href="#" @click="$router.push({name: 'firmManager'})" class="item" v-if="user.profile.is_super_admin">
                                    <font-awesome-icon icon="building" style="margin-left: 2px"/> Firm Management
                                </a>
                                <a href="#" @click="$router.push({name: 'userManager'})" class="item">
                                    <font-awesome-icon icon="users" />
                                    <span style="margin-left: 2px">User Management</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="icon" @click="resizeNavBar()" style="font-size:15px;">&#9776;</a>
            </header>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import profileTypes from "../../profileTypes";
    
    export default {
        name: "NavigationBar",
        beforeMount() {
            this.profileType = this.user.profile.profile_type;
            this.profileTypes = profileTypes;
        },
        data() {
            return {
                profileType: null,
                profileTypes: [],
                logos: [
                    {firmTk: 1, name: require("@/assets/logos/navbar/eversource-wealth-advisors.svg")},
                    {firmTk: 2, name: require("@/assets/logos/navbar/bmss-wesson.svg")},
                    {firmTk: 36, name: require("@/assets/logos/navbar/miller-wealth-management.svg")}
                ],
                provisionsGroupSalesforceCommunityPortal: "https://provisionsgroup.force.com/EverSourceWealth/s/login/"
            };
        },
        computed: {
            ...mapState({ user: "userProfile" }),
            displayName() {
                if(this.user) {
                    if (this.user.first_name) {
                        return this.user.first_name;
                    } else {
                        return this.user.email;
                    }
                }
                return "";
            },
            logo() {
                let image = require("@/assets/logos/horizontal.svg");
                if (this.user) {
                    const tk = this.user.profile._firm;
                    for (let i = 0; i <= this.logos.length; i++) {
                        if (tk === this.logos[i].firmTk) {
                            image = this.logos[i].name;
                            break;
                        }
                    }
                    return image;
                }
                return image;
            }
        },
        methods: {
            resizeNavBar() {
                let x = document.getElementById("topNavBar");
                if (x.className === "top-nav") {
                    x.className += " responsive";
                } else {
                    x.className = "top-nav";
                }
            },
            logout() {
                let loader = this.$loading.show();
                this.$store.dispatch('logout')
                    .then(() => {
                        loader.hide();
                        this.$router.push({name: 'logout'});
                    });
            }
        }
    }
</script>

<style scoped>
    ._524cbd31 {
        background-color: white;
        border-bottom: thin solid #f2f2f2;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 9999;
    }
    ._6f35a76d {
        padding: 0 40px 0 5px;
    }
    .top-nav {
        align-items: center;
        min-height: 20px;
        overflow: hidden;
    }
    .top-nav a {
        float: left;
        display: flex;
        color: #373D3F;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-size: 14px;
    }
    .active {
        background-color: #4CAF50;
        color: white;
    }
    .top-nav .icon {
        display: none;
    }
    .dropdown {
        float: left;
        overflow: hidden;
    }
    .dropdown:hover {
        cursor: pointer;
    }
    .dropdown .dropbtn {
        border: none !important;
        outline: none !important;
        padding: 14px  16px !important;
        margin-top: 11px !important;
        line-height: 1.6 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        background-color: inherit !important;
        color: #414141 !important;
    }
    .dropdown-content {
        display: none;
        position: absolute;
        border-radius: 4px;
        background-color: white;
        min-width: 120px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 9999;
    }
    .setup, .profile {
        float: right !important;
    }
    .dropdown-content a {
        float: none;
        color: #414141 !important;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }
    .dropdown-content a:hover {
        background-color: #f4f4f4;
        color: rgba(0,0,0,.87);
    }
    .dropdown:hover .dropdown-content {
        display: block;
    }
    @media screen and (max-width: 600px) {
        .top-nav a:not(:first-child), .dropdown .dropbtn {
            display: none;
        }
        .top-nav a.icon {
            float: right;
            display: block;
        }
    }
    @media screen and (max-width: 600px) {
        .top-nav.responsive {
            position: relative;
        }
        .top-nav.responsive .icon {
            position: absolute;
            right: 0;
            top: 0;
        }
        .top-nav.responsive a {
            float: none;
            display: block;
            text-align: left;
        }
        .top-nav.responsive .dropdown {
            float: none;
        }
        .top-nav.responsive .dropdown-content {
            position: relative;
        }
        .top-nav.responsive .dropdown .dropbtn {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
</style>
