<template>
    <CountDown :countDownDate="countDownDate"/>
</template>

<script>
    import CountDown from "../common/CountDown";
    export default {
        name: "IndexPage",
        components: {
            CountDown
        },
        data() {
            return {
                countDownDate: '2021-1-31 00:00:00',
            }
        }
    }
</script>

<style scoped>
</style>
