<template>
    <div  class="main-container">
        <sui-container>
            <sui-grid class="ui right aligned grid">
                <div class="center aligned two column row">
                    <div class="center aligned two column row">
                        <div class="right floated left aligned column">
                            <h2 class="ui left floated header">Users</h2>
                        </div>
                    </div>
                    <sui-grid-column>
                        <sui-button
                            basic
                            color="blue"
                            size="mini"
                            content="New User"
                            @click="handleCreate"
                        />
                    </sui-grid-column>
                </div>
            </sui-grid>
            <sui-grid>
                <sui-grid-row>
                    <sui-grid-column :width="6">
                        <sui-input
                            placeholder="Enter user name to search list..."
                            icon="search"
                            icon-position="left"
                            fluid
                            v-model="searchPhrase"
                        />
                    </sui-grid-column>
                    <sui-grid-column :width="10">
                        <div class="table-filter">
                            <sui-dropdown
                                placeholder="Filter"
                                text="Filter"
                                selection
                                :options="userStatusFilterOptions"
                                v-model="userStatusFilterVal"
                            />
                            <sui-dropdown
                                v-if="authUser.profile.is_super_admin"
                                placeholder="Firm"
                                text="Firm"
                                selection
                                floating
                                :options="firms"
                                v-model="firmFilterVal"
                                :disabled="disableFirmMenu"
                            />
                        </div>
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>
            <List
                :users="users"
                :searchPhrase="searchPhrase"
                @handleEdit="handleEdit"
            />
            <UserModal
                :is-open="isOpen"
                :modal-action="modalAction"
                :toggle-modal="toggleModal"
                :get-users="getUserList"
            />
        </sui-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import profileTypeName from "../../mixins/profileTypeName";
import List from "@/components/userManger/UserList.vue"
import UserModal from "@/components/userManger/UserModal.vue";
export default {
    name: "Index",
    components: {
        List,
        UserModal
    },
    created() {
        this.getUserList();
    },
    data() {
        return {
            userStatusFilterOptions: [
                {key: 'all', value: "all", text: "All"},
                {key: 'active', value: "active", text: "Active"},
                {key: 'inactive', value: "inactive", text: "Inactive"},
            ],
            userStatusFilterVal: "all",
            firmFilterVal: null,
            isOpen: false,
            searchPhrase: null,
            modalAction: null
        }
    },
    mixins: [profileTypeName],
    inject: ['notyf'],
    computed: {
        ...mapGetters({authUser: "userProfile"}),
        ...mapGetters(["user"]),
        users() {
            const userArr = this.filterUserList();
            if(this.searchPhrase) {
                return userArr.filter(user => {
                    if (user.first_name.toLowerCase().includes(this.searchPhrase.toLowerCase()) ||
                        user.last_name.toLowerCase().includes(this.searchPhrase.toLowerCase())
                    ) return user;
                });
            } else {
                return userArr;
            }
        },
        firms() {
            const firms = this.$store.getters.firms;
            if (firms) {
                let options = firms.map(firm => {
                    return {key: firm.id, value: firm.id, text: firm.name};
                });
                options.unshift({key: 'null', value: '', text: 'All Firms'})
                return options;
            }
            return [];
        },
        disableFirmMenu() {
            const isSuperAdmin = this.authUser.profile.is_super_admin;
            return (isSuperAdmin !== true);
        }
    },
    methods: {
        ...mapActions([
            "getAllUsers", "getAllFirms", "getProfileTypes", "getUsers", "getFirmUsers", "getFirmAdvisors", "getUser",
            "getAdvisorClients"
        ]),
        getUserList() {
            const loader = this.$loading.show()
            if(this.authUser.profile.profile_type === 1 && this.authUser.profile.is_super_admin) {
                Promise.all([
                    this.getAllUsers(),
                    this.getAllFirms(),
                    this.getFirmAdvisors(this.authUser.profile._firm),
                    this.getProfileTypes()
                ]).finally(() => {
                    loader.hide();
                });
            } else if (this.authUser.profile.profile_type === 1) {
                Promise.all([
                    this.getFirmAdvisors(this.authUser.profile._firm),
                    this.getFirmUsers({firmId: this.authUser.profile._firm})
                ]).finally(() => {
                    loader.hide();
                });
            } else if (this.authUser.profile.profile_type === 2) {
                this.getAdvisorClients({
                    firmId: this.authUser.profile._firm,
                    advisorId: this.authUser.id
                }).then(() => {
                    loader.hide();
                });
            }
        },
        filterUserList() {
            if (this.firmFilterVal) {
                if (this.userStatusFilterVal === "all") {
                    return this.$store.state.users.filter(user => {
                        if (user.profile._firm === this.firmFilterVal) {
                            return user;
                        }
                    });
                } else if (this.userStatusFilterVal === "active") {
                    return this.$store.state.users.filter(user => {
                        if (user.is_active === true && user.profile._firm === this.firmFilterVal) {
                            return user;
                        }
                    });
                } else if (this.userStatusFilterVal === "inactive") {
                    return this.$store.state.users.filter(user => {
                        if (user.is_active === false && user.profile._firm === this.firmFilterVal) {
                            return user;
                        }
                    });
                }
            } else {
                if (this.userStatusFilterVal === "all") {
                    return this.$store.state.users;
                } else if (this.userStatusFilterVal === "active") {
                    return this.$store.state.users.filter(user => {
                        if (user.is_active === true) return  user;
                    });
                } else if (this.userStatusFilterVal === "inactive") {
                    return this.$store.state.users.filter(user => {
                        if (user.is_active === false) return  user;
                    });
                } else {
                    return this.$store.state.users;
                }
            }
        },
        toggleModal() {
            this.isOpen = !this.isOpen;
        },
        handleCreate() {
            this.modalAction = "create";
            const loader = this.$loading.show();
            this.getFirmAdvisors(this.authUser.profile._firm).then(() => {
                loader.hide();
                this.toggleModal();
            });
        },
        handleEdit(userId) {
            this.modalAction = "edit";
            const loader = this.$loading.show();
            Promise.all([
                this.getUser(userId),
                this.getFirmAdvisors(this.authUser.profile._firm)
            ]).finally(() => {
                loader.hide();
                this.toggleModal();
            });
        },
    },
}
</script>

<style scoped>
    .main-container {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    }
    .table-filter {
        display: flex !important;
        justify-content: flex-end !important;
    }
    .ui.selection.dropdown {
        margin-right: 5px !important;
    }
</style>
