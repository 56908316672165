<template>
    <div style="margin-top: 1em !important;">
        <sui-table basic="very" selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                    <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                        {{column.text}}
                    </sui-table-header-cell>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body v-if="firms">
                <sui-table-row
                    v-for="(firm, index) in firms" :key="index">
                    <sui-table-cell collapsing>{{ index + 1}}</sui-table-cell>
                    <sui-table-cell>
                        <h4 is="sui-header">
                            <sui-icon name="building" color="grey"/>
                            <sui-header-content>
                                {{firm.name}}
                                <sui-header-subheader>{{firm.abbreviation}}</sui-header-subheader>
                            </sui-header-content>
                        </h4>
                    </sui-table-cell>
                    <sui-table-cell>{{firm.street}}</sui-table-cell>
                    <sui-table-cell>{{firm.city}}</sui-table-cell>
                    <sui-table-cell>{{firm.state}}</sui-table-cell>
                    <sui-table-cell>{{firm.zip_code}}</sui-table-cell>
                    <sui-table-cell>
                        <sui-dropdown pointing="center left">
                            <sui-dropdown-menu>
                                <sui-dropdown-item @click="getFirm(firm.id)">View</sui-dropdown-item>
                                <sui-dropdown-item @click="getFirm(firm.id)">
                                    <sui-icon name="pencil square" />Edit
                                </sui-dropdown-item>
                                <sui-dropdown-item @click="showDeleteAlert(firm)">
                                    <sui-icon name="trash" />Delete
                                </sui-dropdown-item>
                            </sui-dropdown-menu>
                        </sui-dropdown>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
    </div>
</template>

<script>
    export default {
        name: "FirmList",
        props: {
            firms: {type: Array},
        },
        data() {
            return {
                columns: [
                    {key: 'name', value: 'name',  text: 'Firm'},
                    {key: 'street', value: 'street.',  text: 'Street'},
                    {key: 'city', value: 'city.',  text: 'City'},
                    {key: 'state', value: 'state.',  text: 'State'},
                    {key: 'zip_code.', value: 'zip_code.',  text: 'Zip/Postal Code'},
                ]
            }
        },
        methods: {
            toggleModal() {
                this.$emit('toggleModal');
            },
            getFirm(firmId) {
                this.$emit('getFirm', firmId);
            },
            showDeleteAlert(firm) {
                this.$emit('showDeleteAlert', firm);
            },
        }
    }
</script>

<style scoped>

</style>
