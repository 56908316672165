<template>
    <sui-table selectable class="fee-schedule-virtual-list">
        <sui-table-header>
            <sui-table-row>
                <sui-table-header-cell></sui-table-header-cell>
                <sui-table-header-cell v-for="(column, index) in columns" :key=index>
                    {{ column }}
                </sui-table-header-cell>
                <sui-table-header-cell></sui-table-header-cell>
            </sui-table-row>
        </sui-table-header>
        <sui-table-body v-if="feeSchedules">
            <sui-table-row
                v-for="(schedule, index) in feeSchedules" :key="schedule.id">
                <sui-table-cell collapsing>{{ index + 1 }}</sui-table-cell>
                <sui-table-cell>{{ schedule.name }}</sui-table-cell>
                <sui-table-cell>{{ toDatetime(schedule.created_date) }}</sui-table-cell>
                <sui-table-cell>{{ toDatetime(schedule.last_modified_date) }}</sui-table-cell>
                <sui-table-cell collapsing>
                    <div class="fee-schedule-menu">
                        <font-awesome-icon icon="eye" class="fee-schedule-menu-icon eye" @click="viewFeeSchedule(feeSchedule.id)"/>
                        <font-awesome-icon icon="pencil-alt" class="fee-schedule-menu-icon pencil-alt" @click="editFeeSchedule(feeSchedule.id)"/>
                        <font-awesome-icon icon="trash-alt" class="fee-schedule-menu-icon trash" @click="deleteFeeSchedule(feeSchedule)"/>
                    </div>
                </sui-table-cell>
            </sui-table-row>
        </sui-table-body>
        <div v-else>
            <p>No fee schedule(s) found.</p>
        </div>
    </sui-table>
</template>

<script>
    import dateFormatter from "@/mixins/dateFormatter";
    
    export default {
        name: "FeeScheduleList",
        props: {
            feeSchedules: {
                type: Array
            },
        },
        data() {
            return {
                columns: [
                    'Fee Schedule',
                    'Created Date',
                    'Last Modified Date'
                ]
            }
        },
        mixins: [dateFormatter],
        methods: {
            viewFeeSchedule(feeScheduleId) {
                this.$emit('handleViewFeeSchedule', feeScheduleId);
            },
            editFeeSchedule(feeScheduleId) {
                this.$emit('handleEditFeeSchedule', feeScheduleId);
            },
            deleteFeeSchedule(feeSchedule) {
                this.$emit('showDeleteWarning', feeSchedule);
            }
        }
    }
</script>

<style scoped>
    .fee-schedule-virtual-list {
        margin: 1em 1em !important;
    }
    .fee-schedule-menu {
        display: flex;
        color: #8C979A;
    }
    .fee-schedule-menu-icon {
        margin: 0 10px;
        cursor: pointer;
    }
    .fee-schedule-menu .eye:hover{
        color: #555F61;
    }
    .fee-schedule-menu .pencil-alt:hover{
        color: #555F61;
    }
    .fee-schedule-menu .trash:hover{
        color: #ff4d4d;
    }

</style>
