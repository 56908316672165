export default {
    methods: {
        getFormUrl(firmId, formUrls) {
            let url;
            for (let item of formUrls) {
                if(item.firm == firmId) {
                    url = item.url;
                    break;
                }
            }
            return url
        }
    }
}
