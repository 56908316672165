<template>
    <sui-form>
        <sui-form-fields>
            <sui-form-field width="twelve" required>
                <label>Firm Name</label>
                <input type="text"  name="firm-name" placeholder="Firm Name" v-model="firm.name" />
            </sui-form-field>
            <sui-form-field width="four">
                <label>Firm Name Abbr.</label>
                <input type="text"  name="firm-name-abbreviation" placeholder="Abbr." v-model="firm.abbreviation" />
            </sui-form-field>
        </sui-form-fields>
        <sui-form-field>
            <label>Street</label>
            <input type="text" name="street" placeholder="Street" v-model="firm.street">
        </sui-form-field>
        <sui-form-fields>
            <sui-form-field width="six">
                <label>City</label>
                <input type="text" name="city" placeholder="City" v-model="firm.city" />
            </sui-form-field>
            <sui-form-field width="six">
                <label>State</label>
                <sui-dropdown
                    placeholder="State"
                    selection
                    search
                    :options="states"
                    v-model="firm.state"
                />
            </sui-form-field>
            <sui-form-field width="four">
                <label>Zip/Postal Code</label>
                <input type="text" name="zip-postal-code" placeholder="Zip/Postal Code" v-model="firm.zip_code" />
            </sui-form-field>
        </sui-form-fields>
    </sui-form>
</template>

<script>
    import states from "@/mixins/states";
    export default {
        name: "MasterForm",
        props: {
            isNew: {type: Boolean},
            firm: {type: Object},
            toggleModal: {type: Function},
            resetIsNew: {type: Function},
        },
        mixins: [states],
        inject: ['notyf'],
        methods: {
            save() {
                const loader = this.$loading.show();
                if (this.isNew) {
                    this.$store.dispatch('createFirm', this.firm)
                        .then(() => {
                            this.$store.dispatch('getAllFirms');
                            this.toggleModal();
                            this.resetIsNew();
                            loader.hide();
                            this.notyf.success('Firm created successfully.');
                        }).catch()
                } else {
                    this.$store.dispatch('updateFirm', this.firm)
                        .then(() => {
                            this.$store.dispatch('getAllFirms');
                            this.toggleModal();
                            loader.hide();
                            this.notyf.success('Firm Updated successfully.');
                        }).catch()
                }
            },
        }
    }
</script>

<style scoped>
</style>
