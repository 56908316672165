<template>
    <sui-message info style="margin-top: 2em">
        <p>
            <font-awesome-icon icon="info-circle" />
            No {{resourceName}} resources found to be listed. Please try again after the resources have been added.
        </p>
    </sui-message>
</template>

<script>
    export default {
        name: "NoResourceFoundMessage",
        props: {
            resourceName: {
                type: String,
                default: "",
            }
        }
    }
    
</script>

<style scoped>

</style>
