<template>
    <sui-modal
        size="tiny"
        :closable="false"
        v-model="open"
        class="supervised-person-manager-modal"
    >
        <sui-modal-header class="supervised-person-manager-modal-header">
            {{modalHeaderContent}}
        </sui-modal-header>
        <sui-modal-content>
            <sui-form>
                <sui-form-field>
                    <label>Supervised person email address</label>
                    <input placeholder="Enter email of supervised person user" v-model.trim="person.email">
                </sui-form-field>
                <sui-form-fields grouped>
                    <label>Supervised person submits:</label>
                    <sui-form-field>
                        <sui-checkbox label="Personal securities" v-model="person.has_to_submit_personal_securities" />
                    </sui-form-field>
                    <sui-form-field>
                        <sui-checkbox label="Outside business activities" v-model="person.has_to_submit_outside_business_activities" />
                    </sui-form-field>
                    <sui-form-field>
                        <sui-checkbox label="Political contributions" v-model="person.has_to_submit_political_contributions" />
                    </sui-form-field>
                </sui-form-fields>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="cancel"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handleSave"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "Modal",
    props:  {
        open: {
            type: Boolean
        },
        modalAction: {
            type: String
        },
        cancel: {
            type: Function
        },
    },
    data() {
        return {
            person: {},
            modalHeaderContent: null
        }
    },
    computed: mapState(["supervisedPerson"]),
    watch: {
        modalAction: {
            handler: "initializeForm"
        },
        supervisedPerson: {
            deep: true,
            handler: "initializeForm"
        }
    },
    methods: {
        initializeForm() {
            if (this.modalAction === "add") {
                this.modalHeaderContent = "New Supervised Person"
                this.person = {
                    email: null,
                    has_to_submit_personal_securities: false,
                    has_to_submit_outside_business_activities: false,
                    has_to_submit_political_contributions: false
                };
            } else {
                this.modalHeaderContent = `Edit: ${this.supervisedPerson.first_name} ${this.supervisedPerson.last_name}`;
                this.person = this.supervisedPerson;
            }
        },
        
        handleSave() {
            if (this.modalAction === "add") {
                this.$emit('addPerson', this.person);
            } else {
                this.$emit('editPerson', this.person);
            }
        },
    }
}
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.05) !important;
    }
    .supervised-person-manager-modal-header {
        text-align: center;
    }
</style>
