export default {
    methods: {
        setPersonalSecurities() {
            if (this.$store.getters.quarterlyAttestation) {
                const data = this.$store.getters.quarterlyAttestation.Personal_Securities__r;
                if (data) {
                    this.$store.commit('setPersonalSecurities', data.records);
                } else {
                    this.$store.commit('setPersonalSecurities', []);
                }
            } else {
                this.$store.commit('setPersonalSecurities', []);
            }
        },
        setOutsideBusinessActivities() {
            if (this.$store.getters.quarterlyAttestation) {
                const data = this.$store.getters.quarterlyAttestation.Outside_Business_Activities__r;
                if (data) {
                    this.$store.commit('setOutsideBusinessActivities', data.records);
                } else {
                    this.$store.commit('setOutsideBusinessActivities', []);
                }
            } else {
                this.$store.commit('setOutsideBusinessActivities', []);
            }
        },
        setPoliticalContributions() {
            if (this.$store.getters.quarterlyAttestation) {
                const data = this.$store.getters.quarterlyAttestation.Political_Contributions__r;
                if (data) {
                    this.$store.commit('setPoliticalContributions', data.records);
                } else {
                    this.$store.commit('setPoliticalContributions', []);
                }
            } else {
                this.$store.commit('setPoliticalContributions', []);
            }
        },
        /**
         *
         * Converts Salesforce multi-pick list string into an array of options.
         *
         * @param states
         * @returns array
         */
        statesClientsReside(states) {
            if(states !== null) {
                return (states.includes(';') ? states.split(';') : states.split())
            }
            return [];
        },
        /**
         *
         * Converts Salesforce multi-pick list string into an array of options.
         *
         * @param states
         * @returns array
         */
        statesRegistered(states) {
            if (states !== null) {
                return (states.includes(';') ? states.split(';') : states.split())
            }
            return [];
        },
    }
}
