<template>
    <sui-grid centered>
        <sui-grid-row>
            <a
                is="sui-image"
                href=""
                target="_blank"
                :src="logo"
                size="small"
            >
            </a>
        </sui-grid-row>
    </sui-grid>
</template>

<script>
export default {
    name: "FormHeader",
    data() {
        return {
            logo: require('../../assets/logos/stacked.svg'),
        }
    }
}
</script>

<style scoped>

</style>
