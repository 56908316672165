<template>
    <div class="main-container">
        <sui-container >
            <div class="attestation-manager-header">
                <div class="title">
                    <h3 is="sui-header" style="font-weight: lighter !important;">Quarterly Attestations</h3>
                </div>
                <div class="actions">
                    <sui-button
                        basic
                        size="small"
                        color="grey"
                        content="New Attestation"
                        @click="getSupervisedPerson(); $router.push({name: 'attestationForm', params: {id: $store.getters.userProfile.id}})"
                    />
                </div>
            </div>
            <sui-grid>
                <sui-grid-row>
                    <sui-grid-column :width="10" class="attestation-list-filter">
                        <sui-dropdown class="firm-dropdown" v-if="$store.getters.userProfile.profile.is_cco"
                          placeholder="Firm"
                          text="Firm"
                          selection
                          :options="firmOptions"
                          v-model="filter.firm"
                          floating
                          :disabled="disableFirmDropdown"
                        />
                        <sui-dropdown
                            class="fiscal-year-dropdown"
                            placeholder='Fiscal Year'
                            text="Fiscal Year"
                            selection
                            floating
                            :options="fiscalYearOptions"
                            v-model="filter.fiscalYear"
                        />
                        <sui-dropdown
                            class="fiscal-quarter-dropdown"
                            placeholder='Fiscal Quarter'
                            text="Fiscal Quarter"
                            selection
                            floating
                            :options="fiscalQuarterOptions"
                            v-model="filter.fiscalQuarter"
                        />
                    </sui-grid-column>
                    <sui-grid-column :width="6">
                        <div class="attestation-manager-utils">
                            <div class="search-input">
                                <sui-input
                                    placeholder="Search list..."
                                    icon="search"
                                    icon-position="left"
                                    v-model="searchPhrase"
                                />
                            </div>
                            <div class="refresh-list-btn" @click="refreshList">
                                <sui-button basic icon="undo" />
                            </div>
                            <div v-if="$store.getters.userProfile.profile.is_cco" @click="getSupervisedPersons">
                                <sui-button basic icon="users" />
                            </div>
                            <div v-if="$store.getters.userProfile.profile.is_cco" @click="exportToCsv">
                                <sui-button basic content="CSV" icon="arrow down" />
                            </div>
                        </div>
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>
        </sui-container>
        <sui-container  class="attestation-list">
            <List
                @getAttestations="getAttestations"
                @showDeleteAttestationAlert="showDeleteAttestationAlert"
                :search-phrase="searchPhrase"
            />
            <DeleteAlert ref="deleteAlert" @handleDelete="deleteAttestation" />
            <SupervisedPersonsListModal
                :is-open="isOpen"
                :supervised-persons="supervisedPersons"
                :toggle-supervised-persons="toggleSupervisedPersons"
            />
        </sui-container>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import List from "./List.vue";
    import fiscalQuarter from "@/mixins/fiscalQuarter";
    import DeleteAlert from "@/components/common/DeleteWarningModal";
    import SupervisedPersonsListModal from "./SupervisedPersonsListModal";
    export default {
        name: "Index",
        components: {List, DeleteAlert, SupervisedPersonsListModal},
        mixins: [fiscalQuarter],
        inject: ['notyf'],
        beforeCreate() {
            const user = this.$store.getters.userProfile;
            this.$store.dispatch('getFirm', user.profile._firm)
                .then(() => {
                    if (user.profile.is_cco) {
                        this.filter = Object.assign({}, this.filter, {...this.filter,
                            firm: this.$store.getters.firm.firm_tk,
                            user: this.$store.getters.userProfile.id,
                            fiscalYear: this.getFiscalYear(),
                            fiscalQuarter: `Q${this.previousFiscalQuarter()}`
                        });
                    }  else {
                        this.filter = Object.assign({}, this.filter, {...this.filter,
                            firm: this.$store.getters.firm.firm_tk,
                            user: this.$store.getters.userProfile.id,
                        });
                    }
                    this.$store.dispatch('getAllFirms');
                }
            ).catch()
        },
        data() {
            return {
                fiscalQuarterOptions: [
                    {key: 'None', value: '', text: 'All'},
                    {key: 'Q1', value: 'Q1', text: 'Q1'},
                    {key: 'Q2', value: 'Q2', text: 'Q2'},
                    {key: 'Q3', value: 'Q3', text: 'Q3'},
                    {key: 'Q4', value: 'Q4', text: 'Q4'},
                ],
                filter: {
                    user: null,
                    firm: null,
                    fiscalYear: null,
                    fiscalQuarter: null
                },
                searchPhrase: null,
                isOpen: false,
                firm: null
            }
        },
        computed: {
            ...mapState({authUser: "userProfile"}),
            firmOptions() {
                const firms = this.$store.getters.firms;
                if (firms) {
                    return firms.map(firm => {
                        return {key: firm.id, value: firm.firm_tk, text: firm.name};
                    })
                }
                return [];
            },
            fiscalYearOptions() {
                const startingFiscalYear = 2017;
                const currentFiscalYear = new Date().getFullYear();
                let fiscalYearList =  [];
                for (let i = startingFiscalYear; i <= currentFiscalYear; i++) {
                    const text = `FY ${i.toString()}`;
                    let year = { key: i.toString(), value: i, text: text };
                    fiscalYearList.unshift(year)
                }
                fiscalYearList.unshift({key: 'None', value: '', text: 'All'});
                return fiscalYearList;
            },
            disableFirmDropdown() {
                const isSuperCCO = this.$store.getters.userProfile.profile.is_super_cco;
                return (isSuperCCO !== true);
            },
            supervisedPersons() {
                const supervisedPersons = this.$store.getters.supervisedPersons;
                if (supervisedPersons) return supervisedPersons;
                return [];
            }
        },
        watch: {
            filter: {
                deep: true,
                handler: 'getAttestations'
            },
        },
        methods: {
            getFiscalYear() {
                if (this.previousFiscalQuarter() === 4) return new Date().getFullYear() - 1;
                return new Date().getFullYear();
            },
            getAttestations() {
                const user = this.$store.getters.userProfile;
                if (user.profile.is_cco) {
                    const loader = this.$loading.show();
                    this.$store.dispatch('filterAttestations', this.filter)
                        .then(() => {
                            // Store firm details of based on list filter.
                            for (let firm of this.$store.getters.firms) {
                                if (firm.firm_tk === this.filter.firm) {
                                    this.firm = firm;
                                    break;
                                }
                            }
                            loader.hide();
                        })
                        .catch();
                } else {
                    const loader = this.$loading.show();
                    this.$store.dispatch('getUserAttestations', this.filter)
                        .then(() => {loader.hide(); })
                        .catch();
                }
            },
            showDeleteAttestationAlert(attestation) {
                this.$refs.deleteAlert.toggleIsOpen(attestation.Id, attestation.Name);
            },
            deleteAttestation(attestationId) {
                const loader = this.$loading.show();
                this.$store.dispatch('deleteQuarterlyAttestation', attestationId)
                    .then(() => {
                        this.getAttestations();
                        this.$refs.deleteAlert.toggleIsOpen();
                        loader.hide();
                        this.notyf.success('Attestation deleted successfully.');
                    }).catch()
            },
            refreshList() {
                this.getAttestations();
            },
            getSupervisedPerson() {
                this.$store.dispatch("getSupervisedPersonByUserId", this.authUser.id);
            },
            getSupervisedPersons() {
                if (this.filter.firm) {
                    let firmId;
                    for (const firm of this.$store.getters.firms) {
                        if (firm.firm_tk === this.filter.firm) {
                            firmId = firm.id;
                            break;
                        }
                    }
                    const loader = this.$loading.show();
                    this.$store.dispatch('getFirmSupervisedPersons', firmId)
                        .then(() => {
                                loader.hide();
                                this.toggleSupervisedPersons();
                            }
                        )
                        .catch(() => loader.hide());
                }
            },
            toggleSupervisedPersons() {
                this.isOpen = !this.isOpen;
            },
            exportToCsv() {
                let personalSecurityCsvData = [];
                let outsideBusinessActivityCsvData = [];
                let politicalContributionCsvData = [];
                
                const attestations = this.$store.getters.quarterlyAttestations;
                if (attestations) {
                    let csvData = attestations.map(attestation => {
                        if (attestation.Personal_Securities__r) {
                            attestation.Personal_Securities__r.records.forEach(security => {
                                personalSecurityCsvData.push({
                                    'Attestation': security.Quarterly_Attestation_Id__r.Name,
                                    'Account Owner': security.Account_Owner__c,
                                    'Account Number': security.Account_Number__c,
                                    'Account Type': security.Account_Type__c,
                                    'Custodian': security.Custodian__c
                                })
                            })
                        }
                        if (attestation.Outside_Business_Activities__r) {
                            attestation.Outside_Business_Activities__r.records.forEach(bizActivity => {
                                outsideBusinessActivityCsvData.push({
                                    'Attestation': bizActivity.Quarterly_Attestation_Id__r.Name,
                                    'Outside Business Activity': bizActivity.Name,
                                    'Approved By': bizActivity.Approved_By__c,
                                    'Approved Date': bizActivity.Approved_Date__c,
                                })
                            })
                        }
                        if (attestation.Political_Contributions__r) {
                            attestation.Political_Contributions__r.records.forEach(contribution => {
                                politicalContributionCsvData.push({
                                    'Attestation': contribution.Quarterly_Attestation_Id__r.Name,
                                    'Amount': contribution.Amount__c,
                                    'Candidate': contribution.Candidate_Name__c,
                                    'Political Race': contribution.Political_Race__c,
                                    'Approved By': contribution.Approved_By__c,
                                    'Approved Date': contribution.Approved_Date__c
                                })
                            })
                        }
                        return {
                            'Attestation': attestation.Name,
                            'First Name': attestation.First_Name__c,
                            'Middle Name': attestation.Middle_Name__c,
                            'Last Name': attestation.Last_Name__c,
                            'Job Title': attestation.Job_Title__c,
                            'Employment Date': attestation.Employment_Date__c,
                            'Work Phone': attestation.Work_Phone__c,
                            'Home Phone': attestation.Home_Phone__c,
                            'Mobile Phone': attestation.Mobile_Phone__c,
                            'Work Email': attestation.Work_Email__c,
                            'Personal Email': attestation.Personal_Email__c,
                            'Address Line 1': attestation.Address_Line_1__c,
                            'Address Line 2': attestation.Address_Line_2__c,
                            'City': attestation.City__c,
                            'State': attestation.State__c,
                            'Zip/Postal Code': attestation.Zip_Code__c,
                            'CRD Number': attestation.CRD_Number__c,
                            'Insurance License': attestation.Insurance_License__c,
                            'Broker-Dealer': attestation.Broker_Dealer__c,
                            'States Registered': attestation.States_Registered__c,
                            'States Clients Reside': attestation.States_Clients_Reside__c,
                            'CFP': attestation.CFP__c,
                            'ChFC': attestation.ChFC__c,
                            'CIC': attestation.CIC__c,
                            'Series 7': attestation.Series_7__c,
                            'Series 24': attestation.Series_24__c,
                            'Series 65': attestation.Series_65__c,
                            'Series 66': attestation.Series_66__c,
                            'Other Qualifications': attestation.Other_Qualifications__c,
                            'Solicitor Referral Relationships': attestation.Solicitor_Referral_Relationships__c,
                            'Non_Advisory Compensation Arrangements': attestation.Non_Advisory_Compensation_Arrangements__c,
                            'Covered Associate': attestation.Is_Covered_Associate__c,
                            'Access Person': attestation.Is_Access_Person__c,
                            'LinkedIn': attestation.LinkedIn__c,
                            'Facebook': attestation.Facebook__c,
                            'Twitter': attestation.Twitter__c,
                            'YouTube': attestation.YouTube__c,
                            'Other Social Media': attestation.Other_Social_Media__c,
                            'Has Reported Previous Securities': attestation.Has_Reported_Previous_Securities__c,
                            'Code of Ethics Attestation_Date': attestation.Code_of_Ethics_Attestation_Date__c,
                            'IA Manual Attestation Date': attestation.IA_Manual_Attestation_Date__c,
                            'Has Received Gratuities': attestation.Has_Received_Gratuities__c,
                            'Not Used Cell Phone/Personal Device': attestation.hasNotUsedPersonalDevice__c,
                            'Is Compliant': attestation.Is_Compliant__c,
                            'Status': attestation.Status__c,
                            'Submitted Date': attestation.Submitted_Date__c,
                            'Fiscal Year': attestation.Fiscal_Year__c,
                            'Fiscal Quarter': attestation.Fiscal_Quarter__c,
                            'Reviewed Date': attestation.Reviewed_Date__c,
                            'Firm': attestation.Firm_Name__c
                        }
                    })
                    //Export quarterly attestations.
                    const attestationsCsv = this.$papa.unparse(csvData, {delimiter: ","});
                    this.$papa.download(attestationsCsv, this.attestationsCsvFilename());
    
                    // Export personal securities.
                    if (personalSecurityCsvData.length > 0) {
                        const csv = this.$papa.unparse(personalSecurityCsvData, {delimiter: ","});
                        this.$papa.download(csv, this.personalSecuritiesCsvFilename());
                    }
    
                    // Export outside business activities.
                    if (outsideBusinessActivityCsvData.length > 0) {
                        const csv = this.$papa.unparse(outsideBusinessActivityCsvData, {delimiter: ","});
                        this.$papa.download(csv, this.outsideBusinessActivitiesCsvFilename());
                    }
    
                    // Export political contributions.
                    if (politicalContributionCsvData.length > 0) {
                        const csv = this.$papa.unparse(politicalContributionCsvData, {delimiter: ","});
                        this.$papa.download(csv, this.politicalContributionsCsvFilename());
                    }
                } else {
                    this.notyf.error({
                        position: {
                            x: 'right',
                            y: 'bottom',
                        },
                        message: 'No data to export to CSV file'
                    });
                }
            },
            setFilenameHelper() {
                if (this.filter.fiscalYear && this.filter.fiscalQuarter) {
                    return `FY${this.filter.fiscalYear}-${this.filter.fiscalQuarter}`;
                } else if (this.filter.fiscalYear && !this.filter.fiscalQuarter) {
                    return `FY${this.filter.fiscalYear}`;
                } else if (!this.filter.fiscalYear && this.filter.fiscalQuarter) {
                    return `${this.filter.fiscalQuarter}`;
                }
            },
            attestationsCsvFilename() {
                return `${this.firm.abbreviation}-Attestations-${this.setFilenameHelper()}`.toLowerCase();
            },
            personalSecuritiesCsvFilename() {
                return `${this.firm.abbreviation}-Personal-Securities-${this.setFilenameHelper()}`.toLowerCase();
            },
            outsideBusinessActivitiesCsvFilename() {
                return `${this.firm.abbreviation}-Outside-Business-Activities-${this.setFilenameHelper()}`.toLowerCase();
            },
            politicalContributionsCsvFilename() {
                return `${this.firm.abbreviation}-Political-Contributions-${this.setFilenameHelper()}`.toLowerCase();
            },
        },
    }
</script>

<style scoped>
    .main-container {
         padding: 1em 1em;
    }
    .attestation-manager-header {
        margin-bottom: .5em !important;
        display: flex;
        justify-content: space-between;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
    .attestation-manager-utils {
        display: flex !important;
        justify-content: flex-end !important;
    }
    .attestation-manager-utils .search-input {
        padding-right: 1px;
    }
    .attestation-manager-utils .refresh-list-btn {
        padding-top: 1px;
    }
    .attestation-list-filter {
        display: flex !important;
        justify-content: flex-start !important;
    }
    .ui.selection.dropdown {
        margin-right: 2px !important;
    }
    .attestation-list {
        padding-top: 10px;
    }
    .fiscal-year-dropdown {
        min-width: 7em !important;
    }
    .fiscal-quarter-dropdown {
        min-width: 3em !important;
    }
</style>
