<template>
    <sui-container fluid class="logged-view">
        <Header />
        <sui-grid class="centered">
            <sui-grid-row centered>
                <div class="text">You have successfully logged out.</div>
            </sui-grid-row>
            <sui-grid-row centered>
                    <router-link to="/account/login">
                        <sui-button color="teal" style="background-color: #2a998b;">
                            Log back in
                        </sui-button>
                    </router-link>
            </sui-grid-row>
        </sui-grid>
    </sui-container>
</template>

<script>
    import Header from "./Header";
    export default {
        name: "Logout",
        components: {
             Header
        }
    }
</script>

<style scoped>
    .logged-view {
        min-height: 100vh;
        width: 100%;
        padding-top: 52px;
        background: #f4f6f9;
    }
</style>
