<template>
    <sui-container>
        <div class="manager">
            <div class="title">
                <h3 is="sui-header" style="font-weight: lighter">Models</h3>
            </div>
            <sui-input
                placeholder="Search list..."
                icon="search"
                icon-position="left"
                v-model="searchPhrase"
            />
            <div class="actions">
                <sui-button
                    basic
                    size="tiny"
                    content="New Model"
                    color="grey"
                    @click="handleNewModel"
                />
            </div>
        </div>
        <ModelList
            :models="models"
            @handleViewModel="handleViewModel"
            @handleEditModel="handleEditModel"
            @showDeleteWarning="showDeleteWarning"
        />
        <Modal
            ref="modal"
            :title="title"
            :model="model"
            :firms="firms"
            :financialAdvisors="financialAdvisors"
            :reset="reset"
        />
        <DeleteWarning
            ref="deleteWarningModal"
            @handleDelete="deleteModel"
        />
    </sui-container>
</template>

<script>
    import ModelList from "./ModelList";
    import Modal from "./BaseModal";
    import DeleteWarning from "@/components/common/DeleteWarningModal";
    
    export default {
        name: "Index",
        components: {
            ModelList,
            Modal,
            DeleteWarning,
        },
        inject: ['notyf'],
        beforeCreate() {
            const loader = this.$loading.show();
            Promise.all([
                this.$store.dispatch('getAllInvestmentModels'),
                this.$store.dispatch('getAllFirms'),
                this.$store.dispatch("getAllAdvisors", 2),
            ])
                .finally(() => loader.hide());
        },
        data(){
            return {
                firm: '',
                open: false,
                title: null,
                model: {
                    investment_model_name: null,
                    firm: null,
                    financial_advisor: null,
                },
                isCreate: false,
                searchPhrase: null
            }
        },
        computed: {
            models() {
                const models = this.$store.getters.investmentModelList;
                if (models) {
                    if (this.searchPhrase) {
                        return models.filter(model => {
                            if (model.name.toLowerCase().includes(this.searchPhrase.toLowerCase())){
                                return model;
                            }
                        });
                    } else {
                        return  models;
                    }
                }
                return [];
            },
            firms() {
                const firms = this.$store.getters.firms;
                if (firms) {
                    let firmList = firms.map(firm => {
                        return {key: firm.id, value: firm.id, text: firm.name};
                    });
                    firmList.unshift({key: 0, value: '', text: 'All'})
                    return firmList;
                }
                return [];
            },
            financialAdvisors() {
                const advisors = this.$store.getters.users;
                if (advisors) {
                    return advisors.map(advisor => {
                        const fullName = advisor.first_name.concat(' ',  advisor.last_name);
                        return {key: advisor.id, value: advisor.id, text: fullName};
                    })
                }
                return [];
            },
        },
        watch: {
            firm: {
                handler: 'getModels',
            },
        },
        methods: {
            toggleModal() {
                this.open = !this.open;
            },
            initialState() {
                return {
                    open: false,
                    title: null,
                    model: {
                        investment_model_name: null,
                        firm: null,
                        financial_advisor: null,
                    },
                }
            },
            reset() {
                Object.assign(this.$data, this.initialState());
            },
            getModel(modelId) {
                const loader = this.$loading.show();
                this.$store.dispatch('getInvestmentModel', modelId)
                    .then(() => {
                        this.model = this.$store.getters.investmentModel;
                        loader.hide();
                    }).catch();
            },
            getModels() {
                 const loader = this.$loading.show();
                 if (this.firm) {
                     this.$store.dispatch('getFirmInvestmentModels', this.firm)
                         .then(() => {
                             const profileTypeTk = 2;
                             this.$store.dispatch('getFirmAdvisors', profileTypeTk);
                             loader.hide();
                         }).catch()
                 } else {
                     this.$store.dispatch('getAllInvestmentModels')
                        .then(() =>{
                            this.$store.dispatch('getAllAdvisors');
                            loader.hide();
                        }).catch()
                 }
            },
            getAllAdvisors() {
                this.$store.dispatch('getAllAdvisors');
            },
            getFirmAdvisors(firmTk) {
                this.$store.dispatch('getFirmAdvisors', firmTk);
            },
            handleNewModel() {
                this.$refs.modal.toggleIsCreate();
                this.$refs.modal.toggleModal();
            },
            handleViewModel(modelId) {
                this.getModel(modelId);
                this.$refs.modal.toggleModal();
            },
            handleEditModel(modelId) {
                this.getModel(modelId);
                this.$refs.modal.toggleModal();
            },
            showDeleteWarning(model) {
                this.$refs.deleteWarningModal.toggleIsOpen(model.id, model.investment_model_name);
            },
            deleteModel(modelId) {
                const loader = this.$loading.show();
                this.$store.dispatch('deleteInvestmentModel', modelId)
                    .then(() => {
                        this.$store.dispatch('getAllInvestmentModels');
                        loader.hide();
                        this.$refs.deleteWarningModal.toggleIsOpen();
                        this.notyf.success('Model deleted successfully.');
                    })
                    .catch();

            }
        }
    }
</script>

<style scoped>
    .manager {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5em !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
    .manager .title {
        padding-left: 20px;
    }
    .manager .filter {
    }
    .manager .actions {
    }
</style>
