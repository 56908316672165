<template>
    <sui-modal :closable="false" v-model="open" size="small">
        <sui-modal-header style="text-align: center">{{ title }}</sui-modal-header>
        <sui-modal-content scrolling>
            <ModalForm
                ref="form"
                :model="model"
                :firms="firms"
                :financialAdvisors="financialAdvisors"
                @toggleModal="toggleModal"
                :reset="reset"
                :isCreate="isCreate"
                @toggleIsCreate="toggleIsCreate"
            />
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                type="button"
                basic
                size="small"
                color="grey"
                content="Cancel"
                @click="cancel"
                @toggleIsCreate="toggleIsCreate"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click.native="save"
                @toggleIsCreate="toggleIsCreate"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    import ModalForm from "./BaseForm";
    export default {
        name: "Modal",
        components: {
            ModalForm
        },
        props: {
            model: {type: Object},
            firms: {type: Array},
            financialAdvisors: {type: Array},
            reset: {type: Function},
        },
        data() {
            return {
                open: false,
                isCreate: false
            }
        },
        computed: {
            title() {
                if (this.isCreate) return 'New Model';
                return this.model.investment_model_name;
            }
        },
        methods: {
            toggleModal() {
                this.open = !this.open;
                this.reset();
            },
            toggleIsCreate() {
                this.isCreate = !this.isCreate;
            },
            save() {
                this.$refs.form.save();
            },
            cancel() {
                if (this.isCreate) this.toggleIsCreate();
                this.toggleModal();
            },
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.1) !important;
    }
</style>
