<template>
    <sui-modal
        size="large"
        :closable="false"
        v-model="open"
    >
        <sui-modal-header style="text-align: center">Edit Cash Request</sui-modal-header>
        <sui-modal-content scrolling>
            <CashRequestForm :cashRequest="cashRequest" ref="form"/>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                color="grey"
                type="button"
                content="Cancel"
                @click.native="toggle"
            />
            <sui-button
                primary
                type="button"
                content="Save"
                @click.native="save"
                @update="save"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
    import CashRequestForm from "./MasterForm";
    export default {
        name: "Modal",
        components: {
            CashRequestForm
        },
        props: {
            open: { type: Boolean, default: false },
            cashRequest: { type: Object },
            toggle: { type: Function},
        },
        methods: {
            save() {
                this.$refs.form.update();
                this.$emit('toggle-modal');
            }
        }
    }
</script>

<style scoped>
    .ui.dimmer {
        background-color: rgba(0,0,0,.2) !important;
    }

</style>
