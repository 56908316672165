<template>
    <sui-modal
        size="tiny"
        :closable="false"
        v-model="open"
        class="supervised-person-manager-modal"
    >
        <sui-modal-header class="supervised-person-manager-modal-header">
            {{ title }}
        </sui-modal-header>
        <sui-modal-content>
            <sui-form>
                <sui-form-field>
                    <label>Firm</label>
                    <sui-dropdown
                        selection
                        search
                        direction="downward"
                        placeholder="Search Firms..."
                        :options="firmOptions"
                        v-model="firmModel.firm"
                    />
                </sui-form-field>
                <sui-form-field>
                    <label>Investment Model</label>
                    <sui-dropdown
                        selection
                        search
                        direction="downward"
                        placeholder="Search Models..."
                        :options="models"
                        v-model="firmModel.investment_model"
                    />
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                basic
                size="small"
                color="grey"
                type="button"
                content="Cancel"
                @click="cancel"
            />
            <sui-button
                primary
                size="small"
                type="button"
                content="Save"
                @click="handleSave"
            />
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "Modal",
    props:  {
        open: {
            type: Boolean
        },
        modalAction: {
            type: String
        },
        models: {
            required: true,
            type: Array
        },
        cancel: {
            type: Function
        },
    },
    data() {
        return {
            firmModel: {},
            title: null
        }
    },
    computed: {
        ...mapState({ model: "firmModel" }),
        ...mapGetters(["firms"]),
        firmOptions() {
            if (this.firms) {
                return this.firms.map(firm => {
                    return { key: firm.id, value: firm.id, text: firm.name };
                });
            }
            return [];
        },
    },
    watch: {
        modalAction: {
            handler: "setFormData"
        },
        model: {
            deep: true,
            handler: "setFormData"
        }
    },
    methods: {
        setFormData() {
            if (this.modalAction === "add") {
                this.title = "New Firm Model"
                this.firmModel = {
                    firm: null,
                    investment_model: null
                };
            } else {
                const model = Object.assign({}, this.model)
                this.title = `Edit: ${model.investment_model_name}`;
                this.firmModel = this.model;
            }
        },
        
        handleSave() {
            if (this.modalAction === "add") {
                this.$emit('add', this.firmModel);
            } else {
                this.$emit('edit', this.firmModel);
            }
        },
    }
}
</script>

<style scoped>
.ui.dimmer {
    background-color: rgba(0,0,0,.05) !important;
}
.supervised-person-manager-modal-header {
    text-align: center;
}
</style>

