<template>
    <div class="main-container">
        <sui-container  class="training-resource-container">
            <div class="side-menu">
                <Menu :resource-items="sideMenuItems" />
            </div>
            <div class="resource-list">
                <router-view></router-view>
            </div>
        </sui-container>
    </div>
</template>

<script>
    import Menu from "@/components/resourceManager/SideMenu";
    export default {
        name: "Index",
        components: {
            Menu
        },
        data() {
            return {
                sideMenuItems: [
                    'Compliance',
                    'Investments',
                    'Operations',
                    'Videos',
                    'FAQs',
                ]
            }
        }
    }
</script>

<style scoped>
    .main-container {
        margin-top: 2em;
    }
    .training-resource-container {
        width: 95%;
        height: 100vh;
        margin: auto;
        padding: 10px;
    }
    .side-menu {
        background: #F8F8F8	;
        width: 18%;
        float: left;
        padding-top: 10px;
        
    }
    .resource-list  {
        margin-left: 21%;
        height: 100vh;
    }
</style>
