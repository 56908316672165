<template>
    <div class="no-items-found-list-message">
        <p>No items to display.</p>
    </div>
</template>

<script>
    export default {
        name: "NoItemsFoundListMessage"
    }
</script>

<style scoped>
    .no-items-found-list-message {
        padding: 2em 2em !important;
        text-align: center !important;
    }
</style>
