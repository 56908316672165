<template>
    <div>
        <sui-form v-if="ticket">
            <sui-grid>
                <sui-grid-row>
                    <sui-grid-column :width="8">
                        <sui-form-field required>
                            <label>Category</label>
                            <sui-dropdown
                                placeholder="Category"
                                selection
                                direction="downward"
                                :options="ticketCategoryOptions"
                                v-model="supportTicket.Category__c"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Priority</label>
                            <sui-dropdown
                                placeholder="Priority"
                                selection
                                direction="downward"
                                :options="ticketPriorityOptions"
                                v-model="supportTicket.Priority"
                            />
                        </sui-form-field>
                    </sui-grid-column>
                    <sui-grid-column :width="8">
                        <sui-form-field>
                            <label>Category Type</label>
                            <sui-dropdown
                                placeholder="Category Type"
                                selection
                                direction="downward"
                                :options="ticketTypeOptions"
                                v-model="supportTicket.Type"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Reason</label>
                            <sui-dropdown
                                placeholder="Reason"
                                selection
                                direction="downward"
                                :options="ticketReasonOptions"
                                v-model="supportTicket.Reason"
                            />
                        </sui-form-field>
                        <sui-form-field disabled>
                            <label>Status</label>
                            <sui-dropdown
                                placeholder="Status"
                                selection
                                direction="downward"
                                :options="ticketStatusOptions"
                                v-model="supportTicket.Status"
                            />
                        </sui-form-field>
                    </sui-grid-column>
                </sui-grid-row>
                <sui-grid-row>
                    <sui-grid-column>
                        <sui-form-field required>
                            <label>Subject</label>
                            <input type="text"  name="subject" placeholder="Subject" v-model="supportTicket.Subject"/>
                        </sui-form-field>
                    </sui-grid-column>
                </sui-grid-row>
                <sui-grid-row>
                    <sui-grid-column>
                        <sui-form-field>
                            <label>Description</label>
                            <textarea
                                type="text"
                                name="description"
                                rows="10" v-model="supportTicket.Description"
                            />
                        </sui-form-field>
                    </sui-grid-column>
                </sui-grid-row>
            </sui-grid>
        </sui-form>
    </div>
</template>

<script>
    export default {
        name: "TicketForm",
        props: {
            isNew: {
                type: Boolean,
            },
            ticket: {
                type: Object,
            },
            toggleModal: {
                type: Function,
            }
        },
        inject: ['notyf'],
        data() {
            return {
                supportTicket: null,
                ticketCategoryOptions: [
                    {key: "compliance", value: "Compliance", text: "Compliance"},
                    {key: "consulting", value: "Consulting", text: "Consulting"},
                    {key: "humanResources", value: "Human Resources", text: "Human Resources"},
                    {key: "investments", value: "Investments", text: "Investments"},
                    {key: "marketing", value: "Marketing", text: "Marketing"},
                    {key: "onboarding", value: "Onboarding", text: "Onboarding"},
                    {key: "operations", value: "Operations", text: "Operations"},
                    {key: "prayerRequest", value: "Prayer Request", text: "Prayer Request"},
                    {key: "technology", value: "Technology", text: "Technology"},
                    {key: "other", value: "Other", text: "Other"},
                ],
                ticketPriorityOptions: [
                    {key: "low", value: "Low", text: "Low"},
                    {key: "medium", value: "Medium", text: "Medium"},
                    {key: "high", value: "High", text: "High"},
                    {key: "critical", value: "Critical", text: "Critical"},
                ],
                ticketStatusOptions: [
                    {key: "new", value: "New", text: "New"},
                    {key: "inProgress", value: "In Progress", text: "In Progress"},
                    {key: "resolved", value: "Resolved", text: "Resolved"},
                    {key: "awaitingSubmitterFeedback", value: "Awaiting Submitter Feedback", text: "Awaiting Submitter Feedback"},
                    {key: "closed", value: "Closed", text: "Closed"},
                ],
                ticketReasonOptions: [
                    {key: "problem", value: "Problem", text: "Problem"},
                    {key: "suggestion", value: "Suggestion", text: "Suggestion"},
                    {key: "other", value: "Other", text: "Other"},
                ],
                ticketTypeOptions: [
                    {key: "newProblem", value: "New Problem", text: "New Problem"},
                    {key: "existingProblem", value: "Existing Problem", text: "Existing Problem"},
                    {key: "other", value: "Other", text: "Other"},
                ],
                isSalesforceTicket: false,
            }
        },
        watch: {
            ticket: {
                handler: function () {
                    this.supportTicket = this.ticket;
                }
            },
        },
        methods: {
            save() {
                const notyfConfig = {
                    message: 'Ticket updated successfully',
                    position: {
                        x: 'right',
                        y: 'bottom',
                    }
                };
                if (this.isNew) {
                    const user = this.$store.getters.userProfile;
                    this.ticket.EverSource_Portal_User_ID__c = user.id;
                    this.ticket.SuppliedName = `${user.first_name} ${user.last_name}`;
                    this.ticket.SuppliedEmail = `${user.email}`;

                    // Creates a new support ticket.
                    const loader = this.$loading.show();
                    this.$store.dispatch('createSupportTicket', this.supportTicket)
                        .then(() => {
                            this.$store.dispatch("getSupportTicketList");
                            this.toggleModal();
                            loader.hide();
                            this.notyf.success(notyfConfig["message"]="Ticket submitted successfully");
                        })
                } else {
                    // Updates a support ticket.
                    const loader = this.$loading.show();
                    this.$store.dispatch("updateSupportTicket", this.supportTicket)
                        .then(() => {
                            this.$store.dispatch("getSupportTicketList");
                            this.toggleModal();
                            loader.hide();
                            this.notyf.success(notyfConfig);
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>
