<template>
    <sui-container>
        <sui-menu pointing secondary>
            <a
                is="sui-menu-item"
                v-for="item in items"
                :active="isActive(item)"
                :key="item"
                :content="item"
                @click="select(item)"
            />
        </sui-menu>
        <router-view></router-view>
    </sui-container>
</template>

<script>
    export default {
        name: "Settings",
        data() {
            return {
                items: ["Profile", "Email", "Password"],
                active: "Profile"
            };
        },
        methods: {
            isActive(name) {
                return this.active === name;
            },
            select(name) {
                this.active = name;
                this.$router.push({ name: name});
            }
        }
    }
</script>

<style scoped>
</style>
