<template>
    <div>
        <sui-image
            v-if="logo"
            :src="logo"
            size="medium"
            centered
        />
        <h3 is="sui-header">
            {{firmName}}<br/>Quarterly Attestation
        </h3>
    </div>
</template>

<script>
export default {
    name: "FormHeader",
    props: {
        logo: {
            type: String,
        },
        firmName: {
            type: String,
        }
    }
}
</script>

<style scoped>
    h3 {
        margin-top: 10px !important;
    }
</style>
