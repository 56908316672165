<template>
    <div>
        <sui-container class="centered" style="padding-top: 52px;">
            <Header/>
            <account-activation-form/>
        </sui-container>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from "./Header";
    import AccountActivationForm from "../../components/auth/AccountActivationForm";
    import Footer from "../../components/common/AppFooter";
    export default {
        name: "AccountActivationView",
        components: {
            Footer,
            Header,
            AccountActivationForm,
        }
    }
</script>

<style scoped>

</style>
